import classNames from 'classnames'

import {showDownloadAllArtifactsLink} from '../BuildArtifactsTree.settings'

import styles from './ArtifactsDownloadAll.css'

import IconButton from 'src/components/common/IconButton/IconButton'
import {stringifyId} from 'src/types'
import type {BuildId, BuildTypeId} from 'src/types'
import {resolveRelative} from 'src/utils/url'

type Props = {
  className?: string
  buildId?: BuildId
  buildType?: BuildTypeId | null | undefined
  showHidden?: boolean | null | undefined
  compact?: boolean
}

export default function ArtifactsDownloadAll({
  className,
  buildId,
  buildType,
  showHidden,
  compact,
}: Props) {
  if (!showDownloadAllArtifactsLink) {
    return null
  }

  return (
    <IconButton
      href={resolveRelative(
        `/repository/downloadAll/${stringifyId(buildType)}/${stringifyId(
          buildId,
        )}:id/artifacts.zip${showHidden === true ? '?showAll=true' : ''}`,
      )}
      className={classNames(styles.downloadLink, className)}
      iconClassName={classNames({[styles.downloadIcon]: !compact})}
      icon="download"
      title="Download all"
      withBorder={!compact}
    >
      {compact ? null : 'Download all'}
    </IconButton>
  )
}
