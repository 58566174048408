import {createBrowserHistory} from 'history'
import type {Location, NavigateFunction, Params, PathMatch} from 'react-router-dom'
import {matchPath, parsePath, useLocation, useNavigate} from 'react-router-dom'

import {ChangePageTabName} from '../components/packages/Changes/ChangeDetailsTabs/ChangeDetailsTabs.types'
import withHook from '../hocs/withHook'
import type {
  ActiveEntityURLProps,
  AgentId,
  AgentPoolId,
  AgentTypeId,
  BuildId,
  BuildTypeId,
  ChangeId,
  Enhancer,
  FederationServerId,
  ProjectId,
  TestId,
} from '../types'
import {stringifyId} from '../types'
import {base_uri} from '../types/BS_types'
import type {KeyValue} from '../utils/object'
import type {QueryParams} from '../utils/queryParams'
import {objectToQuery, queryToObject} from '../utils/queryParams'
import {parseURL, resolveRelativeCustomBase} from '../utils/url'

import SharedRoutes from './shared-routes.json'

export const browserHistory = createBrowserHistory()

// Remove trailing slash, ensure leading slash
const basePath = parseURL(base_uri)
  .pathname.replace(/\/$/g, '')
  .replace(/^[^\/]/, '/$&')
export const Routes = {...SharedRoutes, BASE: `${basePath}/*`}
const AGENTS_SCREEN_ROUTES = [
  Routes.AGENTS_OVERVIEW,
  Routes.FAVORITE_AGENT_POOLS,
  Routes.DISCONNECTED_AGENTS_OVERVIEW,
  Routes.AGENT,
  Routes.AGENT_POOL,
  Routes.CLOUD_IMAGE,
  Routes.AGENTS_UNAUTHORIZED,
  Routes.AGENTS,
]
export default Routes
export const getBaseRoute = (route: string): string => Routes.BASE.replace('*', route)
export const matchRoute = (route: string | null | undefined, pathname: string): PathMatch | null =>
  route == null ? null : matchPath(getBaseRoute(route), pathname)
export const getHref = (
  route: string,
  params: KeyValue<string, string> = {},
  hash?: string | null,
): string =>
  getBaseRoute(route)
    .replace(/:([^\/]*)/g, (_, param) => params[param] ?? '')
    .concat(hash != null ? `#${hash}` : '')
export const ALL_PROJECTS_HASH = 'all-projects'
export const getProjectHref = (projectId: ProjectId, isAllProjects?: boolean): string =>
  getHref(
    Routes.PROJECT,
    {
      projectId: stringifyId(projectId),
    },
    isAllProjects === true ? ALL_PROJECTS_HASH : null,
  )
export const getFavoriteProjectsHref = (): string => getBaseRoute(Routes.FAVORITE_PROJECTS)
export const getFavoriteBuildsHref = (): string => getBaseRoute(Routes.FAVORITE_BUILDS)
export const getFavoriteAgentPoolsHref = (): string => getBaseRoute(Routes.FAVORITE_AGENT_POOLS)
export const getBuildTypeHref = (buildTypeId?: BuildTypeId, isAllProjects?: boolean): string =>
  getHref(
    Routes.BUILD_TYPE,
    {
      buildTypeId: stringifyId(buildTypeId),
    },
    isAllProjects === true ? ALL_PROJECTS_HASH : null,
  )
export const getBuildHref = (
  buildId: BuildId | string,
  buildTypeId?: BuildTypeId | null | undefined,
  isAllProjects?: boolean | null | undefined,
): string =>
  getHref(
    buildTypeId != null ? Routes.BUILD : Routes.BUILD_UNKNOWN_BUILDTYPE,
    {
      buildId: stringifyId(buildId),
      buildTypeId: stringifyId(buildTypeId),
    },
    isAllProjects === true ? ALL_PROJECTS_HASH : null,
  )
export const getCompareBuildHref = (sourceId: BuildId): string =>
  getHref(Routes.COMPARE_BUILDS, {
    buildIdSource: stringifyId(sourceId),
  })
export const getOverviewHref = ({
  projectId,
  buildTypeId,
  buildId,
  isAllProjects,
}: ActiveEntityURLProps): string => {
  if (buildId != null) {
    return getBuildHref(buildId, buildTypeId, isAllProjects)
  }

  if (buildTypeId != null) {
    return getBuildTypeHref(buildTypeId, isAllProjects)
  }

  if (projectId != null) {
    return getProjectHref(projectId, isAllProjects)
  }

  return getFavoriteProjectsHref()
}

export const ALL_POOLS_HASH = 'all-pools'
export const getAgentsHref = (): string => getHref(Routes.AGENTS, {})
export const getAgentsOverviewHref = (): string => getHref(Routes.AGENTS_OVERVIEW, {})
export const getDisconnectedAgentsOverviewHref = (): string =>
  getHref(Routes.DISCONNECTED_AGENTS_OVERVIEW, {})
export const getUnauthorizedAgentsHref = (): string => getHref(Routes.AGENTS_UNAUTHORIZED, {})
export const getAgentHref = (agentId: AgentId, isAllPools?: boolean): string =>
  getHref(
    Routes.AGENT,
    {
      agentId: stringifyId(agentId),
    },
    isAllPools === true ? ALL_POOLS_HASH : null,
  )
export const getAgentPoolHref = (agentPoolId?: AgentPoolId, isAllPools?: boolean): string =>
  getHref(
    Routes.AGENT_POOL,
    {
      agentPoolId: stringifyId(agentPoolId),
    },
    isAllPools === true ? ALL_POOLS_HASH : null,
  )
export const getAgentTypeHref = (
  agentTypeId: AgentTypeId | null | undefined,
  isAllPools?: boolean,
): string =>
  getHref(
    Routes.CLOUD_IMAGE,
    {
      agentTypeId: stringifyId(agentTypeId),
    },
    isAllPools === true ? ALL_POOLS_HASH : null,
  )
export const getTestHistoryHref = (
  testId: TestId | null | undefined,
  params: QueryParams,
): string =>
  `${getHref(Routes.TEST, {
    testId: stringifyId(testId),
  })}?${objectToQuery(params)}`

export const getChangeHref = ({
  changeId,
  buildTypeId,
  personal,
  tab,
}: {
  changeId: ChangeId
  buildTypeId?: BuildTypeId | null | undefined
  personal?: boolean | null | undefined
  tab?: string | null | undefined
}): string =>
  `${getHref(Routes.CHANGE, {changeId: stringifyId(changeId)})}?${objectToQuery({
    personal: personal != null ? String(personal) : null,
    buildTypeId: buildTypeId != null ? stringifyId(buildTypeId) : null,
    tab: tab ?? ChangePageTabName.FILES,
  })}`

export const getChangesHref = (): string => getHref(Routes.CHANGES)

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
export const getPipelinesHref = (): string => getHref(Routes.PIPELINES).slice(0, -2)

export const getClassicOverviewHrefWithCustomBase = ({
  projectId,
  buildTypeId,
  serverId,
  isAdmin = false,
  isTemplate = false,
}: {
  projectId?: ProjectId | null | undefined
  buildTypeId?: BuildTypeId | null | undefined
  serverId: FederationServerId
  isAdmin?: boolean
  isTemplate?: boolean
}): string => {
  if (projectId) {
    return resolveRelativeCustomBase(
      stringifyId(serverId),
      isAdmin ? '/admin/editProject.html' : '/project.html',
      {
        projectId: stringifyId(projectId),
      },
    )
  }

  if (buildTypeId) {
    return resolveRelativeCustomBase(
      stringifyId(serverId),
      isAdmin ? '/admin/editBuild.html' : '/viewType.html',
      isAdmin
        ? {
            id: `${isTemplate ? 'template' : 'buildType'}:${stringifyId(buildTypeId)}`,
          }
        : {
            buildTypeId: stringifyId(buildTypeId),
          },
    )
  }

  return stringifyId(serverId)
}
export const getQueueHref = (): string => getHref(Routes.QUEUE, {})

export const getInvestigationsHref = (): string => getHref(Routes.INVESTIGATIONS)

export type LocationProps = {
  location: Location
  navigate: NavigateFunction
}
export const withLocation: Enhancer<LocationProps, any> = withHook(() => ({
  location: useLocation(),
  navigate: useNavigate(),
}))
export const getHrefWithQueryParams = (
  location: Location,
  href?: string | null,
  params: QueryParams | ((prevParams: QueryParams) => QueryParams) = p => p,
  hash?: string,
): string =>
  browserHistory.createHref({
    ...(href != null ? parsePath(href) : location),
    search: objectToQuery(
      typeof params === 'function' ? params(queryToObject(location.search ?? {})) : params,
    ),
    ...(hash != null
      ? {
          hash: `#${hash}`,
        }
      : null),
  })
//
// export const useHrefWithQueryParams = (
//   href: ?string,
//   params: ?QueryParams | (QueryParams => QueryParams),
//   hash: ?string,
// ) => useLocationSelector(location => getHrefWithQueryParams(location, href, params, hash))
export const isQueueScreen = (pathname: string): boolean =>
  matchRoute(Routes.QUEUE, pathname) != null

// It's not possible to solve this issue with the `react-router-dom`
export function isAgentsScreen(pathname: string) {
  return Boolean(AGENTS_SCREEN_ROUTES.find(route => matchRoute(route, pathname)))
}

export const isPipelinesScreen = (pathname: string): boolean =>
  matchRoute(Routes.PIPELINES, pathname) != null

export const isAllPipelinesScreen = (pathname: string): boolean =>
  matchRoute(Routes.ALL_PIPELINES, pathname) != null

export const isPipelinesEditScreen = (pathname: string): boolean =>
  matchRoute(Routes.EDIT_PIPELINE, pathname) != null

const isPipelinesCreateScreen = (pathname: string): boolean =>
  matchRoute(Routes.CREATE_PIPELINE, pathname) != null

export const isPipelinesRunScreen = (pathname: string, params: Readonly<Params>): boolean =>
  matchRoute(Routes.PIPELINE_RUN, pathname) != null && !!params.pipelineRunId

export const isPipelinesCreateEditScreen = (pathname: string): boolean =>
  isPipelinesEditScreen(pathname) || isPipelinesCreateScreen(pathname)

const matchAny = (routes: string[], pathname: string) =>
  routes.some(route => matchRoute(route, pathname) != null)

const NO_SIDEBAR_ROUTES = [
  Routes.CHANGES,
  Routes.CHANGE,
  Routes.GUIDES,
  Routes.CREATE_PIPELINE,
  Routes.NOTIFICATIONS,
  Routes.WELCOME_PIPELINES,
]
export const isScreenWithSidebar = (pathname: string) => !matchAny(NO_SIDEBAR_ROUTES, pathname)

const SAKURA_UI_ONLY_ROUTES = [
  Routes.COMPARE_BUILDS,
  Routes.GUIDES,
  Routes.PIPELINES,
  Routes.NOTIFICATIONS,
]
export const isSakuraUIOnlyScreen = (pathname: string): boolean =>
  matchAny(SAKURA_UI_ONLY_ROUTES, pathname)

const CENTERED_CONTENT_ROUTES = [Routes.NOTIFICATIONS]
export const isCenteredContentScreen = (pathname: string): boolean =>
  matchAny(CENTERED_CONTENT_ROUTES, pathname)
