import type {Mutable} from 'utility-types'

import {getBranchLocatorPart} from '../../../../rest/locators'
import type {Branch} from '../../../../services/rest'
import escapeLocatorDimension from '../../../../utils/escapeLocatorDimension'
import {isValue} from '../../../../utils/guards'
import {queryToObject} from '../../../../utils/queryParams'
import {OrderByField, OrderDirection} from '../../../packages/Tests/Tests.types'
import type {TestScope} from '../../../packages/Tests/Tests.types'

import {TestFilterStatus} from './TestsFilter/TestsStatusFilter/TestsStatusFilter.utils'
import {ViewType} from './TestsFilter/TestsViewSelect/TestsViewSelect.utils'

export const getTestsLocator = ({
  baseLocator,
  status,
  name,
  onlyNew,
  withoutInvestigator,
  branch,
  currentPage,
  count,
  scope,
  orderBy,
  order,
}: {
  baseLocator?: string
  status?: TestFilterStatus
  name?: string
  onlyNew?: boolean
  withoutInvestigator?: boolean
  branch?: Branch | null | undefined
  currentPage?: number
  count?: number
  scope?: TestScope
  orderBy?: OrderByField | null | undefined
  order?: OrderDirection
}): string => {
  let filterLocator = ''
  const branchPart = getBranchLocatorPart(branch)
  const branchLocator = branch != null && branchPart != null ? `branch(${branchPart})` : ''

  if (status === TestFilterStatus.ALL) {
    filterLocator = 'ignored:any,muted:any'
  }

  if (status === TestFilterStatus.MUTED) {
    filterLocator = 'muted:true,ignored:false'
  }

  if (status === TestFilterStatus.IGNORED) {
    filterLocator = 'ignored:true,muted:false'
  }

  if (status === TestFilterStatus.FAILED) {
    filterLocator = 'status:FAILURE,ignored:false,muted:false'
  }

  if (status === TestFilterStatus.PASSED) {
    filterLocator = 'status:SUCCESS,ignored:false,muted:false'
  }

  const scopeParts = [
    scope?.suite != null ? `suite:(value:(${scope.suite}),matchType:equals)` : '',
    scope?.package != null ? `package:(value:(${scope.package}),matchType:equals)` : '',
    scope?.class != null ? `class:(value:(${scope.class}),matchType:equals)` : '',
  ].filter(Boolean)
  const scopeLocator = scopeParts.length > 0 ? `scope:(${scopeParts.join(',')})` : ''
  const testNameLocator =
    name != null && name !== ''
      ? `name:(value:${escapeLocatorDimension(name)},matchType:contains,ignoreCase:true)`
      : ''
  const onlyNewLocator = onlyNew ? `newFailure:true` : ''
  const withoutInvestigatorLocator = withoutInvestigator ? `currentlyInvestigated:false` : ''
  const orderLocator = orderBy != null && order != null ? `orderBy:(${orderBy}:${order})` : ''
  const countLocator = count != null ? `count:${count}` : ''
  const startLocator =
    currentPage != null && count != null ? `start:${(currentPage - 1) * count}` : ''
  return [
    baseLocator,
    branchLocator,
    filterLocator,
    scopeLocator,
    testNameLocator,
    onlyNewLocator,
    withoutInvestigatorLocator,
    orderLocator,
    countLocator,
    startLocator,
    'includePersonal:true',
  ]
    .filter(Boolean)
    .join(',')
}
export const getCountersLocator = (buildLocator: string): string =>
  `build:(${buildLocator}),count:-1`
type FilterQueryParams = {
  view: ViewType
  order: OrderDirection
  orderBy: OrderByField
  scope: TestScope
  onlyNew: boolean
  name: string
  withoutInvestigator: boolean
}
export const defaultFilterTemplate = {
  view: ViewType.TESTS,
  order: OrderDirection.DESC,
  orderBy: OrderByField.NEW_FAILURE,
  scope: {
    suite: undefined,
    package: undefined,
    class: undefined,
  },
  onlyNew: false,
  withoutInvestigator: false,
  name: '',
}
export const getFilterQueryParams = (
  location: {search: string},
  defaultFilter: FilterQueryParams = defaultFilterTemplate,
): FilterQueryParams => {
  const {
    view,
    order,
    orderBy,
    onlyNew,
    withoutInvestigator,
    name,
    suite,
    package: testPackage,
    class: testClass,
  } = queryToObject(location.search)
  const result: Mutable<FilterQueryParams> = {...defaultFilter}

  if (view != null && isValue(ViewType, view)) {
    result.view = view
  }

  if (order != null && isValue(OrderDirection, order)) {
    result.order = order
  }

  if (orderBy != null && isValue(OrderByField, orderBy)) {
    result.orderBy = orderBy
  }

  if (onlyNew != null) {
    result.onlyNew = onlyNew === 'true'
  }

  if (withoutInvestigator != null) {
    result.withoutInvestigator = withoutInvestigator === 'true'
  }

  if (name != null) {
    result.name = decodeURIComponent(name ?? '')
  }

  if (suite != null) {
    result.scope = {...result.scope, suite}
  }

  if (testPackage != null) {
    result.scope = {...result.scope, package: testPackage}
  }

  if (testClass != null) {
    result.scope = {...result.scope, class: testClass}
  }

  return result
}
