import classNames from 'classnames'
import {differenceInSeconds} from 'date-fns/differenceInSeconds'
import {parseISO} from 'date-fns/parseISO'
import * as React from 'react'

import {useIsAutodetectTimeZone} from '../../../../hooks/useIsAutodetectTimeZone'
import {useDistanceToNow} from '../../../../hooks/useTimeSelector'
import type {BuildStatus} from '../../../../types'
import {formatDuration, parseDateToObj} from '../../../../utils/dateTimeUtils'
import Icon from '../../Icon/Icon'

import styles from './BuildDurationAnchor.css'

const innerStyle = (progress: string) => ({
  width: progress,
})

type Props = {
  elapsed?: number
  exact?: boolean
  className?: string
  // seconds
  left?: number
  //seconds
  overtime?: number
  status?: BuildStatus
  progress?: number
  started?: string | null | undefined
  finished?: string | null | undefined
  low?: boolean
  withBorder?: boolean
  isOvertime?: boolean
}
export default function BuildDurationAnchor(props: Props) {
  const isAutodetectTimeZone = useIsAutodetectTimeZone()

  function getProgressWidth(): string {
    const progress = isNaN(props.progress ?? 0) ? 0 : Number(props.progress ?? 0)
    return `${progress}%`
  }

  function getProgressTextWidth(): string {
    const hundred = 100
    const progress = isNaN(props.progress ?? 0)
      ? 0
      : Number((hundred * hundred) / (props.progress ?? 0))
    return `${progress}%`
  }

  const finishedFromNow = useDistanceToNow(props.finished)

  function getText(): React.ReactNode {
    let result
    const {started, finished, elapsed = 0, left, overtime, exact = false} = props

    if (started != null && finished != null) {
      const duration = formatDuration(differenceInSeconds(parseISO(finished), parseISO(started)), {
        alwaysShowSeconds: exact,
      })
      result = exact ? (
        <React.Fragment>
          <span
            className={classNames(styles.startedFinished, styles.exactStarted)}
            title="Start date"
          >
            {parseDateToObj(started, isAutodetectTimeZone).format('D MMM YY LT')}
          </span>
          <span
            className={classNames(styles.durationFinished, styles.exactDuration)}
            title="Duration"
          >
            {duration}
          </span>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <span className={styles.startedFinished} title="Finished">
            {finishedFromNow}
          </span>
          <span className={classNames(styles.durationFinished)} title="Duration">
            {duration}
          </span>
        </React.Fragment>
      )
    } else if (overtime != null && props.isOvertime) {
      result = (
        <span className={classNames(styles.text, styles.textForOvertime)}>
          {formatDuration(overtime)}
        </span>
      )
    } else if (left == null || left < 0) {
      // no estimation, e.g. first build
      result = <span className={styles.text}>{`${formatDuration(elapsed)} passed`}</span>
    } else {
      result = <span className={styles.text}>{`${formatDuration(left || 0)} left`}</span>
    }

    return result
  }

  function renderContent(): React.ReactNode {
    const {started, finished, status, withBorder} = props

    if (started == null) {
      return null
    }

    const text = getText()

    if (finished != null) {
      return text
    }

    const isFailure = status !== 'SUCCESS'

    const wrapperClasses = classNames(styles.wrapper, {
      [styles.withBorder]: withBorder,
      [styles.wrapperSuccess]: !isFailure,
      [styles.wrapperFailure]: isFailure,
      [styles.wrapperOvertime]: props.isOvertime,
    })
    const progressClasses = classNames(styles.progress, {
      [styles.progressFailure]: isFailure,
      [styles.progressOvertime]: props.isOvertime,
    })
    const overtimeIcon = props.isOvertime ? <Icon name="overtime" className={styles.icon} /> : null
    return (
      <div className={wrapperClasses}>
        {text}
        {overtimeIcon}

        <div style={innerStyle(getProgressWidth())} className={progressClasses}>
          <div style={innerStyle(getProgressTextWidth())}>
            {text}
            {overtimeIcon}
          </div>
        </div>
      </div>
    )
  }

  const {className, low, started} = props
  const classes = classNames(styles.buildDuration, className, {
    [styles.low]: low,
    [styles.placeholder]: !started,
  })
  return <div className={classes}>{renderContent()}</div>
}
