import type {CaseReducer, PayloadAction} from '@reduxjs/toolkit'

import type {ConfirmationDecision} from '../../services/pipelinesApi.types'

import type {PipelineDraftState} from './EditPipelinePage.slices.types'

export const setVersionedSettings: CaseReducer<
  PipelineDraftState,
  PayloadAction<{
    id: string
    storedInRepo?: boolean
    filename?: string | null
    confirmationDecision?: ConfirmationDecision
  }>
> = (state, action) => {
  const {id, filename = null, ...updatedSettings} = action.payload
  const {draft} = state[id] ?? {}

  if (draft != null) {
    draft.versionedSettings = {
      ...draft.versionedSettings,
      filename,
      ...updatedSettings,
    }
  }
}
