import {connect} from 'react-redux'

import type {State} from '../../../../reducers/types'
import {getBuild} from '../../../../selectors'
import type {BuildId} from '../../../../types'
import {getBuildOvertimeInfo} from '../../../../utils/buildOvertime'

import BuildDurationDetails from './BuildDurationDetails'

type Props = {
  buildId: BuildId
}

const mapStateToProps = (state: State, props: Props) => {
  const build = getBuild(state, props.buildId)
  const {elapsedSeconds = 0, estimatedTotalSeconds = 0, leftSeconds} = build?.['running-info'] || {}
  return {
    elapsed: elapsedSeconds,
    estimated: estimatedTotalSeconds,
    left: leftSeconds,
    overtime: getBuildOvertimeInfo(build?.['running-info']).seconds,
    started: build?.startDate,
    finished: build?.finishDate,
  }
}

export default connect(mapStateToProps)(BuildDurationDetails)
