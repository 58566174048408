import {useContext, createContext, memo} from 'react'

import useBannersStore from './BannersContext.hooks'
import type {BannersContextProps, BannersProviderProps} from './BannersContext.types'

const BannersContext = createContext<BannersContextProps>({} as BannersContextProps)

function BannersProvider({children}: BannersProviderProps) {
  const store = useBannersStore()

  return <BannersContext.Provider value={store}>{children}</BannersContext.Provider>
}

export default memo(BannersProvider)

export const useBanners = (): BannersContextProps => useContext(BannersContext)
