import type {QueryReturnValue} from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import type {
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/dist/query/fetchBaseQuery'
import type {MaybePromise} from '@reduxjs/toolkit/dist/query/tsHelpers'

import * as serviceWorkerUpdateActions from '../actions/serviceWorkers'
import {checkIfNonValidResponse} from '../actions/utils'
import type {RunnerType} from '../components/App/SelectBuildRunners/SelectBuildRunners.types'
import type {
  BuildLogMessagesResponse,
  BuildLogMessagesResponseAnchor,
  BuildLogTimeline,
  FetchMessagesParams,
  LogView,
} from '../components/packages/BuildLog/BuildLog.types'
import {problemOccurrencesTreeFields} from '../components/packages/BuildProblems/BuildProblems.rest'
import type {ProblemOccurrencesTree} from '../components/packages/BuildProblems/BuildProblems.types'
import {getBuildProblemsTreeFields} from '../components/packages/Problems/BuildProblems/BuildProblems.rest'
import type {BuildProblemsTree} from '../components/packages/Problems/BuildProblems/BuildProblems.types'
import {getTestOccurrencesTreeFields} from '../components/packages/Tests/Tests.rest'
import type {
  RequestFlakyTests,
  RequestTestOccurrenceOptionsParams,
  TestFlaky,
  TestOccurrencesTree,
} from '../components/packages/Tests/Tests.types'
import {getTestOccurrencesEntities} from '../components/packages/Tests/Tests.utils'
import type {BuildSnippet} from '../components/pages/BuildPage/BuildOverviewTab/BuildSnippets/BuildSnippets.types'
import type {
  DiskUsage,
  DiskUsageNode,
  PoliciesResponse,
  PolicyHolder,
  UpdatePolicyPayload,
} from '../components/pages/CleanupProjectPage/CleanupProjectPage.types'
import {getEmptyDiskUsage} from '../components/pages/CleanupProjectPage/CleanupProjectPage.types'
import type {
  CleanupHolderNode,
  CreateRuleAnswer,
  FetchRulesOptons,
  GetRulesResponse,
  HolderRule,
  JSendResponse,
  Rule,
  RuleId,
} from '../components/pages/CleanupProjectPage/Listings/Rules/Rules.types'
import {
  getCleanupBuildTypeNode,
  getCleanupProjectNode,
} from '../components/pages/CleanupProjectPage/Listings/Rules/Rules.types'
import {
  getHolderParams,
  getKey,
} from '../components/pages/CleanupProjectPage/Listings/Rules/Rules.utils'
import type {
  BuildForCompareData,
  CompareBuildsInfo,
  CompareBuildsList,
} from '../components/pages/CompareBuildsPage/CompareBuildsPage.types'
import type {TriggerBuildOptions} from '../rest/builds'
import {getPromoteOptions} from '../rest/builds'
import {subscribeOnServiceWorkerMessage} from '../rest/caching/events'
import type {
  Entities,
  Normalized,
  NormalizedAgents,
  NormalizedBranches,
  NormalizedBuilds,
  NormalizedBuildTypes,
  NormalizedChanges,
  NormalizedProjects,
  NormalizedSingleAgent,
  NormalizedSingleBuild,
  NormalizedSingleBuildType,
  NormalizedSingleProject,
  NormalizedTestOccurrences,
  NormalizedTestProblems,
} from '../rest/schemata'
import {
  getStatusKey,
  normalizeAgent,
  normalizeAgentPreviews,
  normalizeAgents,
  normalizeBranches,
  normalizeBuild,
  normalizeBuildProblems,
  normalizeBuildProblemsInvestigations,
  normalizeBuildProblemsMutes,
  normalizeBuilds,
  normalizeBuildType,
  normalizeBuildTypeDescription,
  normalizeBuildTypeLinks,
  normalizeBuildTypeLinksFromAgentsAndBuilds,
  normalizeBuildTypeLinksFromBuilds,
  normalizeBuildTypeParameters,
  normalizeBuildTypes,
  normalizeChanges,
  normalizeModificationsOfChange,
  normalizeOverview,
  normalizeProblemOccurrences,
  normalizeProjectsData,
  normalizeTestOccurrences,
  normalizeTestOccurrencesInvocations,
  normalizeTestProblems,
  normalizeTestProblemsFailingBuildTypes,
  normalizeTestProblemsFailingBuildTypesCount,
  normalizeTestProblemsInvestigations,
  normalizeTestProblemsMutes,
  normalizeTestProblemTestOccurrenceBuilds,
  normalizeTestProblemTestOccurrences,
} from '../rest/schemata'
import stream from '../rest/stream'
import type {
  AgentDetails,
  AgentPoolId,
  BuildId,
  BuildTypeHierarchy,
  BuildTypeId,
  BuildTypeInternalId,
  ChangeId,
  DslOptionsResponse,
  ExpandState,
  ProjectId,
  ProjectInternalId,
  ProjectOrBuildTypeStatus,
  RequestOptionsParams,
  StatusRequest,
  Tab,
  TestOccurrenceId,
} from '../types'
import {stringifyId, toBuildId} from '../types'
import {base_uri} from '../types/BS_types'
import {noop} from '../utils/empty'
import {mergeEntities} from '../utils/entities'
import {notNull} from '../utils/guards'
import {keyBy} from '../utils/keyBy'
import type {KeyValue} from '../utils/object'
import {entriesToQuery, objectToQuery} from '../utils/queryParams'
import {
  CACHE_ALL_BUILDS,
  CACHE_PROJECTS_TREE,
  CACHE_TABS,
  SW_CACHING_HEADER_NAME,
  SW_FORCE_REVALIDATE_HEADER_NAME,
  CACHE_TEST_SCOPES,
  CACHE_PROJECTS,
  CACHE_BUILD_TYPE,
  CACHE_CHANGELOG,
  CACHE_BUILD_TYPES,
  CACHE_ALL_BUILDS_DETAILS,
} from '../workers/sw.consts'

import {restBaseApi as api} from './restBase'

export const getIdFromLocator = (locator: string) => {
  const [_, id] = locator.match(/^id:([^,]*)$/) ?? []
  return id
}

export const processBuildType = (data: BuildType) => {
  const buildType = normalizeBuildType(data)
  return {
    result: buildType.result,
    entities: {
      ...buildType.entities,
      ...normalizeBuildTypeLinks(data).entities,
      ...normalizeBuildTypeDescription(data).entities,
      ...normalizeBuildTypeParameters(data).entities,
    },
  }
}

export const processChangeLog = ({graph, row, ...rest}: GetChangeLogApiResponse) => {
  const changes: Array<Change> = []
  const builds: Array<Build> = []

  row.forEach(item => {
    if (item.build != null) {
      builds.push(item.build)
    }
    if (item.change != null) {
      changes.push(item.change)
    }
  })

  return {
    graph,
    rows: row,
    changes: changes.length > 0 ? normalizeChanges(changes) : null,
    builds: builds.length > 0 ? normalizeBuilds(builds) : null,
    ...rest,
  }
}

export const processAgents = (data: readonly Agent[]) => {
  const agents = normalizeAgents(data)
  Object.assign(
    agents.entities,
    normalizeAgentPreviews(data).entities,
    normalizeBuildTypeLinksFromAgentsAndBuilds(data).entities,
  )
  return agents
}

type FetchSingleAgentDataPayload = {
  agent: NormalizedSingleAgent
  details: AgentDetails
}

export function processAgent(data: Agent): FetchSingleAgentDataPayload {
  const agent = normalizeAgent(data)
  const {host, port, protocol, cpuRank, connectedSince} = data
  return {
    agent,
    details: {
      host,
      port,
      protocol,
      cpuRank,
      connectedSince,
    },
  }
}

export const processProject = (
  data: Project,
  requestOptions: RequestOptionsParams = {
    withBuildTypes: true,
    withLinks: true,
    withParameters: true,
    withDescription: true,
    withArchivedSubprojectsIds: true,
  },
) => {
  const projects = normalizeProjectsData([data], requestOptions, false)
  return {
    entities: projects.entities,
    result: projects.result[0],
  }
}

export const processBuildsData = (
  data: readonly Build[],
  requestOptions?: RequestOptionsParams,
) => {
  const builds = normalizeBuilds(data)
  const withBuildTypes = requestOptions?.withBuildTypeDetails
  let entities = {...builds.entities}

  if (withBuildTypes === true) {
    const buildTypeLinks = normalizeBuildTypeLinksFromBuilds(data)
    entities = {...entities, buildTypeLinks: buildTypeLinks.entities.buildTypeLinks}
  }

  return {
    result: builds.result,
    entities,
  }
}

const executeAll = <T, R>(
  promises: MaybePromise<
    QueryReturnValue<T, FetchBaseQueryError, FetchBaseQueryMeta | undefined>
  >[],
  transformResponse: (data: T[]) => R,
): Promise<QueryReturnValue<R, FetchBaseQueryError, FetchBaseQueryMeta | undefined>> =>
  Promise.all(
    promises.map(async promise => {
      const result = await promise
      if (result.error != null) {
        throw result.error
      }
      return result
    }),
  )
    .then(results => ({data: transformResponse(results.map(({data}) => data).filter(notNull))}))
    .catch(error => ({error}))

export const processTestOccurrenceTree = (
  data: TestOccurrencesTree,
  options?: RequestTestOccurrenceOptionsParams,
) => {
  let entities: Partial<Entities> = {}
  const leaves = data.leaf ?? []
  leaves.forEach(leaf => {
    const testOccurrences = leaf.testOccurrences?.testOccurrence ?? []
    const normalizedTestOccurrences = getTestOccurrencesEntities(testOccurrences, options)
    entities = mergeEntities(entities, normalizedTestOccurrences.entities)
  })
  return {tree: data, entities}
}

export const processTestOccurrencesInvocations = ({
  data: testOccurrencesData,
  options,
}: {
  data: ReadonlyArray<TestOccurrence>
  options?: RequestTestOccurrenceOptionsParams
}) => {
  const normalizedTestOccurrences = normalizeTestOccurrences(testOccurrencesData)
  const normalizedTestOccurrencesInvocations =
    normalizeTestOccurrencesInvocations(testOccurrencesData)
  let entities = {...normalizedTestOccurrencesInvocations.entities}
  const testOccurrences = normalizedTestOccurrences.entities.testOccurrences
  normalizedTestOccurrences.result.forEach((testOccurrenceId: TestOccurrenceId) => {
    const invocations = testOccurrences?.[testOccurrenceId]?.invocations?.testOccurrence ?? []
    const testOccurrencesInvocations = getTestOccurrencesEntities(invocations, options)
    entities = mergeEntities(entities, testOccurrencesInvocations.entities)
  })
  return {
    entities,
    result: normalizedTestOccurrencesInvocations.result,
  }
}

export const processTestProblems = (data: ReadonlyArray<TestProblem>) => {
  const normalizedProblems = normalizeTestProblems(data)
  const problems = normalizedProblems.entities.testProblems
  let entities = {
    ...normalizedProblems.entities,
    ...normalizeTestProblemsInvestigations(data).entities,
    ...normalizeTestProblemsMutes(data).entities,
    ...normalizeTestProblemsFailingBuildTypesCount(data).entities,
    ...normalizeTestProblemsFailingBuildTypes(data).entities,
  }

  normalizedProblems.result.forEach(testId => {
    const buildTypes = problems?.[testId]?.failingBuildTypes?.buildType
    if (buildTypes) {
      const normalizedBuildTypes = normalizeBuildTypes(buildTypes)
      entities = mergeEntities(entities, normalizedBuildTypes.entities)
    }
  })

  return {
    entities,
    result: normalizedProblems.result,
  }
}

export const processTestProblemTestOccurrences = (data: ReadonlyArray<TestOccurrence>) => {
  const normalizedTestOccurrences = normalizeTestProblemTestOccurrences(data)
  const entities = {
    ...normalizeTestProblemTestOccurrenceBuilds(data).entities,
    ...normalizedTestOccurrences.entities,
  }

  return {
    entities,
    result: normalizedTestOccurrences.result,
  }
}

export const processBuildProblemsTree = (data: BuildProblemsTree) => {
  let entities: Partial<Entities> = {}
  const leaves = data.leaf ?? []
  leaves.forEach(leaf => {
    const problemEntries = leaf.problemEntries?.entry ?? []
    const normalizedBuildProblems = normalizeBuildProblems(problemEntries)
    const allEntities = {
      ...normalizedBuildProblems.entities,
      ...normalizeBuildProblemsMutes(problemEntries).entities,
      ...normalizeBuildProblemsInvestigations(problemEntries).entities,
    }
    entities = mergeEntities(entities, allEntities)
  })
  return {tree: data, entities}
}

export const processProblemOccurrencesTree = (data: ProblemOccurrencesTree) => {
  let entities: Partial<Entities> = {}
  const leaves = data.leaf ?? []
  leaves.forEach(leaf => {
    const problemOccurrences = leaf.problemOccurrences?.problemOccurrence ?? []
    const normalizedProblemOccurrences = normalizeProblemOccurrences(problemOccurrences)
    entities = mergeEntities(entities, normalizedProblemOccurrences.entities)
  })
  return {tree: data, entities}
}

const processMultipleBuildsOperationResult = (data: MultipleOperationResult) =>
  normalizeBuilds(
    data.operationResult?.map(operationResult => operationResult.related?.build).filter(notNull) ??
      [],
  )

export const processHoldersWithRules = (holdersWithRules: ReadonlyArray<HolderRule>) =>
  keyBy(holdersWithRules, item => {
    const node: CleanupHolderNode =
      item.holder === 'project'
        ? getCleanupProjectNode({
            id: item.holderExternalId,
          })
        : getCleanupBuildTypeNode({
            id: item.holderExternalId,
          })
    return getKey(node)
  })

export const getEssentialHeader = (essential?: boolean | null): Record<string, string> =>
  essential ? {'X-TeamCity-Essential': 'true'} : {}

const getBuildLogMessagesQuery = ({endpoint, buildId, options = {}}: GetBuildLogMessagesArg) => {
  const {
    count,
    logAnchor,
    expandState,
    testOccurrenceId,
    problemOccurrenceId,
    stageKey,
    target,
    expandAll,
    filter,
    searchQuery,
    logView,
    expandSubtree,
    expandFailures,
  } = options

  let expandStateCorrected = expandState ?? []

  if (expandAll === true) {
    expandStateCorrected = expandStateCorrected.filter(id => id !== 0)
  } else {
    expandStateCorrected = [0, ...expandStateCorrected]
  }

  return {
    url: `/${endpoint}`,
    params: {
      buildId,
      messagesCount: count,
      ...(filter && {filter: filter === 'debug' ? 'verbose' : filter}),
      view: logView,
      messageId: logAnchor || undefined,
      query: searchQuery,
      testOccurrenceId,
      problemOccurrenceId,
      expandSubtree,
      _focus: logAnchor
        ? `${logAnchor}#_state=${expandStateCorrected.join(',')}`
        : expandState != null
          ? `0#_state=${expandStateCorrected.join(',')}`
          : undefined,
      stage: stageKey,
      target,
      expandAll,
      expandFailures,
    },
  }
}

const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    /* Start of Non REST-API controllers */
    getAvailableRunners: build.query<GetAvailableRunnersResponse, GetAvailableRunnersArg>({
      query: queryArg => ({url: `/app/availableRunners?projectId=${queryArg.projectId}`}),
    }),
    getTabs: build.query<Tab[], GetTabsArg>({
      queryFn: ({key, cacheTabs, essential}, {forced}, _, baseQuery) =>
        baseQuery({
          url: `/app/tabs?${key}`,
          headers: {
            ...(cacheTabs ? {[SW_CACHING_HEADER_NAME]: CACHE_TABS} : undefined),
            ...(forced ? {[SW_FORCE_REVALIDATE_HEADER_NAME]: 'true'} : undefined),
            ...getEssentialHeader(essential),
          },
        }) as MaybePromise<
          QueryReturnValue<Tab[], FetchBaseQueryError, FetchBaseQueryMeta | undefined>
        >,
      serializeQueryArgs: ({queryArgs}) => queryArgs.key,
      async onCacheEntryAdded(
        {key, cacheTabs},
        {updateCachedData, cacheDataLoaded, cacheEntryRemoved},
      ) {
        if (cacheTabs) {
          await cacheDataLoaded
          const unsubscribe = subscribeOnServiceWorkerMessage<Tab[]>({
            url: `${base_uri}/app/tabs?${key}`,
            handler: response => {
              if (!checkIfNonValidResponse(response)) {
                updateCachedData(() => response.payload)
              }
            },
          })
          await cacheEntryRemoved
          unsubscribe()
        }
      },
    }),
    getBuildTimeline: build.query<BuildLogTimeline, GetBuildTimelineArg>({
      query: ({buildId}) => ({
        url: '/app/timeline',
        params: {buildId},
      }),
    }),
    getDockerImages: build.query<GetDockerImagesResponse, GetDockerImagesArg>({
      query: ({q}) => ({
        url: '/app/docker/dockerhub/search',
        params: {q},
      }),
    }),
    getParameterAutocompletion: build.query<
      ParameterAutocompletionResponse,
      GetParameterAutocompletionArg
    >({
      query: ({settingsId, term}) => ({
        url: '/admin/parameterAutocompletion.html',
        params: {settingsId, term},
      }),
    }),
    getDockerTags: build.query<GetDockerTagsResponse, GetDockerTagsArg>({
      query: ({image, name}) => ({
        url: '/app/docker/dockerhub/tags',
        params: {image, name},
      }),
    }),
    testDockerConnection: build.mutation<Document, TestDockerConnectionArg>({
      query: ({userName, url, userPass}) => ({
        url: '/repo/registry-test-connection.html',
        method: 'POST',
        body: {
          userName,
          repositoryUrl: url,
          'secure:userPass': userPass,
        },
      }),
      transformResponse: (data: string) => new DOMParser().parseFromString(data, 'text/xml'),
    }),
    testNpmConnection: build.mutation<Document, TestNpmConnectionArg>({
      query: ({url, scope}) => ({
        url: '/app/nodejs/connection/test/',
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
        body: new URLSearchParams({
          'prop:npmRegistryHost': url,
          'prop:npmRegistryScope': scope,
        }).toString(),
        responseHandler: 'text',
      }),
      transformResponse: (data: string) => new DOMParser().parseFromString(data, 'text/xml'),
    }),
    getStatuses: build.query<Record<string, ProjectOrBuildTypeStatus>, GetStatusesArg>({
      query: ({endpoint, requestIds}) => ({
        url: `/${endpoint}`,
        params: {statuses: true},
        method: 'POST',
        body: requestIds,
      }),
      transformResponse: (data: ProjectOrBuildTypeStatus[]) => keyBy(data, getStatusKey),
    }),
    getExpandState: build.query<Record<string, ExpandState>, string>({
      query: endpoint => ({url: `/${endpoint}`, params: {expandState: true}}),
      transformResponse: (data: ReadonlyArray<ExpandState>) => keyBy(data, 'id'),
    }),
    updateExpandState: build.mutation<string, UpdateExpandStateArg>({
      query: ({endpoint, data}) => ({
        url: `/${endpoint}`,
        params: {expandState: true},
        method: 'POST',
        body: data,
        responseHandler: 'text',
      }),
      onQueryStarted({endpoint, data}, {dispatch, queryFulfilled}) {
        const patchResult = dispatch(
          injectedRtkApi.util.updateQueryData('getExpandState', endpoint, draft => {
            data.forEach(item => {
              draft[item.id!] = item
            })
          }),
        )
        queryFulfilled.catch(patchResult.undo)
      },
    }),
    getArtifactExtension: build.query<ArtifactExtension, GetArtifactExtensionArg>({
      query: ({endpoint, buildId}) => ({url: `/${endpoint}`, params: {buildId}}),
    }),
    getFlakyTests: build.query<ReadonlyArray<TestFlaky>, GetFlakyTestsArg>({
      query: ({endpoint, tests}) => ({url: `/${endpoint}`, method: 'POST', body: tests}),
    }),
    setProjectOrder: build.mutation<unknown, SetProjectOrderArg>({
      query: order => ({
        url: '/visibleProjects.html',
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
        body: entriesToQuery([
          ['save', 'true'],
          ...order.map<[string, string]>(id => ['projects_visible', id]),
          ['projects_order', order.join(',')],
        ]),
        responseHandler: 'text',
      }),
    }),
    setBuildTypeOrder: build.mutation<unknown, SetBuildTypeOrderArg>({
      query: ({projectId, order}) => ({
        url: '/visibleBuildTypes.html',
        params: {projectId},
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
        body: entriesToQuery([
          ['save', 'true'],
          ...order.map<[string, string]>(item => ['bt_visible', item]),
          ['bt_order', order.join(',')],
        ]),
        responseHandler: 'text',
      }),
    }),
    getPoolPermissions: build.query<Record<AgentPoolId, PoolPermission>, void>({
      query: () => ({url: '/overview', params: {poolPermissions: 'true'}}),
      transformResponse: (data: PoolPermission[]) => keyBy(data, 'poolId'),
    }),
    getHtml: build.query<string, GetHtmlArg>({
      query: ({path, method}) => ({
        url: path,
        method: method ?? 'GET',
        headers: {
          Accept: '*/*',
        },
        responseHandler: 'text',
      }),
    }),
    toggleFavoriteProject: build.mutation<string, ToggleFavoriteProjectArg>({
      query: ({projectInternalId, on, skipSubprojects}) => ({
        url: 'ajax.html',
        params: {[on ? 'addProject' : 'hideProject']: projectInternalId, skipSubprojects},
        method: 'POST',
        responseHandler: 'text',
      }),
    }),
    toggleFavoriteBuildType: build.mutation<string, ToggleFavoriteBuildTypeArg>({
      query: ({projectId, buildTypeInternalId, on}) => ({
        url: 'visibleBuildTypes.html',
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
        body: objectToQuery({
          projectId: stringifyId(projectId),
          bt_visible: buildTypeInternalId,
          [on ? 'showOne' : 'hideOne']: 'true',
        }),
        responseHandler: 'text',
      }),
    }),
    setFavoriteBuildTypes: build.mutation<string, SetFavoriteBuildTypesArg>({
      query: ({projectId, buildTypeInternalIds}) => ({
        url: 'visibleBuildTypes.html',
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
        body: objectToQuery({
          projectId: stringifyId(projectId),
          bt_visible: buildTypeInternalIds.join(','),
          bt_order: buildTypeInternalIds.join(','),
        }),
        responseHandler: 'text',
      }),
    }),
    getDslOptions: build.query<DslOptionsResponse, void>({
      query: () => ({url: 'dslApiVersions.html'}),
    }),
    runBuild: build.mutation<RunBuildResponse, RunBuildArg>({
      query: ({buildTypeId, branch, options}) => {
        const defaultBranch = branch?.default === true || branch?.groupFlag === true
        const branchName = defaultBranch ? null : branch?.name
        return {
          url: 'ajax.html',
          method: 'POST',
          body: objectToQuery({
            add2Queue: stringifyId(buildTypeId),
            validate: 'true',
            branchName,
            ...getPromoteOptions(options.promoteId),
          }),
          headers: {
            Accept: 'text/xml',
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          },
          responseHandler: 'text',
        }
      },
      transformResponse: (data: string) => {
        const xml = new DOMParser().parseFromString(data, 'text/xml')
        const queuedBuild = xml.querySelector('queuedBuild')
        const queuedBuildIdAttr = queuedBuild ? queuedBuild.getAttribute('itemId') : null
        const queuedBuildId = queuedBuildIdAttr != null ? toBuildId(queuedBuildIdAttr) : null
        return {
          queuedBuildId,
          showDialog: xml.getElementById('action_showRunCustomBuildDialog') != null,
        }
      },
    }),
    getCompareBuildsList: build.query<CompareBuildsList, GetCompareBuildsListArg>({
      query: ({endpoint, sourceId, compareWithId}) => ({
        url: `/${endpoint}/list`,
        params: {build1: sourceId, build2: compareWithId},
        headers: getEssentialHeader(true),
      }),
    }),
    getCompareBuildsInfo: build.query<CompareBuildsInfo, GetCompareBuildsInfoArg>({
      query: ({endpoint, compareType, sourceId, compareWithId, mode, filterText}) => ({
        url: `/${endpoint}/${sourceId}/to/${compareWithId}/with/${compareType}`,
        params: {mode, text: filterText},
      }),
    }),
    getBuildsForCompare: build.query<BuildForCompareData, GetBuildsForCompareArg>({
      query: ({endpoint, sourceId}) => ({
        url: `/${endpoint}/builds/for/${sourceId}`,
      }),
    }),
    getCleanupRules: build.query<KeyValue<string, HolderRule>, GetCleanupRulesArg>({
      query: ({holderNode, options}) => {
        const {hierarchy = 'effective', getChildrensRules = 'withOwnBuildTypes'} = options || {}
        const {holder, holderId} = getHolderParams(holderNode)
        return {
          url: '/admin/cleanupRules.html',
          params: {
            holder,
            holderId,
            rulesHierarchy: hierarchy,
            getForProjectChildren: getChildrensRules,
          },
          withJsonError: true,
        }
      },
      transformResponse: (response: GetRulesResponse) =>
        processHoldersWithRules(response.data.holdersWithRules),
      providesTags: ['CleanupRules'],
    }),
    deleteCleanupRule: build.mutation<CreateRuleAnswer, DeleteCleanupRuleArg>({
      query: ({holderNode, ruleId}) => {
        const {holder, holderId} = getHolderParams(holderNode)
        return {
          url: '/admin/cleanupRules.html',
          method: 'POST',
          body: {
            holder,
            holderId,
            type: 'delete',
            ruleId,
          },
          withJsonError: true,
        }
      },
      invalidatesTags: ['CleanupRules'],
    }),
    createCleanupRule: build.mutation<CreateRuleAnswer, CreateCleanupRuleArg>({
      query: ({holderNode, rule, ruleId}) => {
        const {holder, holderId} = getHolderParams(holderNode)
        const {ruleDisabled, ...restRule} = rule
        return {
          url: '/admin/cleanupRules.html',
          method: 'POST',
          body: {
            holder,
            holderId,
            ruleId: ruleId ?? null,
            ruleDisabled,
            type: 'create',
            rule: ruleDisabled ? null : restRule,
          },
          withJsonError: true,
        }
      },
      invalidatesTags: ['CleanupRules'],
    }),
    updateCleanupRule: build.mutation<CreateRuleAnswer, UpdateCleanupRuleArg>({
      query: ({holderNode, rule}) => {
        const {holder, holderId} = getHolderParams(holderNode)
        const {ruleId, ruleDisabled, ...restRule} = rule
        return {
          url: '/admin/cleanupRules.html',
          method: 'POST',
          body: {
            holder,
            holderId,
            type: 'update',
            ruleId: ruleId ?? null,
            ruleDisabled,
            rule: restRule,
          },
          withJsonError: true,
        }
      },
      invalidatesTags: ['CleanupRules'],
    }),
    getCleanupPolicies: build.query<JSendResponse<PoliciesResponse>, GetCleanupPoliciesArg>({
      query: ({projectInternalId, includeSubprojects}) => ({
        url: '/admin/cleanupPoliciesRest.html',
        params: {
          baseProjectId: projectInternalId,
          includeSubprojectsPolicies: includeSubprojects,
        },
        withJsonError: true,
      }),
      providesTags: ['CleanupPolicies'],
    }),
    updateCleanupPolicyDisableState: build.mutation<unknown, UpdateCleanupPolicyDisableStateArg>({
      query: ({holder, cleanupPoliciesDisabled}) => ({
        url: '/admin/cleanupPoliciesRest.html',
        method: 'POST',
        body: {
          holderId: stringifyId(holder.holderId),
          holder: holder.holderType,
          cleanupPoliciesDisabled,
          type: 'updateDisableState',
        },
        withJsonError: true,
      }),
      invalidatesTags: ['CleanupPolicies'],
    }),
    resetCleanupPolicy: build.mutation<unknown, PolicyHolder>({
      query: ({holderId, holderType}) => ({
        url: '/admin/cleanupPoliciesRest.html',
        method: 'POST',
        body: {
          holderId: stringifyId(holderId),
          holder: holderType,
          type: 'resetSettings',
        },
        withJsonError: true,
      }),
      invalidatesTags: ['CleanupPolicies'],
    }),
    updateCleanupPolicy: build.mutation<unknown, UpdatePolicyPayload>({
      query: policy => ({
        url: '/admin/cleanupPoliciesRest.html',
        method: 'POST',
        body: {...policy, type: 'updateSettings'},
        withJsonError: true,
      }),
      invalidatesTags: ['CleanupPolicies'],
    }),
    getDiskUsage: build.query<DiskUsage, ProjectId>({
      query: projectId => ({
        url: '/diskUsage.html',
        method: 'POST',
        body: `action=getData&projectId=${stringifyId(projectId)}`,
        headers: {
          Accept: 'text/xml',
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
      }),
      transformResponse: (data: string) => {
        const xml = new DOMParser().parseFromString(data, 'text/xml')
        const nodes = xml.querySelectorAll<DiskUsageNode>('project, buildType')
        const result = getEmptyDiskUsage()
        nodes.forEach(node => {
          const type = node.nodeName
          const id = node.getAttribute('projectId') || node.getAttribute('buildTypeId')
          const size = node.getAttribute('size')
          if (id != null && size != null) {
            result[type][id] = parseInt(size, 10)
          }
        })
        return result
      },
    }),
    getBuildLogMessages: build.query<GetBuildLogMessagesResponse, GetBuildLogMessagesArg>({
      query: getBuildLogMessagesQuery,
      transformResponse: (response: BuildLogMessagesResponse, _, {invalidate, options = {}}) => {
        let focusLine: number | null | undefined
        let logView: LogView

        if (
          options.stageKey != null &&
          response.stageTarget != null &&
          response.stageTarget.key === options.stageKey
        ) {
          const anchor: BuildLogMessagesResponseAnchor | null | undefined =
            response.stageTarget.anchors.find(item => item.type === 'BUILD_STAGE_START')

          if (anchor != null) {
            focusLine = anchor.position
          }
        } else if (invalidate === true && options.logAnchor === 0) {
          const firstVisible = response.messages.find(message => message.level > 0)

          if (firstVisible != null) {
            focusLine = firstVisible.id
          }
        }

        if (options.logAnchor === -1) {
          const last = response.messages[response.messages.length - 1]

          if (last != null) {
            focusLine = last.id
          }
        }

        if (response.view != null) {
          logView = response.view
        }
        if (response.focusIndex != null) {
          focusLine = response.focusIndex
        }

        return {
          data: response,
          focusLine,
          logView,
        }
      },
    }),
    getBuildLogTimeline: build.query<BuildLogTimeline, GetBuildLogTimelineArg>({
      query: ({endpoint, buildId, logView}) => ({
        url: `/${endpoint}`,
        params: {buildId, view: logView},
      }),
    }),
    getBuildSnippets: build.query<BuildSnippet[], BuildId>({
      query: buildId => ({
        url: '/app/placeId',
        params: {buildId},
      }),
    }),
    getChangeBuildTypeHierarchy: build.query<BuildTypeHierarchy, GetChangeBuildTypeHierarchyArg>({
      query: ({modId, personal}) => ({
        url: '/app/changeFilesByBuildTypeHierarchy',
        params: {modId, personal},
      }),
    }),
    generateAgentToken: build.mutation<string, void>({
      query: () => ({
        url: '/plugins/hosted-plugin/agentsAuth.html',
        params: {generate: 'token'},
        method: 'POST',
        responseHandler: 'text',
      }),
    }),
    generateAgentConfig: build.mutation<string, string | null | undefined>({
      query: agentName => ({
        url: '/plugins/hosted-plugin/agentsAuth.html',
        params: {generate: 'config', agentName},
        method: 'POST',
        responseHandler: 'text',
      }),
    }),
    generateId: build.mutation<
      string,
      {object: string; parentId?: string; name: string; currentId?: string}
    >({
      query: ({object, parentId, name, currentId}) => {
        const formData = new FormData()

        formData.append('object', object)
        formData.append('name', name)

        if (parentId != null) {
          formData.append('parentId', parentId)
        }

        if (currentId != null) {
          formData.append('currentId', currentId)
        }

        return {
          url: '/generateId.html',
          body: formData,
          method: 'POST',
          responseHandler: 'text',
        }
      },
    }),
    /* End of non REST-API controllers */

    getAllAgentsNormalized: build.query<NormalizedAgents, GetAllAgentsApiArg>({
      query: queryArg => ({
        url: `/app/rest/agents`,
        params: {
          locator: queryArg.locator,
          fields: queryArg.fields,
        },
      }),
      transformResponse: (data: Agents) => processAgents(data.agent ?? []),
    }),
    getAllAgentPreviewsNormalized: build.query<
      NormalizedAgents,
      GetAllAgentPreviewsNormalizedApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/agents`,
        headers: getEssentialHeader(queryArg.essential),
        params: {
          locator: queryArg.locator,
          fields: queryArg.fields,
        },
      }),
      transformResponse: (data: Agents) => normalizeAgentPreviews(data.agent ?? []),
      structuralSharing: false,
    }),
    getAgentNormalized: build.query<FetchSingleAgentDataPayload, GetAgentNormalizedApiArg>({
      query: queryArg => ({
        url: `/app/rest/agents/${queryArg.agentLocator}`,
        headers: getEssentialHeader(queryArg.essential),
        params: {fields: queryArg.fields},
      }),
      transformResponse: processAgent,
      providesTags: result => [
        {
          type: 'Agent',
          id: result?.agent.result,
        },
      ],
    }),
    deleteAgent: build.mutation<DeleteAgentApiResponse, DeleteAgentApiArg>({
      query: queryArg => ({
        url: `/app/rest/agents/${queryArg.agentLocator}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, queryArg) => [
        {
          type: 'Agent',
          id: Number(getIdFromLocator(queryArg.agentLocator)),
        },
      ],
    }),
    setAuthorizedInfo: build.mutation<SetAuthorizedInfoApiResponse, SetAuthorizedInfoApiArg>({
      query: queryArg => ({
        url: `/app/rest/agents/${queryArg.agentLocator}/authorizedInfo`,
        method: 'PUT',
        body: queryArg.authorizedInfo,
        params: {fields: queryArg.fields},
      }),
    }),
    setEnabledInfo: build.mutation<SetEnabledInfoApiResponse, SetEnabledInfoApiArg>({
      query: queryArg => ({
        url: `/app/rest/agents/${queryArg.agentLocator}/enabledInfo`,
        method: 'PUT',
        body: queryArg.enabledInfo,
        params: {fields: queryArg.fields},
      }),
    }),
    setAgentPool: build.mutation<SetAgentPoolApiResponse, SetAgentPoolApiArg>({
      query: queryArg => ({
        url: `/app/rest/agents/${queryArg.agentLocator}/pool`,
        method: 'PUT',
        body: queryArg.agentPool,
        params: {fields: queryArg.fields},
      }),
    }),
    getAllBuildTypesNormalized: build.query<NormalizedBuildTypes, GetAllBuildTypesApiArg>({
      query: queryArg => ({
        url: `/app/rest/buildTypes`,
        headers: {
          [SW_CACHING_HEADER_NAME]: CACHE_BUILD_TYPES,
        },
        params: {
          locator: queryArg.locator,
          fields: queryArg.fields,
        },
      }),
      transformResponse: (data: BuildTypes) => normalizeBuildTypes(data.buildType ?? []),
    }),
    getPathBuildTypeNormalized: build.query<NormalizedSingleBuildType, GetBuildTypeApiArg>({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}`,
        headers: {
          [SW_CACHING_HEADER_NAME]: CACHE_BUILD_TYPE,
        },
        params: {fields: queryArg.fields},
      }),
      transformResponse: processBuildType,
    }),
    getBuildTypeNormalized: build.query<NormalizedSingleBuildType, GetBuildTypeApiArg>({
      query: queryArg => ({
        url: `/app/rest/buildTypes/${queryArg.btLocator}`,
        headers: {
          [SW_CACHING_HEADER_NAME]: CACHE_BUILD_TYPE,
        },
        params: {fields: queryArg.fields},
      }),
      transformResponse: processBuildType,
    }),
    getAllBranchesOfBuildTypeMerged: build.query<
      NormalizedBranches,
      GetAllBranchesOfBuildTypeMergedApiArg
    >({
      queryFn: ({args, buildTypeId}, _, __, baseQuery) =>
        executeAll(
          args.map(
            queryArg =>
              baseQuery({
                url: `/app/rest/buildTypes/${queryArg.btLocator}/branches`,
                params: {
                  locator: queryArg.locator,
                  fields: queryArg.fields,
                },
              }) as MaybePromise<
                QueryReturnValue<Branches, FetchBaseQueryError, FetchBaseQueryMeta | undefined>
              >,
          ),
          data =>
            normalizeBranches(
              data.flatMap(
                ({branch}) =>
                  branch?.map(item => ({
                    ...item,
                    buildTypeId,
                  })) ?? [],
              ),
            ),
        ),
    }),
    getAllBuilds: build.query<GetAllBuildsApiResponse, GetAllBuildsApiArg>({
      query: queryArg => ({
        url: `/app/rest${queryArg.customEndpoint ?? '/builds'}`,
        params: {
          locator: queryArg.locator,
          fields: queryArg.fields,
        },
        headers: getEssentialHeader(queryArg.essential),
      }),
    }),
    getAllBuildsNormalized: build.query<
      GetAllBuildsNormalizedApiResponse,
      GetAllBuildsNormalizedApiArg
    >({
      queryFn: (
        {locator, fields, options, onProgress = noop, customEndpoint, essential},
        {forced},
      ) =>
        stream<Builds, Build>(
          `${base_uri}/app/rest${customEndpoint ?? '/builds'}?${objectToQuery({
            locator,
            fields,
          })}`,
          '!.build[*]',
          onProgress,
          {
            essential,
            headers: {
              [SW_CACHING_HEADER_NAME]: CACHE_ALL_BUILDS,
              ...(forced ? {[SW_FORCE_REVALIDATE_HEADER_NAME]: 'true'} : undefined),
            },
          },
        ).then(
          ({build: dataBuild = [], nextHref}) => ({
            data: {
              ...normalizeBuilds(dataBuild),
              hasMore: nextHref != null,
              loadedLessThanRequested: dataBuild.length < (options?.pageSize || 0),
            },
          }),
          error => ({error}),
        ),
      async onCacheEntryAdded(
        args,
        {dispatch, cacheDataLoaded, cacheEntryRemoved, updateCachedData},
      ) {
        await cacheDataLoaded
        const {customEndpoint, locator, fields} = args
        const unsubscribe = subscribeOnServiceWorkerMessage<Builds>({
          url: `${base_uri}/app/rest${customEndpoint ?? '/builds'}?${objectToQuery({
            locator,
            fields,
          })}`,
          handler: response => {
            if (!checkIfNonValidResponse(response)) {
              const {nextHref, build: buildData = []} = response.payload
              updateCachedData(() => {
                const result = {
                  ...normalizeBuilds(buildData ?? []),
                  hasMore: nextHref != null,
                  loadedLessThanRequested: buildData.length < (args.options?.pageSize || 0),
                }
                dispatch(serviceWorkerUpdateActions.getAllBuildsNormalized(result, args))
                return result
              })
            }
          },
        })
        await cacheEntryRemoved
        unsubscribe()
      },
    }),
    getAllBuildsDetailsNormalized: build.query<
      GetAllBuildsNormalizedApiResponse,
      GetAllBuildsNormalizedApiArg
    >({
      queryFn: ({locator, fields, options, onProgress = noop, customEndpoint}, {forced}) =>
        stream<Builds, Build>(
          `${window.base_uri}/app/rest${customEndpoint ?? '/builds'}?${objectToQuery({
            locator,
            fields,
          })}`,
          '!.build[*]',
          onProgress,
          {
            headers: {
              [SW_CACHING_HEADER_NAME]: CACHE_ALL_BUILDS_DETAILS,
              ...(forced ? {[SW_FORCE_REVALIDATE_HEADER_NAME]: 'true'} : undefined),
            },
          },
        ).then(
          ({build: dataBuild = [], nextHref}) => ({
            data: {
              ...normalizeBuilds(dataBuild),
              hasMore: nextHref != null,
              loadedLessThanRequested: dataBuild.length < (options?.pageSize || 0),
            },
          }),
          error => ({error}),
        ),
      async onCacheEntryAdded(
        args,
        {dispatch, updateCachedData, cacheDataLoaded, cacheEntryRemoved},
      ) {
        const {customEndpoint, locator, fields} = args
        await cacheDataLoaded
        const unsubscribe = subscribeOnServiceWorkerMessage<Builds>({
          url: `${window.base_uri}/app/rest${customEndpoint ?? '/builds'}?${objectToQuery({
            locator,
            fields,
          })}`,
          handler: response => {
            if (!checkIfNonValidResponse(response)) {
              const {nextHref, build: buildData = []} = response.payload
              const result = {
                ...normalizeBuilds(buildData ?? []),
                hasMore: nextHref != null,
                loadedLessThanRequested: buildData.length < (args.options?.pageSize || 0),
              }
              dispatch(serviceWorkerUpdateActions.getAllBuildsNormalized(result, args))
              updateCachedData(() => result)
            }
          },
        })
        await cacheEntryRemoved
        unsubscribe()
      },
    }),
    setMultipleBuildComments: build.mutation<NormalizedBuilds, SetMultipleBuildCommentsApiArg>({
      query: queryArg => ({
        url: `/app/rest/builds/multiple/${queryArg.buildLocator}/comment`,
        method: 'PUT',
        body: queryArg.body,
        params: {fields: queryArg.fields},
      }),
      transformResponse: processMultipleBuildsOperationResult,
    }),
    pinMultipleBuilds: build.mutation<NormalizedBuilds, PinMultipleBuildsApiArg>({
      query: queryArg => ({
        url: `/app/rest/builds/multiple/${queryArg.buildLocator}/pinInfo`,
        method: 'PUT',
        body: queryArg.pinInfo,
        params: {fields: queryArg.fields},
      }),
      transformResponse: processMultipleBuildsOperationResult,
    }),
    addTagsToMultipleBuilds: build.mutation<NormalizedBuilds, AddTagsToMultipleBuildsApiArg>({
      query: queryArg => ({
        url: `/app/rest/builds/multiple/${queryArg.buildLocator}/tags`,
        method: 'POST',
        body: queryArg.tags,
        params: {fields: queryArg.fields},
      }),
      transformResponse: processMultipleBuildsOperationResult,
    }),
    getBuild: build.query<GetBuildApiResponse, GetBuildApiArg>({
      query: queryArg => ({
        url: `/app/rest/builds/${queryArg.buildLocator}`,
        headers: getEssentialHeader(queryArg.essential),
        params: {fields: queryArg.fields},
      }),
    }),
    getBuildNormalized: build.query<NormalizedSingleBuild, GetBuildApiArg>({
      query: queryArg => ({
        url: `/app/rest/builds/${queryArg.buildLocator}`,
        params: {fields: queryArg.fields},
      }),
      transformResponse: normalizeBuild,
    }),
    getBuildNormalizedAsList: build.query<NormalizedBuilds, GetBuildNormalizedAsListApiArg>({
      query: queryArg => ({
        url: `/app/rest/builds/${queryArg.buildLocator}`,
        headers: getEssentialHeader(queryArg.essential),
        params: {fields: queryArg.fields},
      }),
      transformResponse: (data: Build) => normalizeBuilds([data]),
      providesTags: data => [
        {
          type: 'Build',
          id: data?.result[0],
        },
      ],
    }),
    setBuildTags: build.mutation<SetBuildTagsApiResponse, SetBuildTagsApiArg>({
      query: queryArg => ({
        url: `/app/rest/builds/${queryArg.buildLocator}/tags`,
        method: 'PUT',
        body: queryArg.tags,
        params: {
          locator: queryArg.locator,
          fields: queryArg.fields,
        },
      }),
    }),
    getChangeLog: build.query<GetChangeLogApiTransformedResponse, GetChangeLogApiArg>({
      query: queryArg => ({
        url: `/app/rest/changeLog/${encodeURIComponent(queryArg.locator)}`,
        headers: {
          [SW_CACHING_HEADER_NAME]: CACHE_CHANGELOG,
        },
        params: {
          fields: queryArg.fields,
        },
      }),
      transformResponse: processChangeLog,
    }),
    getAllChanges: build.query<GetAllChangesApiTransformedResponse, GetAllChangesApiArg>({
      query: queryArg => ({
        url: `/app/rest/changes`,
        headers: getEssentialHeader(queryArg.essential),
        params: {
          locator: queryArg.locator,
          fields: queryArg.fields,
        },
      }),
      transformResponse: ({change, ...rest}: GetAllChangesApiResponse) => ({
        changes: change && normalizeChanges(change),
        ...rest,
      }),
    }),
    getModificationsOfChange: build.query<Normalized<ChangeId>, GetChangeApiArg>({
      query: queryArg => ({
        url: `/app/rest/changes/${queryArg.changeLocator}`,
        params: {fields: queryArg.fields},
      }),
      transformResponse: normalizeModificationsOfChange,
    }),
    getChangeFiles: build.query<GetChangeFilesApiResponse, GetChangeFilesApiArg>({
      query: ({changeLocator, fields}) => ({
        url: `/app/rest/changes/${changeLocator}/files`,
        params: {fields},
      }),
    }),
    getAllInvestigations: build.query<GetAllInvestigationsApiResponse, GetAllInvestigationsApiArg>({
      query: queryArg => ({
        url: `/app/rest/investigations`,
        params: {
          locator: queryArg.locator,
          fields: queryArg.fields,
        },
      }),
    }),
    // relay migration cursor
    muteMultipleTests: build.mutation<MuteMultipleTestsApiResponse, MuteMultipleTestsApiArg>({
      query: queryArg => ({
        url: `/app/rest/mutes/multiple`,
        method: 'POST',
        body: queryArg.mutes,
        params: {fields: queryArg.fields},
      }),
    }),
    unmuteTest: build.mutation<UnmuteTestApiResponse, UnmuteTestApiArg>({
      query: queryArg => ({
        url: `/app/rest/mutes/${queryArg.muteLocator}`,
        method: 'DELETE',
        body: queryArg.body,
      }),
    }),
    unmuteMultiple: build.mutation<UnmuteTestApiResponse, MuteMultipleTestsApiArg>({
      query: queryArg => ({
        url: `/app/rest/mutes/multiple`,
        method: 'DELETE',
        body: queryArg.mutes,
        params: {fields: queryArg.fields},
      }),
    }),
    getBuildProblemOccurrence: build.query<
      GetBuildProblemOccurrenceApiResponse,
      GetBuildProblemOccurrenceApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/problemOccurrences/${queryArg.problemLocator}`,
        params: {fields: queryArg.fields},
      }),
    }),
    getAllProjectsNormalized: build.query<NormalizedProjects, GetAllProjectsNormalizedApiArg>({
      queryFn: async (queryArg, {forced}, _, baseQuery) => {
        const result = await (baseQuery({
          url: `/app/rest/projects`,
          params: {
            locator: queryArg.locator,
            fields: queryArg.fields,
          },
          headers: {
            ...(queryArg.cache ? {[SW_CACHING_HEADER_NAME]: CACHE_PROJECTS_TREE} : undefined),
            ...(forced ? {[SW_FORCE_REVALIDATE_HEADER_NAME]: 'true'} : undefined),
          },
        }) as MaybePromise<
          QueryReturnValue<Projects, FetchBaseQueryError, FetchBaseQueryMeta | undefined>
        >)

        if (result.data != null) {
          const projects = result.data.project ?? []
          return {
            ...result,
            data: queryArg.isOverview
              ? normalizeOverview(projects)
              : normalizeProjectsData(projects, queryArg),
          }
        } else {
          return result
        }
      },
      async onCacheEntryAdded(
        arg,
        {dispatch, updateCachedData, cacheDataLoaded, cacheEntryRemoved},
      ) {
        const {locator, fields, cache, isOverview} = arg
        if (!cache) {
          return
        }
        await cacheDataLoaded
        const unsubscribe = subscribeOnServiceWorkerMessage<Projects>({
          url: `${base_uri}/app/rest/projects?${objectToQuery({locator, fields})}`,
          handler: response => {
            if (!checkIfNonValidResponse(response)) {
              const projects = response.payload.project ?? []
              updateCachedData(() => {
                const result = isOverview
                  ? normalizeOverview(projects)
                  : normalizeProjectsData(projects, arg)

                dispatch(serviceWorkerUpdateActions.getAllProjectsNormalized(result, arg))
                return result
              })
            }
          },
        })
        await cacheEntryRemoved
        unsubscribe()
      },
      providesTags: (_, __, {isOverview}) => (isOverview ? ['Overview'] : []),
    }),
    getFederationProjectsNormalized: build.query<
      NormalizedProjects,
      GetFederationProjectsNormalizedApiArg
    >({
      query: queryArg => ({
        url: `${queryArg.federationServerUrl}/app/rest/projects`,
        params: {
          locator: queryArg.locator,
          fields: queryArg.fields,
        },
        withAuth: false,
      }),
      transformResponse: (data: Projects, _, arg) => normalizeProjectsData(data.project ?? [], arg),
    }),
    addProject: build.mutation<AddProjectApiResponse, AddProjectApiArg>({
      query: queryArg => ({
        url: `/app/rest/projects`,
        method: 'POST',
        body: queryArg.newProjectDescription,
      }),
    }),
    getProjectNormalized: build.query<NormalizedSingleProject, GetProjectNormalizedApiArg>({
      query: queryArg => ({
        url: `/app/rest/projects/${queryArg.projectLocator}`,
        headers: {
          [SW_CACHING_HEADER_NAME]: CACHE_PROJECTS,
          ...getEssentialHeader(queryArg.essential),
        },
        params: {fields: queryArg.fields},
      }),
      transformResponse: (data: Project, _, arg) => processProject(data, arg),
    }),
    getAllBranches: build.query<GetAllBranchesApiResponse, GetAllBranchesApiArg>({
      query: queryArg => ({
        url: `/app/rest/projects/${queryArg.projectLocator}/branches`,
        params: {
          locator: queryArg.locator,
          fields: queryArg.fields,
        },
      }),
    }),
    getFeature: build.query<GetFeatureApiResponse, GetFeatureApiArg>({
      query: queryArg => ({
        url: `/app/rest/projects/${queryArg.projectLocator}/projectFeatures/${queryArg.featureLocator}`,
        params: {fields: queryArg.fields},
      }),
    }),
    addSecureToken: build.mutation<AddSecureTokenApiResponse, AddSecureTokenApiArg>({
      query: queryArg => ({
        url: `/app/rest/projects/${queryArg.projectLocator}/secure/tokens`,
        headers: {
          accept: 'text/plain',
        },
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getProjectsDefaultValueSets: build.query<
      GetProjectsDefaultValueResponse,
      GetProjectsDefaultValueSetsArg
    >({
      query: projectLocator => ({
        url: `/app/rest/projects/${projectLocator}/defaultValueSets`,
      }),
    }),
    getServerInfo: build.query<GetServerInfoApiResponse, GetServerInfoApiArg>({
      query: queryArg => ({
        url: `/app/rest/server`,
        params: {fields: queryArg.fields},
      }),
    }),
    getLicensingData: build.query<GetLicensingDataApiResponse, GetLicensingDataApiArg>({
      query: queryArg => ({
        url: `/app/rest/server/licensingData`,
        params: {fields: queryArg.fields},
        headers: getEssentialHeader(true),
      }),
    }),
    getAllMetrics: build.query<GetAllMetricsApiResponse, GetAllMetricsApiArg>({
      query: queryArg => ({
        url: `/app/rest/server/metrics`,
        params: {fields: queryArg.fields},
      }),
    }),
    nodes: build.query<NodesApiResponse, NodesApiArg>({
      query: queryArg => ({
        url: `/app/rest/server/nodes`,
        params: {fields: queryArg.fields},
      }),
    }),
    getNode: build.query<GetNodeApiResponse, GetNodeApiArg>({
      query: queryArg => ({
        url: `/app/rest/server/nodes/${queryArg.nodeId}`,
        params: {fields: queryArg.fields},
      }),
    }),
    getAllTestProblems: build.query<GetAllTestProblemsApiResponse, GetAllTestProblemsApiArg>({
      query: queryArg => ({
        url: `/app/rest/pages/problems/testFailures`,
        params: {
          locator: queryArg.locator,
          fields: queryArg.fields,
        },
      }),
    }),
    getAllTestProblemsNormalized: build.query<
      GetAllTestProblemsNormalizedApiResponse,
      GetAllTestProblemsNormalizedApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/pages/problems/testFailures`,
        params: {
          locator: queryArg.locator,
          fields: queryArg.fields,
        },
      }),
      transformResponse: ({nextHref, entry = []}: TestProblems, _, {pageSize = 0}) => ({
        data: processTestProblems(entry),
        hasMore: nextHref != null,
        loadedLessThanRequested: entry.length < pageSize,
      }),
    }),
    getTestProblemTestOccurrencesNormalized: build.query<
      GetAllTestProblemsTestOccurrencesApiResponse,
      GetAllTestProblemsTestOccurrencesApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/testOccurrences`,
        params: {
          locator: queryArg.locator,
          fields: queryArg.fields,
        },
      }),
      transformResponse: ({nextHref, testOccurrence = []}: TestOccurrences, _, {pageSize = 0}) => ({
        data: processTestProblemTestOccurrences(testOccurrence),
        hasMore: nextHref != null,
        loadedLessThanRequested: testOccurrence.length < pageSize,
      }),
    }),
    getBuildProblemsTree: build.query<GetBuildProblemsTreeApiResponse, GetBuildProblemTreeApiArg>({
      query: ({locator, subTreeRootId}) => ({
        url: `/app/rest/pages/problems/buildProblems/tree`,
        params: {
          locator,
          subTreeRootId,
          fields: getBuildProblemsTreeFields(),
        },
      }),
      transformResponse: processBuildProblemsTree,
    }),
    getAllTestOccurrencesNormalized: build.query<
      GetAllTestOccurrencesNormalizedApiResponse,
      GetAllTestOccurrencesNormalizedApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/testOccurrences`,
        params: {
          locator: queryArg.locator,
          fields: queryArg.fields,
        },
      }),
      transformResponse: (
        {nextHref, testOccurrence = []}: TestOccurrences,
        _,
        {pageSize = 0, isInvocations, options},
      ) => ({
        data: isInvocations
          ? processTestOccurrencesInvocations({data: testOccurrence, options})
          : getTestOccurrencesEntities(testOccurrence, options),
        hasMore: nextHref != null,
        loadedLessThanRequested: testOccurrence.length < pageSize,
      }),
    }),
    getTestOccurrence: build.query<GetTestOccurrenceApiResponse, GetTestOccurrenceApiArg>({
      query: queryArg => ({
        url: `/app/rest/testOccurrences/${queryArg.testLocator}`,
        params: {fields: queryArg.fields},
      }),
    }),
    getTestOccurrenceNormalized: build.query<
      NormalizedTestOccurrences,
      GetTestOccurrenceNormalizedApiArg
    >({
      query: queryArg => ({
        url: `/app/rest/testOccurrences/${queryArg.testLocator}`,
        params: {fields: queryArg.fields},
      }),
      transformResponse: (data: TestOccurrence, _, {options}) =>
        getTestOccurrencesEntities([data], options),
    }),
    getTestOccurrenceTree: build.query<
      GetTestOccurrenceTreeApiResponse,
      GetTestOccurrenceTreeApiArg
    >({
      query: ({endpoint, locator, options, subTreeRootId}) => ({
        url: `/app/rest/${endpoint}`,
        headers: {[SW_CACHING_HEADER_NAME]: CACHE_TEST_SCOPES},
        params: {
          locator,
          subTreeRootId,
          fields: getTestOccurrencesTreeFields(options),
        },
      }),
      transformResponse: (data: TestOccurrencesTree, _, {options}) =>
        processTestOccurrenceTree(data, options),
    }),
    getProblemOccurrenceTree: build.query<
      GetProblemOccurrenceTreeApiResponse,
      GetProblemOccurrenceTreeApiArg
    >({
      query: ({endpoint, locator, subTreeRootId}) => ({
        url: `/app/rest/${endpoint}`,
        params: {
          locator,
          subTreeRootId,
          fields: problemOccurrencesTreeFields,
        },
      }),
      transformResponse: processProblemOccurrencesTree,
    }),
    sendPipelinesFeedback: build.mutation<
      {cause: Array<{name: string; cause: string}>; success: boolean},
      {
        email: string
        rating: number
        name: string
        feedback: string
        formid: string
        privacyConsentType: Array<string>
        privacyConsent: boolean
      }
    >({
      queryFn: async ({
        email,
        rating,
        name,
        feedback,
        formid,
        privacyConsent,
        privacyConsentType,
      }) => {
        const response = await fetch('https://forms-service.jetbrains.com/feedback', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email,
            name,
            feedback,
            formid,
            rating,
            privacyConsentType,
            privacyConsent,
          }),
        })
        return response.ok
          ? await response.json()
          : {
              error: 'An error occurred while sending feedback. Please, try again later.',
            }
      },
    }),
    getVsStatus: build.query<VsStatus, GetVsConfigApiArg>({
      query: queryArg => ({
        url: `/app/rest/projects/${queryArg.projectLocator}/versionedSettings/status`,
        params: {
          fields: queryArg.fields,
        },
      }),
    }),
  }),
  overrideExisting: false,
})
export {injectedRtkApi as restApi}

type ParameterAutocompletionResponseItem = {
  value: string
  label: string
  meta: string
  selectable: boolean
}

type ParameterAutocompletionResponse = ParameterAutocompletionResponseItem[]
type GetParameterAutocompletionArg = {settingsId: string; term?: string}

type GetBuildTimelineArg = {
  buildId: number
}

type GetDockerImagesResponse = DockerImages
type GetDockerImagesArg = {
  q: string
}

type GetDockerTagsResponse = DockerTags
type GetDockerTagsArg = {
  image: string
  name?: string
}

type TestNpmConnectionArg = {
  url: string
  scope: string
  token: string
}

type TestDockerConnectionArg = {
  userName: string
  url: string
  userPass: string
}

type GetStatusesArg = {
  endpoint: string
  requestIds: ReadonlyArray<StatusRequest>
}

type UpdateExpandStateArg = {
  endpoint: string
  data: ReadonlyArray<ExpandState>
}

type GetArtifactExtensionArg = {
  endpoint: string
  buildId: BuildId
}

type GetFlakyTestsArg = {
  buildId: BuildId
  endpoint: string
  tests: RequestFlakyTests
}

type SetProjectOrderArg = ProjectInternalId[]

export type SetBuildTypeOrderArg = {
  projectId: ProjectId
  order: BuildTypeInternalId[]
}

type GetHtmlArg = {
  path: string
  method?: string | null
}

type ToggleFavoriteProjectArg = {
  projectId: ProjectId
  projectInternalId: ProjectInternalId
  on: boolean
  skipSubprojects?: boolean
}

type ToggleFavoriteBuildTypeArg = {
  projectId: ProjectId
  buildTypeId: BuildTypeId
  buildTypeInternalId: BuildTypeInternalId
  on: boolean
}

type SetFavoriteBuildTypesArg = {
  projectId: ProjectId
  buildTypeIds: BuildTypeId[]
  buildTypeInternalIds: BuildTypeInternalId[]
}

export type GetAvailableRunnersResponse = Record<string, Array<RunnerType>>
type GetAvailableRunnersArg = {projectId: ProjectId}
export type GetTabsArg = {
  key: string
  cacheTabs?: boolean
  essential?: boolean
}

export type RunBuildResponse = {
  queuedBuildId: BuildId | null | undefined
  showDialog: boolean
}
type RunBuildArg = {
  buildTypeId: BuildTypeId
  branch: Branch | null | undefined
  options: TriggerBuildOptions
  isCustom?: boolean
}

type GetCompareBuildsListArg = {
  endpoint: string
  sourceId: BuildId
  compareWithId: BuildId
}

type GetCompareBuildsInfoArg = {
  endpoint: string
  compareType: string
  sourceId: BuildId
  compareWithId: BuildId
  mode: 'changed' | 'all'
  filterText?: string | null | undefined
}

type GetBuildsForCompareArg = {
  endpoint: string
  sourceId: BuildId
}

export type GetCleanupRulesArg = {
  holderNode: CleanupHolderNode
  options?: FetchRulesOptons | null | undefined
}

type DeleteCleanupRuleArg = {
  holderNode: CleanupHolderNode
  ruleId: string
}

type CreateCleanupRuleArg = {
  holderNode: CleanupHolderNode
  rule: Rule
  ruleId?: RuleId
}

type UpdateCleanupRuleArg = {
  holderNode: CleanupHolderNode
  rule: Rule
}

type GetCleanupPoliciesArg = {
  projectInternalId: ProjectInternalId
  includeSubprojects?: boolean
}

type UpdateCleanupPolicyDisableStateArg = {
  holder: PolicyHolder
  cleanupPoliciesDisabled: boolean
}

type GetBuildLogMessagesResponse = {
  data: BuildLogMessagesResponse
  focusLine?: number | null
  logView?: LogView
}
type GetBuildLogMessagesArg = FetchMessagesParams & {
  endpoint: string
}

export type GetBuildLogTimelineArg = {
  endpoint: string
  buildId: BuildId
  logView?: LogView
}

type GetChangeBuildTypeHierarchyArg = {modId: ChangeId; personal: boolean}

export type GetAllAgentsApiArg = {
  locator?: string
  fields?: string
}
export type GetAllAgentPreviewsNormalizedApiArg = GetAllAgentsApiArg & {essential?: boolean}
export type GetAgentNormalizedApiArg = {
  agentLocator: string
  fields?: string
  essential?: boolean
}
type DeleteAgentApiResponse = unknown
type DeleteAgentApiArg = {
  agentLocator: string
}
type SetAuthorizedInfoApiResponse = /** status 200 successful operation */ AuthorizedInfo
type SetAuthorizedInfoApiArg = {
  agentLocator: string
  fields?: string
  authorizedInfo: AuthorizedInfo
}
type SetEnabledInfoApiResponse = /** status 200 successful operation */ EnabledInfo
type SetEnabledInfoApiArg = {
  agentLocator: string
  fields?: string
  enabledInfo: EnabledInfo
}
type SetAgentPoolApiResponse = /** status 200 successful operation */ AgentPool
type SetAgentPoolApiArg = {
  agentLocator: string
  fields?: string
  agentPool: AgentPool
}
export type GetAllBuildTypesApiArg = {
  locator?: string
  fields?: string
}
export type GetBuildTypeApiArg = {
  btLocator: string
  fields?: string
  essential?: boolean
}
export type GetAllBranchesOfBuildTypeApiArg = {
  btLocator: string
  locator?: string
  fields?: string
}
export type GetAllBranchesOfBuildTypeMergedApiArg = {
  buildTypeId?: string
  args: GetAllBranchesOfBuildTypeApiArg[]
}
type GetAllBuildsApiResponse = /** status 200 successful operation */ Builds
export type GetAllBuildsApiArg = RequestOptionsParams & {
  locator?: string
  fields?: string
  options?: {
    pageSize?: number
  }
  count?: number
  inBackground?: boolean
}
export type GetAllBuildsNormalizedApiResponse = NormalizedBuilds & {
  hasMore?: boolean
  loadedLessThanRequested?: boolean
}
export type GetAllBuildsNormalizedApiArg = GetAllBuildsApiArg & {
  onProgress?: (build: Build) => void
  detailsLocatorToStore?: string
}
export type SetMultipleBuildCommentsApiArg = {
  buildLocator: string
  fields?: string
  body: string
}
export type PinMultipleBuildsApiArg = {
  buildLocator: string
  fields?: string
  pinInfo: PinInfo
}
type GetVsConfigApiArg = {
  projectLocator: string
  fields?: string
}
export enum VsStatusType {
  INFO = 'info',
  WARN = 'warn',
}
type VsStatus = {
  message: string
  type: VsStatusType
  timestamp: string
  dslOutdated: boolean
  missingContextParameters: string[]
  versionedSettingsError: [
    {
      message: string
      type: string
      file: string
      stackTraceLines: string[]
    },
  ]
}
export type AddTagsToMultipleBuildsApiArg = {
  buildLocator: string
  buildTypeId?: string
  fields?: string
  tags: Tags
}
type GetBuildApiResponse = /** status 200 successful operation */ Build
export type GetBuildApiArg = {
  buildLocator: string
  fields?: string
} & RequestOptionsParams
export type GetBuildNormalizedAsListApiArg = GetBuildApiArg & {
  locators: string[]
}
type SetBuildTagsApiResponse = /** status 200 successful operation */ Tags
export type SetBuildTagsApiArg = {
  buildLocator: string
  buildTypeId?: string
  locator?: string
  fields?: string
  tags: Tags
  prevTags: Tags
}
export type GetChangeLogApiResponse = /** status 200 successful operation */ {
  nextHref?: string
  graph: ChangesGraph
  row: ReadonlyArray<{
    change?: Change
    build?: Build
  }>
}
type GetChangeLogApiTransformedResponse = {
  nextHref?: string

  rows: ReadonlyArray<ChangeLogRow>
  graph: ChangesGraph
  changes: NormalizedChanges | null
  builds: NormalizedBuilds | null
}
export type GetChangeLogApiArg = {
  locator: string
  fields?: string
  withUpdatePager?: boolean
}
type GetAllChangesApiResponse = /** status 200 successful operation */ Changes
type GetAllChangesApiTransformedResponse = Omit<Changes, 'change'> & {
  changes: NormalizedChanges | undefined
}
export type GetAllChangesApiArg = {
  locator?: string
  fields?: string
  withUpdatePager?: boolean
  essential?: boolean
}
export type GetChangeApiArg = {
  changeLocator: string
  fields?: string
}
type GetChangeFilesApiResponse = /** status 200 successful operation */ FileChanges
export type GetChangeFilesApiArg = {
  changeId: ChangeId
  changeLocator: string
  fields?: string
  buildTypeId?: BuildTypeId | null
}
type GetAllInvestigationsApiResponse = /** status 200 successful operation */ Investigations
export type GetAllInvestigationsApiArg = {
  locator?: string
  fields?: string
}
type MuteMultipleTestsApiResponse = /** status 200 successful operation */ Mutes
type MuteMultipleTestsApiArg = {
  fields?: string
  mutes: Mutes
}
type UnmuteTestApiResponse = unknown
type UnmuteTestApiArg = {
  muteLocator: string
  body: string
}
type GetBuildProblemOccurrenceApiResponse = /** status 200 successful operation */ ProblemOccurrence
type GetBuildProblemOccurrenceApiArg = {
  problemLocator: string
  fields?: string
}
type GetAllProjectsApiArg = {
  locator?: string
  fields?: string
}
export type GetAllProjectsNormalizedApiArg = GetAllProjectsApiArg &
  RequestOptionsParams & {
    projectId?: ProjectId
    isOverview?: boolean
  }
export type GetFederationProjectsNormalizedApiArg = GetAllProjectsApiArg &
  RequestOptionsParams & {
    federationServerUrl: string
  }
type AddProjectApiResponse = /** status 200 successful operation */ Project
type AddProjectApiArg = {
  newProjectDescription: NewProjectDescription
}
export type GetProjectNormalizedApiArg = RequestOptionsParams & {
  projectLocator: string
  fields?: string
  essential?: boolean
}
type GetAllBranchesApiResponse = /** status 200 successful operation */ Branches
type GetAllBranchesApiArg = {
  projectLocator: string
  locator?: string
  fields?: string
}
type GetFeatureApiResponse = /** status 200 successful operation */ {
  properties?: Properties
}
type GetFeatureApiArg = {
  featureLocator: string
  fields?: string
  projectLocator: string
}
type AddSecureTokenApiResponse = unknown
type AddSecureTokenApiArg = {
  projectLocator: string
  body: string
}
type GetServerInfoApiResponse = /** status 200 successful operation */ Server
type GetServerInfoApiArg = {
  fields?: string
}
type GetLicensingDataApiResponse = /** status 200 successful operation */ LicensingData
type GetLicensingDataApiArg = {
  fields?: string
}

type GetProjectsDefaultValueSetsArg = string
type GetProjectsDefaultValue = {
  displayName?: string
  name?: string | null
  keyword?: string[]
  description?: string
  value?: MatrixParameterValue[]
}

type GetProjectsDefaultValueResponse = {
  valueSet: GetProjectsDefaultValue[]
}

type GetAllMetricsApiResponse = /** status 200 successful operation */ Metrics
type GetAllMetricsApiArg = {
  fields?: string
}
type NodesApiResponse = /** status 200 successful operation */ Nodes
type NodesApiArg = {
  fields?: string
}
type GetNodeApiResponse = /** status 200 successful operation */ Node
type GetNodeApiArg = {
  nodeId: string
  fields?: string
}
type GetAllTestProblemsApiResponse = /** status 200 successful operation */ TestProblems
type GetAllTestProblemsApiArg = {
  locator?: string
  fields?: string
  pageSize?: number
}
type GetAllTestProblemsNormalizedApiResponse = {
  data: NormalizedTestProblems
  hasMore?: boolean
  loadedLessThanRequested?: boolean
}
export type GetAllTestProblemsNormalizedApiArg = GetAllTestProblemsApiArg
type GetAllTestProblemsTestOccurrencesApiResponse = {
  data: NormalizedTestOccurrences
  hasMore?: boolean
  loadedLessThanRequested?: boolean
}
type GetAllTestProblemsTestOccurrencesApiArg = {
  locator?: string
  fields?: string
  pageSize?: number
}
export type GetBuildProblemTreeApiArg = {
  locator?: string
  fields?: string
  subTreeRootId?: string | null
  treeLocator: string
  subTreeKey?: string | null
  depth?: number
}
type GetBuildProblemsTreeApiResponse = {
  tree: BuildProblemsTree
  entities: Partial<Entities>
}
type GetAllTestOccurrencesNormalizedApiResponse = {
  data: NormalizedTestOccurrences
  hasMore?: boolean
  loadedLessThanRequested?: boolean
}
export type GetAllTestOccurrencesNormalizedApiArg = {
  locator?: string
  fields?: string
  pageSize?: number
  options?: RequestTestOccurrenceOptionsParams
  isInvocations?: boolean
}
type GetTestOccurrenceApiResponse = /** status 200 successful operation */ TestOccurrence
export type GetTestOccurrenceApiArg = {
  testLocator: string
  fields?: string
}
export type GetTestOccurrenceNormalizedApiArg = GetTestOccurrenceApiArg & {
  options?: RequestTestOccurrenceOptionsParams
}

export type GetVersionApiResponse = unknown

type GetTestOccurrenceTreeApiResponse = {
  tree: TestOccurrencesTree
  entities: Partial<Entities>
}
export type GetTestOccurrenceTreeApiArg = {
  endpoint: string
  locator?: string | null
  options?: RequestTestOccurrenceOptionsParams
  subTreeRootId?: string | null
  treeLocator: string
  subTreeKey?: string | null
  depth?: number
}

type GetProblemOccurrenceTreeApiResponse = {
  tree: ProblemOccurrencesTree
  entities: Partial<Entities>
}
export type GetProblemOccurrenceTreeApiArg = {
  endpoint: string
  locator?: string | null
  subTreeRootId?: string | null
  treeLocator: string
  subTreeKey?: string | null
  depth?: number
}

type Link = {
  type?: string | null
  url?: string | null
  relativeUrl?: string | null
}
export type Links = {
  count?: number | null
  link?: readonly Link[] | null
}
type StateField = {
  value?: boolean
  inherited?: boolean
}
type BuildTypes = {
  count?: number
  href?: string
  nextHref?: string
  prevHref?: string
  buildType?: BuildType[]
}
type Type = {
  rawValue?: string | null
}
export type Property = {
  name?: string | null
  value?: string | null
  inherited?: boolean | null
  type?: Type | null
}
export type Properties = {
  count?: number
  href?: string
  property?: readonly Property[] | null
}
type VcsCheckStatus = {
  status?: string
  requestorType?: string
  timestamp?: string
}
type VcsStatus = {
  current?: VcsCheckStatus
  previous?: VcsCheckStatus
}
type Role = {
  roleId?: string
  scope?: string
  href?: string
}
type Roles = {
  role?: Role[]
}
type Users = {
  count?: number
  user?: User[]
}
type Group = {
  key?: string
  name?: string
  href?: string
  description?: string
  'parent-groups'?: Groups
  'child-groups'?: Groups
  users?: Users
  roles?: Roles
  properties?: Properties
}
type Groups = {
  count?: number
  group?: Group[]
}
type UserAvatars = {
  urlToSize20?: string | null
  urlToSize28?: string | null
  urlToSize32?: string | null
  urlToSize40?: string | null
  urlToSize56?: string | null
  urlToSize64?: string | null
  urlToSize80?: string | null
}
export type User = {
  username?: string | null
  name?: string | null
  id?: number | null
  email?: string
  lastLogin?: string
  password?: string
  hasPassword?: boolean
  realm?: string
  href?: string
  properties?: Properties
  roles?: Roles
  groups?: Groups
  locator?: string
  avatars?: UserAvatars | null
  enabled2FA?: boolean
}
type Comment = {
  timestamp?: string | null
  text?: string
  user?: User
}
type Tag = {
  name?: string
  owner?: User
  private?: boolean
}
export type Tags = {
  count?: number
  tag?: Tag[]
}
export type EnabledInfo = {
  status?: boolean
  comment?: Comment
  statusSwitchTime?: string
}
type AuthorizedInfo = {
  status?: boolean
  comment?: Comment
}
type CloudImages = {
  count?: number | null
  nextHref?: string
  prevHref?: string
  href?: string
  cloudImage?: CloudImage[]
}
type CloudProfile = {
  id?: string
  name?: string
  cloudProviderId?: string
  href?: string
  project?: Project
  images?: CloudImages
}
type CloudInstances = {
  count?: number
  nextHref?: string
  prevHref?: string
  href?: string
  cloudInstance?: CloudInstance[]
}
export type CloudImage = {
  id?: string
  name?: string
  href?: string
  profile?: CloudProfile
  instances?: CloudInstances
  agentType?: {
    configurationParameters?: Properties
    environmentParameters?: Properties
    systemParameters?: Properties
  }
  errorMessage?: string
  agentTypeId?: number
  agentPoolId?: number
  operatingSystemName?: string
  locator?: string
}
type CloudInstance = {
  id?: string
  name?: string
  state?: string
  startDate?: string
  networkAddress?: string
  href?: string
  image?: CloudImage
  agent?: Agent
  errorMessage?: string
}
type OSType =
  | 'Windows'
  | 'macOS'
  | 'Linux'
  | 'Solaris'
  | 'FreeBSD'
  | 'Unix'
  | 'Unknown'
  | '%future added value'

type Environment = {
  osType?: OSType
  osName?: string
}
type CompatibilityPolicy = {
  policy?: string
  buildTypes?: BuildTypes
}
type Requirements = {
  description?: string
}
type Compatibility = {
  compatible?: boolean
  agent?: Agent
  buildType?: BuildType
  unmetRequirements?: Requirements
}
type Compatibilities = {
  count?: number
  compatibility?: Compatibility[]
}
export type Agent = {
  id?: number
  name?: string
  typeId?: number
  connected?: boolean
  connectedSince?: string
  enabled?: boolean
  authorized?: boolean
  uptodate?: boolean
  outdated?: boolean
  pluginsOutdated?: boolean
  javaOutdated?: boolean
  ip?: string
  protocol?: 'unidirectional' | 'bidirectional'
  version?: string
  currentAgentVersion?: string
  lastActivityTime?: string
  idleSinceTime?: string
  disconnectionComment?: string
  registrationTimestamp?: string
  host?: string
  cpuRank?: number
  port?: number
  href?: string
  webUrl?: string
  build?: Build
  links?: Links | null
  enabledInfo?: EnabledInfo
  authorizedInfo?: AuthorizedInfo
  properties?: Properties
  cloudInstance?: CloudInstance
  cloudImage?: CloudImage
  environment?: Environment
  pool?: AgentPool
  compatibilityPolicy?: CompatibilityPolicy
  compatibleBuildTypes?: BuildTypes
  incompatibleBuildTypes?: Compatibilities
  builds?: Builds
  locator?: string
}
type UserApprovalRule = {
  approved?: boolean
  user?: User
}
type UserApprovals = {
  count?: number
  userApproval?: UserApprovalRule[]
}
type GroupApprovalRule = {
  group?: Group
  requiredApprovalsCount?: number
  currentlyApprovedBy?: Users
}
type GroupApprovals = {
  count?: number
  groupApproval?: GroupApprovalRule[]
}
export enum ApprovalStatuses {
  WAITING_FOR_APPROVAL = 'waitingForApproval',
  APPROVED = 'approved',
  TIMED_OUT = 'timedOut',
  CANCELED = 'canceled',
}
export type ApprovalInfo = {
  timeoutTimestamp?: string
  configurationValid?: boolean
  canBeApprovedByCurrentUser?: boolean
  userApprovals?: UserApprovals
  groupApprovals?: GroupApprovals
  status?: ApprovalStatuses
}
export type ProgressInfo = {
  percentageComplete?: number | null
  elapsedSeconds: number
  estimatedTotalSeconds?: number | null
  leftSeconds?: number | null
  currentStageText?: string
  outdated?: boolean
  probablyHanging?: boolean
  lastActivityTime?: string
  outdatedReasonBuild?: Build
  overtime?: number
}
type TriggeredBy = {
  type?: string
  details?: string
  date?: string
  displayText?: string
  rawValue?: string
  user?: User
  build?: Build
  buildType?: BuildType
  properties?: Properties
}
type SnapshotDependencyLink = {
  build?: Build
  buildType?: BuildType
  buildTypeBranch?: string
}
export type FileChange = {
  'before-revision'?: string
  'after-revision'?: string
  changeType?: string
  changeTypeComment?: string
  file?: string
  'relative-file'?: string
  directory?: boolean
}
export type FileChanges = {
  count?: number
  file?: FileChange[]
}
type Items = {
  item?: string[]
}
export type ChangeStatus = {
  finishedBuilds?: number
  successfulBuilds?: number
  runningBuilds?: number
  failedBuilds?: number
  cancelledBuilds?: number
  criticalBuilds?: Builds
  notCriticalBuilds?: Builds
  newTestsFailedBuilds?: Builds
  compilationErrorBuilds?: Builds
  runningSuccessfullyBuilds?: number
  pendingBuildTypes?: number
  totalProblems?: number
  newFailedTests?: number
  otherFailedTests?: number
  queuedBuildsCount?: number
}
type Commiter = {
  vcsUsername?: string
  users?: Users
}
type ChangeMergedInfo = {
  status?: ChangeStatus
  branches?: Branches
  changes?: Changes
  vcsRootInstances?: VcsRootInstances
}
export type Change = {
  id?: number
  version?: string
  internalVersion?: string
  username?: string
  date?: string
  registrationDate?: string
  personal?: boolean
  href?: string
  webUrl?: string
  comment?: string
  user?: User
  type?: string
  snapshotDependencyLink?: SnapshotDependencyLink
  files?: FileChanges
  vcsRootInstance?: VcsRootInstance
  parentChanges?: Changes
  parentRevisions?: Items
  attributes?: Properties
  storesProjectSettings?: boolean
  status?: ChangeStatus
  commiter?: Commiter
  canEditComment?: boolean
  locator?: string
  mergedInfo?: ChangeMergedInfo
}
type Changes = {
  href?: string
  change?: Change[]
  nextHref?: string
  prevHref?: string
  count?: number
}
type ChangesGraph = {
  columns?: ReadonlyArray<{
    id?: string
    lines?: ReadonlyArray<ReadonlyArray<number>>
    maxWidth?: number
    name?: string
    startVertices?: ReadonlyArray<number>
    vertices?: ReadonlyArray<{
      id?: string
      description?: string
      lines?: ReadonlyArray<ReadonlyArray<number>>
      parents?: ReadonlyArray<number>
      position?: number
      type?: 'commit' | 'build'
      last?: boolean
      status?: string
    }>
  }>
}
type Revision = {
  version?: string
  internalVersion?: string
  vcsBranchName?: string
  'vcs-root-instance'?: VcsRootInstance
  'checkout-rules'?: string
}
type Revisions = {
  count?: number
  revision?: Revision[]
}
type BuildChange = {
  nextBuild?: Build
  prevBuild?: Build
}
type BuildChanges = {
  count?: number
  buildChange?: BuildChange[]
}
type Agents = {
  count?: number | null
  nextHref?: string
  prevHref?: string
  href?: string
  agent?: Agent[]
}
export type ProblemScope = {
  project?: Project
  buildTypes?: BuildTypes
  buildType?: BuildType
}
type TestCounters = {
  ignored?: number
  failed?: number
  muted?: number
  success?: number
  all?: number
  newFailed?: number
  duration?: number
}
type Tests = {
  count?: number
  myTestCounters?: TestCounters
  nextHref?: string
  prevHref?: string
  test?: Test[]
}
type Resolution = {
  type?: 'manually' | 'whenFixed' | 'atTime'
  time?: string
}
export type Investigation = {
  id?: string
  state?: 'TAKEN' | 'FIXED' | 'GIVEN_UP' | 'NONE'
  href?: string
  assignee?: User
  assignment?: Comment
  scope?: ProblemScope
  target?: ProblemTarget
  resolution?: Resolution
  responsible?: User
}
type Investigations = {
  count?: number
  nextHref?: string
  prevHref?: string
  href?: string
  readonly investigation?: Investigation[]
}
export type ProblemOccurrence = {
  id?: string
  type?: string
  identity?: string
  href?: string
  muted?: boolean
  currentlyInvestigated?: boolean
  currentlyMuted?: boolean
  logAnchor?: string
  newFailure?: boolean
  details?: string
  additionalData?: string
  problem?: Problem
  mute?: Mute
  build?: Build
}
type ProblemOccurrences = {
  count?: number
  href?: string
  nextHref?: string
  prevHref?: string
  problemOccurrence?: ProblemOccurrence[]
  passed?: number
  failed?: number
  newFailed?: number
  ignored?: number
  muted?: number
}
type Problem = {
  id?: string
  type?: string
  identity?: string
  href?: string
  description?: string
  mutes?: Mutes
  investigations?: Investigations
  problemOccurrences?: ProblemOccurrences
  locator?: string
}
type Problems = {
  count?: number
  nextHref?: string
  prevHref?: string
  problem?: Problem[]
}
type ProblemTarget = {
  anyProblem?: boolean
  tests?: Tests
  problems?: Problems
}
export type Mute = {
  id?: number
  href?: string
  assignment?: Comment
  scope?: ProblemScope
  target?: ProblemTarget
  resolution?: Resolution
}
type Mutes = {
  count?: number
  nextHref?: string
  prevHref?: string
  href?: string
  mute?: Mute[]
}
export type ParsedTestName = {
  testPackage?: string
  testSuite?: string
  testClass?: string
  testShortName?: string
  testNameWithoutPrefix?: string
  testMethodName?: string
  testNameWithParameters?: string
}
type Test = {
  id?: string
  name?: string
  mutes?: Mutes
  investigations?: Investigations
  testOccurrences?: TestOccurrences
  parsedTestName?: ParsedTestName
  href?: string
  locator?: string
}
export type TypedValue = {
  name?: string
  type?: string
  value?: string
}
type TestRunMetadata = {
  count?: number
  typedValues?: TypedValue[]
}
export type TestOccurrence = {
  id?: string
  name?: string
  status?: 'UNKNOWN' | 'NORMAL' | 'WARNING' | 'FAILURE' | 'ERROR' | 'SUCCESS'
  ignored?: boolean
  duration?: number
  runOrder?: string
  newFailure?: boolean
  muted?: boolean
  currentlyMuted?: boolean
  currentlyInvestigated?: boolean
  href?: string
  ignoreDetails?: string
  details?: string
  test?: Test
  mute?: Mute
  build?: Build
  firstFailed?: TestOccurrence
  nextFixed?: TestOccurrence
  invocations?: TestOccurrences
  metadata?: TestRunMetadata
  logAnchor?: string
}
type TestOccurrences = {
  count?: number
  href?: string
  nextHref?: string
  prevHref?: string
  testOccurrence?: TestOccurrence[]
  testCounters?: TestCounters
  ignored?: number
  newFailed?: number
  passed?: number
  muted?: number
  failed?: number
}
export type TestProblem = {
  investigations?: Investigations
  test?: Test
  mutes?: Mutes
  newFailure?: boolean
  failingBuildTypes?: {
    count?: number
    buildType?: BuildType[]
  }
}
type TestProblems = {
  count?: number
  entry?: TestProblem[]
  href?: string
  nextHref?: string
}
export type BuildProblem = {
  investigations?: Investigations
  build?: Build
  mutes?: Mutes
  problemOccurrence?: ProblemOccurrence
  alsoIn?: Builds
}
type Href = {
  href?: string
}
export type File = {
  name?: string
  fullName?: string
  size?: number
  modificationTime?: string
  href?: string
  parent?: File
  content?: Href
  children?: Files
}
type Files = {
  count?: number | null
  href?: string
  file?: File[]
}
type Issue = {
  url?: string
  id?: string
}
type IssueUsage = {
  changes?: Changes
  issue?: Issue
}
type IssuesUsages = {
  href?: string
  issueUsage?: IssueUsage[]
  count?: number
}
type Entry = {
  name?: string
  value?: string
}
type Entries = {
  count?: number
  entry?: Entry[]
}
type MetaData = {
  id?: string
  entries?: Entries
}
type Datas = {
  count?: number
  data?: MetaData[]
}
type ArtifactDependency = {
  id?: string
  name?: string
  type?: string
  disabled?: boolean
  inherited?: boolean
  href?: string
  properties?: Properties
  'source-buildType'?: BuildType
}
type ArtifactDependencies = {
  count?: number
  'artifact-dependency'?: ArtifactDependency[]
  replace?: string
}
type Related = {
  builds?: Builds
}
type BuildTriggeringOptions = {
  cleanSources?: boolean
  cleanSourcesInAllDependencies?: boolean
  rebuildAllDependencies?: boolean
  rebuildFailedOrIncompleteDependencies?: boolean
  queueAtTop?: boolean
  freezeSettings?: boolean
  tagDependencies?: boolean
  rebuildDependencies?: BuildTypes
}
type VcsLabel = {
  text?: string
  failureReason?: string
  status?:
    | 'UNKNOWN'
    | 'SUCCESSFUL_SET'
    | 'IS_BEING_SET'
    | 'FAILED'
    | 'DISABLED_FOR_THE_ROOT'
    | 'LABELING_NOT_SUPPORTED'
  buildId?: number
  'vcs-root-instance'?: VcsRootInstance
}
type Customizations = {
  parameters?: Record<string, string>
  changes?: Record<string, string>
  artifactDependencies?: Record<string, string>
}
type ArtifactDownloadInfo = {
  path?: string
  downloadTimestamp?: string
}
export type DownloadInfo = {
  artifactInfo?: ArtifactDownloadInfo[]
  build?: Build
  count?: number
}
type DownloadedArtifacts = {
  unfilteredCount?: number
  downloadInfo?: DownloadInfo[]
  count?: number
}
type MatrixDependencyParametersEntry = {
  name?: string
  value?: string
}
type MatrixDependencyParameters = {
  entry?: MatrixDependencyParametersEntry[]
}
type MatrixDependency = {
  build?: Build
  parameters?: MatrixDependencyParameters
}
type MatrixParameterValue = {
  value?: string | null
  label?: string | null
}
export type MatrixParameter = {
  name?: string | null
  value?: MatrixParameterValue[] | null
}
type MatrixDependencies = {
  dependency?: MatrixDependency[]
  count?: number
}
type MatrixParameters = {
  parameter?: MatrixParameter[]
  count?: number
}
type MatrixConfiguration = {
  enabled?: boolean
  dependencies?: MatrixDependencies
  parameters?: MatrixParameters
}

export type Build = {
  id?: number | null
  taskId?: number
  buildTypeId?: string
  buildTypeInternalId?: string
  number?: string
  status?: string | null
  state?: 'queued' | 'finished' | 'running' | 'deleted' | 'unknown' | null
  running?: boolean
  composite?: boolean
  parallelized?: boolean | null
  failedToStart?: boolean | null
  personal?: boolean | null
  percentageComplete?: number
  branchName?: string
  defaultBranch?: boolean
  unspecifiedBranch?: boolean
  history?: boolean
  pinned?: boolean
  href?: string
  webUrl?: string
  queuePosition?: number
  limitedChangesCount?: number
  artifactsDirectory?: string
  links?: Links | null
  statusText?: string | null
  buildType?: BuildType | null
  comment?: Comment
  tags?: Tags
  pinInfo?: Comment
  user?: User
  startEstimate?: string | null
  waitReason?: string | null
  finishEstimate?: string
  delayedByBuild?: Build
  plannedAgent?: Agent
  approvalInfo?: ApprovalInfo
  'running-info'?: ProgressInfo | null
  canceledInfo?: Comment | null
  queuedDate?: string
  startDate?: string
  finishDate?: string
  triggered?: TriggeredBy
  lastChanges?: Changes
  changes?: Changes
  revisions?: Revisions
  versionedSettingsRevision?: Revision
  artifactDependencyChanges?: BuildChanges
  agent?: Agent
  compatibleAgents?: Agents | null
  compatibleCloudImages?: CloudImages | null
  testOccurrences?: TestOccurrences
  problemOccurrences?: ProblemOccurrences
  artifacts?: Files | null
  relatedIssues?: IssuesUsages
  properties?: Properties
  resultingProperties?: Properties
  originalProperties?: Properties
  startProperties?: Properties
  attributes?: Entries
  statistics?: Properties
  metadata?: Datas
  'snapshot-dependencies'?: Builds
  'artifact-dependencies'?: Builds
  'custom-artifact-dependencies'?: ArtifactDependencies
  settingsHash?: string
  currentSettingsHash?: string
  modificationId?: string
  chainModificationId?: string
  replacementIds?: Items
  related?: Related
  triggeringOptions?: BuildTriggeringOptions
  usedByOtherBuilds?: boolean
  statusChangeComment?: Comment
  vcsLabels?: VcsLabel[]
  detachedFromAgent?: boolean | null
  finishOnAgentDate?: string
  customized?: boolean
  customization?: Customizations
  changesCollectingInProgress?: boolean
  queuedWaitReasons?: Properties
  downloadedArtifacts?: DownloadedArtifacts
  locator?: string
  firstBuildWithSameChanges?: Build
  matrixConfiguration?: MatrixConfiguration
}
type Builds = {
  count?: number
  href?: string
  nextHref?: string
  prevHref?: string
  build?: Build[]
}
type BranchVersion = {
  version?: string
  lastActivity?: string
  groupFlag?: boolean
  unspecified?: boolean
  builds?: Builds
  name?: string
  default?: boolean
  active?: boolean
  internalName?: string
}
type RepositoryState = {
  timestamp?: string
  count?: number
  branch?: BranchVersion[]
}
type VcsRootInstance = {
  id?: string
  'vcs-root-id'?: string
  vcsRootInternalId?: string
  name?: string
  vcsName?: string
  modificationCheckInterval?: number
  commitHookMode?: boolean
  lastVersion?: string
  lastVersionInternal?: string
  href?: string
  'vcs-root'?: VcsRoot
  status?: VcsStatus
  repositoryState?: RepositoryState
  properties?: Properties
  repositoryIdStrings?: Items
  projectLocator?: string
}
type VcsRootInstances = {
  count?: number
  href?: string
  nextHref?: string
  prevHref?: string
  'vcs-root-instance'?: VcsRootInstance[]
}
type VcsRoot = {
  id?: string
  internalId?: string
  uuid?: string
  name?: string
  vcsName?: string
  modificationCheckInterval?: number
  href?: string
  project?: Project
  properties?: Properties
  vcsRootInstances?: VcsRootInstances
  repositoryIdStrings?: Items
  projectLocator?: string
  locator?: string
}
type VcsRootEntry = {
  id?: string
  inherited?: boolean
  'vcs-root'?: VcsRoot
  'checkout-rules'?: string
}
type VcsRootEntries = {
  count?: number
  'vcs-root-entry'?: VcsRootEntry[]
}
type Step = {
  id?: string
  name?: string
  type?: string
  disabled?: boolean
  inherited?: boolean
  href?: string
  properties?: Properties
}
type Steps = {
  count?: number
  step?: Step[]
}
type Feature = {
  id?: string
  name?: string
  type?: string
  disabled?: boolean
  inherited?: boolean
  href?: string
  properties?: Properties
}
type Features = {
  count?: number
  feature?: Feature[]
}
type BuildTriggerCustomization = {
  enforceCleanCheckout?: boolean
  enforceCleanCheckoutForDependencies?: boolean
  parameters?: Properties
}
type Trigger = {
  id?: string
  name?: string
  type?: string
  disabled?: boolean
  inherited?: boolean
  href?: string
  properties?: Properties
  buildCustomization?: BuildTriggerCustomization
}
type Triggers = {
  count?: number
  trigger?: Trigger[]
}
type SnapshotDependency = {
  id?: string
  name?: string
  type?: string
  disabled?: boolean
  inherited?: boolean
  href?: string
  properties?: Properties
  'source-buildType'?: BuildType
}
type SnapshotDependencies = {
  count?: number
  'snapshot-dependency'?: SnapshotDependency[]
}
type AgentRequirement = {
  id?: string
  name?: string
  type?: string
  disabled?: boolean
  inherited?: boolean
  href?: string
  properties?: Properties
}
type AgentRequirements = {
  count?: number
  'agent-requirement'?: AgentRequirement[]
}
export type Branch = {
  name?: string | null
  internalName?: string | null
  default?: boolean | null
  unspecified?: boolean | null
  active?: boolean | null
  lastActivity?: string
  groupFlag?: boolean | null
  builds?: Builds
}
type Branches = {
  count?: number
  href?: string
  branch?: Branch[]
}
export type BuildType = {
  id?: string
  internalId?: string
  name?: string
  templateFlag?: boolean
  type?: 'regular' | 'composite' | 'deployment'
  paused?: boolean
  uuid?: string
  description?: string
  projectName?: string
  projectId?: string
  projectInternalId?: string
  href?: string
  webUrl?: string
  inherited?: boolean
  links?: Links | null
  project?: Project
  templates?: BuildTypes
  template?: BuildType
  'vcs-root-entries'?: VcsRootEntries
  settings?: Properties
  parameters?: Properties
  steps?: Steps
  features?: Features
  triggers?: Triggers
  'snapshot-dependencies'?: SnapshotDependencies
  'artifact-dependencies'?: ArtifactDependencies
  'agent-requirements'?: AgentRequirements
  branches?: Branches
  builds?: Builds
  investigations?: Investigations
  compatibleAgents?: Agents
  vcsRootInstances?: VcsRootInstances
  externalStatusAllowed?: boolean
  pauseComment?: Comment
  locator?: string
}
type VcsRoots = {
  count?: number
  href?: string
  nextHref?: string
  prevHref?: string
  'vcs-root'?: VcsRoot[]
}
type ProjectFeature = {
  id?: string
  name?: string
  type?: string
  disabled?: boolean
  inherited?: boolean
  href?: string
  properties?: Properties
}
type ProjectFeatures = {
  count?: number
  href?: string
  projectFeature?: ProjectFeature[]
}
type Projects = {
  count?: number
  href?: string
  nextHref?: string
  prevHref?: string
  project?: Project[]
}
type CloudProfiles = {
  count?: number
  nextHref?: string
  prevHref?: string
  href?: string
  cloudProfile?: CloudProfile[]
}
export type Project = {
  id?: string
  internalId?: string
  uuid?: string
  name?: string
  parentProjectId?: string
  parentProjectInternalId?: string
  parentProjectName?: string
  archived?: boolean
  virtual?: boolean
  description?: string
  href?: string
  webUrl?: string
  links?: Links | null
  parentProject?: Project
  readOnlyUI?: StateField
  defaultTemplate?: BuildType
  buildTypes?: BuildTypes
  templates?: BuildTypes
  parameters?: Properties
  vcsRoots?: VcsRoots
  projectFeatures?: ProjectFeatures
  projects?: Projects
  cloudProfiles?: CloudProfiles
  ancestorProjects?: Projects
  locator?: string
}
export type AgentPool = {
  id?: number
  name?: string
  href?: string
  maxAgents?: number
  agentTypes?: AgentTypes
  ownerProject?: Project
  projects?: Projects
  agents?: Agents
  locator?: string
}
type AgentTypes = {
  count?: number
  agentType?: AgentType[]
}
type AgentType = {
  id?: number
  name?: string
  isCloud?: boolean
  configurationParameters?: Properties
  environmentParameters?: Properties
  systemParameters?: Properties
  environment?: Environment
}
type RelatedEntity = {
  type?: string
  unknown?: boolean
  internalId?: string
  text?: string
  build?: Build
  buildType?: BuildType
  project?: Project
  user?: User
  group?: Group
  test?: Test
  problem?: Problem
  agent?: Agent
  vcsRoot?: VcsRoot
  change?: Change
  agentPool?: AgentPool
}
type OperationResult = {
  message?: string
  related?: RelatedEntity
}
type MultipleOperationResult = {
  count?: number
  errorCount?: number
  operationResult?: OperationResult[]
}
type PinInfo = {
  status?: boolean
  comment?: Comment
}
type NewProjectDescription = {
  copyAllAssociatedSettings?: boolean
  projectsIdsMap?: Properties
  buildTypesIdsMap?: Properties
  vcsRootsIdsMap?: Properties
  name?: string
  id?: string
  sourceProjectLocator?: string
  sourceProject?: Project
  parentProject?: Project
}
type Server = {
  version?: string
  versionMajor?: number
  versionMinor?: number
  startTime?: string
  currentTime?: string
  buildNumber?: string
  buildDate?: string
  internalId?: string
  role?: string
  webUrl?: string
  projects?: Href
  vcsRoots?: Href
  builds?: Href
  users?: Href
  userGroups?: Href
  agents?: Href
  buildQueue?: Href
  agentPools?: Href
  investigations?: Href
  mutes?: Href
  artifactsUrl?: string
  nodes?: Href
}
type LicenseKey = {
  valid?: boolean
  active?: boolean
  expired?: boolean
  obsolete?: boolean
  expirationDate?: string
  maintenanceEndDate?: string
  type?: 'evaluation' | 'eap' | 'open_source' | 'commercial' | 'enterprise' | 'professional'
  servers?: number
  agents?: number
  unlimitedAgents?: boolean
  buildTypes?: number
  unlimitedBuildTypes?: boolean
  errorDetails?: string
  key?: string
  rawType?: string
}
type LicenseKeys = {
  count?: number
  href?: string
  licenseKey?: LicenseKey[]
}
type LicensingData = {
  licenseUseExceeded?: boolean
  maxAgents?: number
  unlimitedAgents?: boolean
  maxBuildTypes?: number
  unlimitedBuildTypes?: boolean
  buildTypesLeft?: number
  serverLicenseType?:
    | 'evaluation'
    | 'eap'
    | 'open_source'
    | 'commercial'
    | 'enterprise'
    | 'professional'
  serverEffectiveReleaseDate?: string
  agentsLeft?: number
  licenseKeys?: LicenseKeys
}
type MetricTag = {
  name?: string
  value?: string
}
type MetricTags = {
  count?: number
  metricTag?: MetricTag[]
}
type MetricValue = {
  name?: string
  value?: number
  tags?: MetricTags
}
type MetricValues = {
  count?: number
  metricValue?: MetricValue[]
}
type Metric = {
  name?: string
  description?: string
  prometheusName?: string
  metricValues?: MetricValues
  metricTags?: MetricTags
}
type Metrics = {
  count?: number
  metric?: Metric[]
}
type Responsibility = {
  name?: string
  description?: string
}
type EnabledResponsibilities = {
  count?: number
  responsibility?: Responsibility[]
}
type DisabledResponsibilities = {
  count?: number
  responsibility?: Responsibility[]
}
type EffectiveResponsibilities = {
  count?: number
  responsibility?: Responsibility[]
}
type Node = {
  id?: string
  url?: string
  online?: boolean
  role?: string
  current?: boolean
  enabledResponsibilities?: EnabledResponsibilities
  disabledResponsibilities?: DisabledResponsibilities
  effectiveResponsibilities?: EffectiveResponsibilities
}
type Nodes = {
  count?: number
  node?: Node[]
}

export type ChangeLogRow = {
  build?: Build
  change?: Change
}

export type ArtifactExtension = {
  icon: {
    name: string
  }
  title: string
  hrefs: Readonly<Record<string, string>>
}

export type PoolPermission = {
  poolId: AgentPoolId
  canChangeStatus: boolean
  canAuthorized: boolean
}

export type DockerImage = {
  name?: string
  badges?: ReadonlyArray<string>
  description?: string
  dockerHubUrl?: string
}

type DockerImages = {
  count?: number
  images?: ReadonlyArray<DockerImage>
}

export type DockerTag = {name: string}

type DockerTags = {
  tags?: ReadonlyArray<DockerTag>
}
