import {BuildApprovalViewContext} from './BuildApproval.context'
import type {Props} from './BuildApproval.types'
import {BuildApprovalViewMode} from './BuildApproval.types'
import BuildApprovalClassic from './BuildApprovalClassic/BuildApprovalClassic'
import BuildApprovalDetailsPopup from './BuildApprovalDetailsPopup/BuildApprovalDetailsPopup'
import BuildApprovalInline from './BuildApprovalInline/BuildApprovalInline'

export default function BuildApprovalLayout(props: Props) {
  const {buildId, view} = props

  let Tag
  let contextValue

  switch (view) {
    case BuildApprovalViewMode.CLASSIC_UI:
      Tag = BuildApprovalClassic
      contextValue = BuildApprovalViewMode.INLINE
      break
    case BuildApprovalViewMode.POPUP:
      Tag = BuildApprovalDetailsPopup
      contextValue = BuildApprovalViewMode.POPUP
      break
    default:
      contextValue = BuildApprovalViewMode.INLINE
      Tag = BuildApprovalInline
  }

  return (
    <BuildApprovalViewContext.Provider value={contextValue}>
      <Tag buildId={buildId} />
    </BuildApprovalViewContext.Provider>
  )
}
