import type {JsonSchema} from '@jsonforms/core'
import type {Optional} from 'utility-types'

import type {PipelineSettings} from '../types'

type BaseVcsRootDto = {
  url: string
  branch: string
  vcsName: string
  branchSpecification?: string
  username?: string | null
  isPrivate?: boolean
  connectionId?: string
}

export type GetRepositoryBranchesArg = {
  url: string
  vcsName?: string
  username?: string
  isPrivate?: boolean
  branchSpecification?: string
  connectionId: string
}

export type RepositoryTestConnectionArg = {
  url: string
  connectionId: string
  username?: string
  vcsName: string
}

export type NewVcsRootDto = BaseVcsRootDto & {
  isPrivate?: boolean
  connectionId?: string
}

export type VcsRootDto = BaseVcsRootDto & {
  id: string
}

type NewTriggerDto = {
  type: string
  parameters?: Record<string, string>
  disabled?: boolean
}

type TriggerDto = Required<NewTriggerDto>

export type NewIntegrationDto = {
  id?: string
  type: string
  parameters: Record<string, string>
}

type IntegrationDto = Required<NewIntegrationDto>

export enum ConfirmationDecision {
  OVERRIDE = 'override',
  IMPORT = 'import',
}

export type VersionedSettingsDto = {
  storedInRepo?: boolean
  filename?: string | null
  confirmationDecision?: ConfirmationDecision
}

export type NewPipelineDto = {
  yaml: string
  vcsRoot: NewVcsRootDto
  triggers?: NewTriggerDto[]
  additionalVcsRoots: BaseVcsRootDto[]
  integrations?: NewIntegrationDto[]
  versionedSettings?: VersionedSettingsDto
}

export type NewPipeline = Omit<NewPipelineDto, 'yaml'> & {
  settings: PipelineSettings
}

export type PipelineDto = {
  id: string
  pipelineVersion?: string | null
  yaml: string
  vcsRoot: VcsRootDto
  additionalVcsRoots: VcsRootDto[]
  triggers: TriggerDto[]
  integrations: IntegrationDto[]
  versionedSettings?: VersionedSettingsDto
}

export type Pipeline = Optional<PipelineDto, 'yaml'> & {
  settings: PipelineSettings
}

export type UpdatePipelineArg = {
  id: string
  body: NewPipeline
}

type Owner = {
  avatarUrl: string
  login: string
}

type Repository = {
  defaultBranch: string
  htmlUrl: string
  name: string
  private: boolean
}

export enum RepositoryGroupType {
  CURRENT_USER = 1,
  OTHER_USER = 2,
  ORGANISATION = 3,
}

export type RepositoryGroup = {
  myGroupType: RepositoryGroupType
  myOwner: Owner
  myRepositories: Repository[]
}

export type Optimization = {
  name: string
  value: number
}

export enum Optimizations {
  PARALLEL_TESTING = 'parallelTests',
  BUILD_CACHE = 'buildCache',
  REUSING_JOBS = 'reusedJobs',
  REUSING_DOCKER_IMAGE = 'reusedDockerImage',
}

export type PossibleOptimization = {
  name: string
  expectedValue?: string
}

export type RunDuration = {
  number: number
  duration: number
  optimized?: number
  canceled?: boolean
}

export type RunDurations = {
  queuedDate: string
  startedDate?: string
  finishedDate?: string
  estimatedDuration?: number
  optimizedDuration?: number
  previousRunDuration?: number
  appliedOptimizations?: Optimization[]
  possibleOptimizations?: PossibleOptimization[]
}

export type PipelineDurations = {
  totalDuration: number
  optimizedDuration?: number
  optimizations?: Optimization[]
  runs?: RunDuration[]
}

export type CheckVersionedSettingsArg = {
  vcsRoot: NewVcsRootDto
  filename?: string
}

type JsonSchemaExtendedDefs = Record<
  string,
  {
    enumNames: string[]
    enumDescription: string[]
  }
>

type JsonSchemaDefs = {
  $defs: JsonSchema['definitions'] & JsonSchemaExtendedDefs
}

export type PipelineRunnersJsonFromXMLs = {
  [key in string]: {
    jsonSchema: JsonSchema & JsonSchemaDefs
  }
}
