import {queryToObject} from '../../../../../utils/queryParams'
import type {OrderDirection, TestScope} from '../../../../packages/Tests/Tests.types'
import {OrderByField, TestScopesKey} from '../../../../packages/Tests/Tests.types'
import {defaultFilterTemplate, getFilterQueryParams, getTestsLocator} from '../BuildTestsTab.utils'
import {getStatusQueryParam} from '../TestsFilter/TestsStatusFilter/TestsStatusFilter.utils'
import {ViewType} from '../TestsFilter/TestsViewSelect/TestsViewSelect.utils'

const getScopesLocator = ({
  testOccurrencesLocator,
  currentPage,
  count,
  scope,
  orderBy,
  order,
}: {
  testOccurrencesLocator: string
  currentPage?: number
  count?: number
  scope?: TestScope
  orderBy?: OrderByField | null | undefined
  order?: OrderDirection
}): string => {
  const scopeParts = [
    scope?.suite != null ? `suite:(value:(${scope.suite}),matchType:equals)` : '',
    scope?.package != null ? `package:(value:(${scope.package}),matchType:equals)` : '',
    scope?.class != null ? `class:(value:(${scope.class}),matchType:equals)` : '',
  ]
    .filter(Boolean)
    .join(',')
  const scopeLocator = scopeParts.length > 0 ? scopeParts : ''
  const orderLocator = orderBy != null && order != null ? `orderBy:(${orderBy}:${order})` : ''
  const countLocator = count != null ? `count:${count}` : ''
  const startLocator =
    currentPage != null && count != null ? `start:${(currentPage - 1) * count}` : ''
  return [
    `testOccurrences:(${testOccurrencesLocator})`,
    scopeLocator,
    orderLocator,
    countLocator,
    startLocator,
  ]
    .filter(Boolean)
    .join(',')
}
const getScopeKeyByViewType = (view: ViewType): TestScopesKey => {
  switch (view) {
    case ViewType.SUITES:
      return TestScopesKey.SUITE

    case ViewType.PACKAGES:
      return TestScopesKey.PACKAGE

    case ViewType.CLASSES:
      return TestScopesKey.CLASS

    default:
      return TestScopesKey.SUITE
  }
}

export const COUNT_VISIBLE_SCOPES = 100
export const getScopesViewVariables = ({
  location,
  buildLocator,
}: {
  location: {search: string}
  buildLocator: string
}) => {
  const filterQueryParams = getFilterQueryParams(location, {
    ...defaultFilterTemplate,
    view: ViewType.SUITES,
    orderBy: OrderByField.DURATION,
  })
  const scopeName = getScopeKeyByViewType(filterQueryParams.view)
  const currentPage = Number(queryToObject(location.search).page ?? '1')
  const status = getStatusQueryParam(location)

  const testOccurrencesLocator = getTestsLocator({
    onlyNew: filterQueryParams.onlyNew,
    name: filterQueryParams.name,
    withoutInvestigator: filterQueryParams.withoutInvestigator,
    status,
    count: -1,
    baseLocator: `build:(${buildLocator})`,
  })
  const locator = getScopesLocator({
    testOccurrencesLocator,
    order: filterQueryParams.order,
    orderBy: filterQueryParams.orderBy,
    scope: filterQueryParams.scope,
    count: COUNT_VISIBLE_SCOPES,
    currentPage,
  })

  return {
    locator,
    status,
    scopeName,
  }
}
