/**
 * @generated SignedSource<<d4575ee03d979e65677c2190f80330f8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CommiterSelectQuery$variables = {
  changeLocator: string;
};
export type CommiterSelectQuery$data = {
  readonly changeCommiters: {
    readonly commiter: ReadonlyArray<{
      readonly users: {
        readonly user: ReadonlyArray<{
          readonly avatars: {
            readonly urlToSize40: string | null | undefined;
          } | null | undefined;
          readonly name: string | null | undefined;
          readonly rawId: number | null | undefined;
        }> | null | undefined;
      } | null | undefined;
      readonly vcsUsername: string | null | undefined;
    }> | null | undefined;
  } | null | undefined;
};
export type CommiterSelectQuery = {
  response: CommiterSelectQuery$data;
  variables: CommiterSelectQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "changeLocator"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "changeLocator",
    "variableName": "changeLocator"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "vcsUsername",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rawId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "UserAvatars",
  "kind": "LinkedField",
  "name": "avatars",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "urlToSize40",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CommiterSelectQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Commiters",
        "kind": "LinkedField",
        "name": "changeCommiters",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Commiter",
            "kind": "LinkedField",
            "name": "commiter",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Users",
                "kind": "LinkedField",
                "name": "users",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CommiterSelectQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Commiters",
        "kind": "LinkedField",
        "name": "changeCommiters",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Commiter",
            "kind": "LinkedField",
            "name": "commiter",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Users",
                "kind": "LinkedField",
                "name": "users",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "87d61ac0a169c380c3bd44a207838ed5",
    "id": null,
    "metadata": {},
    "name": "CommiterSelectQuery",
    "operationKind": "query",
    "text": "query CommiterSelectQuery(\n  $changeLocator: String!\n) {\n  changeCommiters(changeLocator: $changeLocator) {\n    commiter {\n      vcsUsername\n      users {\n        user {\n          rawId\n          name\n          avatars {\n            urlToSize40\n          }\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ac243b44fc253a1f35407253c9d48ba9";

export default node;
