import classnames from 'classnames'

import {getAgentHref, getAgentTypeHref} from '../../../routes'
import type {OSType} from '../../../types'
import type {KeyValue} from '../../../utils/object'
import Icon from '../Icon/Icon'
import LinkWithIcon from '../LinkWithIcon/LinkWithIcon'
import MiddleEllipsis from '../MiddleEllipsis/MiddleEllipsis'
import RouterLinkWrapper from '../RouterLinkWrapper/RouterLinkWrapper'

import type {AgentLinkProps} from './AgentLink.types'

import styles from './AgentLink.css'

export const osIconMap: KeyValue<OSType, string> = {
  Windows: 'os-windows',
  macOS: 'os-mac',
  Linux: 'os-linux',
  Solaris: 'os-solaris',
  FreeBSD: 'os-bsd',
  Unix: 'os-unix',
}

function AgentLink(props: AgentLinkProps) {
  const {
    className,
    name,
    href,
    osType,
    secondary,
    typeId,
    agentId,
    isSakuraUI,
    iconClassName,
    beforeIcon,
  } = props

  if (name == null) {
    return null
  }

  let agentUrl

  if (isSakuraUI === true) {
    if (agentId != null) {
      agentUrl = getAgentHref(agentId)
    } else if (typeId != null && typeId !== 0) {
      agentUrl = getAgentTypeHref(typeId)
    }
  } else {
    agentUrl = href
  }

  const classes = classnames(styles.agentLink, className, {
    [styles.secondary]: secondary || !href,
    [styles.withoutLink]: !agentUrl || !href,
  })
  const iconClasses = classnames(styles.icon, iconClassName)
  const icon = (osType && osIconMap[osType]) || 'unknown_os'
  const title = osType || 'Unknown OS'

  if (!agentUrl || !href) {
    return (
      <div className={classes}>
        {beforeIcon}
        {name !== 'N/A' && osType != null && (
          <div className={iconClasses} title={title}>
            <Icon name={icon} />
          </div>
        )}
        <MiddleEllipsis className={styles.onlyText}>{name}</MiddleEllipsis>
      </div>
    )
  }

  return (
    <RouterLinkWrapper>
      <LinkWithIcon
        href={agentUrl}
        className={classes}
        iconClassName={iconClasses}
        iconPlaceholder={<span className={iconClasses} key="icon" />}
        icon={icon}
        iconTitle={title}
        textTitle={`Agent name: ${name}`}
        leftNodes={beforeIcon}
      >
        {name}
      </LinkWithIcon>
    </RouterLinkWrapper>
  )
}

export default AgentLink
