import RingLink from '@jetbrains/ring-ui/components/link/link'
import type {To} from 'react-router-dom'
import {useHref, useLinkClickHandler} from 'react-router-dom'

type Props = {
  to: To
  children: string
}

function Link({children, to}: Props) {
  const href = useHref(to)
  const onClick = useLinkClickHandler(to)
  return (
    <RingLink href={href} onClick={onClick}>
      {children}
    </RingLink>
  )
}

export default Link
