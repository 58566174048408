import type {EntryPoint} from 'react-relay'
import {getRequest} from 'relay-runtime'

import {makeResource} from '../../../../../../../utils/makeResource'
import {commiterSelectQuery} from '../../../../../../common/UserSelect/CommiterSelect.queries'

import type {ChangesTabComponent} from './ChangesTab'

export const ChangesTabEntryPoint: EntryPoint<ChangesTabComponent, number> = {
  root: makeResource(
    'ChangesTab',
    () => import(/* webpackChunkName: "ChangesTab", webpackPrefetch: true */ './ChangesTab'),
  ),
  getPreloadProps: jobId => ({
    queries: {
      commiterSelect: {
        parameters: getRequest(commiterSelectQuery),
        variables: {changeLocator: `build:(id:${jobId})`},
      },
    },
  }),
}
