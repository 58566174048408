/**
 * @generated SignedSource<<e4a0b4e7594891f2952fcf693328881c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SnapshotDependenciesCountersQuery$variables = {
  locator: string;
};
export type SnapshotDependenciesCountersQuery$data = {
  readonly builds: {
    readonly count: number | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"BuildProblemsPreviewDependencyIdsFragment" | "SnapshotDependenciesCountersFragment">;
  } | null | undefined;
};
export type SnapshotDependenciesCountersQuery = {
  response: SnapshotDependenciesCountersQuery$data;
  variables: SnapshotDependenciesCountersQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "locator"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "locator",
    "variableName": "locator"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "count",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SnapshotDependenciesCountersQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Builds",
        "kind": "LinkedField",
        "name": "builds",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SnapshotDependenciesCountersFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "BuildProblemsPreviewDependencyIdsFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SnapshotDependenciesCountersQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Builds",
        "kind": "LinkedField",
        "name": "builds",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Build",
            "kind": "LinkedField",
            "name": "build",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "state",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "failedToStart",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ProgressInfo",
                "kind": "LinkedField",
                "name": "runningInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "elapsedSeconds",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "estimatedTotalSeconds",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "rawId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "304b439102e3ec7bce8a68fc93dedd04",
    "id": null,
    "metadata": {},
    "name": "SnapshotDependenciesCountersQuery",
    "operationKind": "query",
    "text": "query SnapshotDependenciesCountersQuery(\n  $locator: String!\n) {\n  builds(locator: $locator) {\n    count\n    ...SnapshotDependenciesCountersFragment\n    ...BuildProblemsPreviewDependencyIdsFragment\n  }\n}\n\nfragment BuildProblemsPreviewDependencyIdsFragment on Builds {\n  build {\n    rawId\n    id\n  }\n}\n\nfragment SnapshotDependenciesCountersFragment on Builds {\n  build {\n    status\n    state\n    failedToStart\n    runningInfo {\n      elapsedSeconds\n      estimatedTotalSeconds\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "21329734518f49bd7a92d753e98f7b17";

export default node;
