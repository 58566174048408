import ExceptionIcon from '@jetbrains/icons/exception.svg'
import InfoIcon from '@jetbrains/icons/info.svg'
import WarningIcon from '@jetbrains/icons/warning.svg'
import classNames from 'classnames'
import {useMemo} from 'react'

import SvgIcon from '../../../common/SvgIcon/SvgIcon'

import styles from './HealthItem.css'

type Props = {
  severity: 'Error' | 'Warning' | 'Info'
  className?: string
}

function HealthItemIcon({severity, className}: Props) {
  const isError = severity === 'Error'
  const isWarning = severity === 'Warning'
  const iconToShow = useMemo(() => {
    if (isError) {
      return ExceptionIcon
    }

    if (isWarning) {
      return WarningIcon
    }

    return InfoIcon
  }, [isError, isWarning])

  return <SvgIcon className={classNames(styles.icon, className)} icon={iconToShow} />
}

export default HealthItemIcon
