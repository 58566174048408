import 'whatwg-fetch'
import type {PlaceId} from '@jetbrains/teamcity-api'
import {createAction} from '@reduxjs/toolkit'

import type {BranchesToShow} from '../components/pages/BuildTypePage/BuildTypeOverviewTab/BuildTypeBranches/CollapsibleBranchLines/CollapsibleBranchLines.types'
import type {State} from '../reducers/types'
import {getStatusKey} from '../rest/schemata'
import {
  buildTypeBranchesSectionCollapsedKey,
  getAutoExpandQueuedBuilds,
  getShowQueuedBuildsInBuildsList,
} from '../selectors'
import type {Branch} from '../services/rest'
import {restApi} from '../services/rest'
import {
  agentsInCloud,
  blocks,
  builds,
  buildTab,
  buildTypesLimit,
  buildTypeTab,
  cachedPlugins,
  dialog,
  dummyCalls,
  isSakuraUI,
  queuedTogglerAutoExpand,
  routeAvailabilityResponse,
  serverInfo,
  showQueuedBuildsCount,
  showQueuedBuildsInBuildsList,
  showQueuedBuildsInProject,
  showQueuedBuildsPerBranch,
  sorting,
  syncStorageValues,
  urlExtensions,
} from '../slices'
import buildsFilters from '../slices/buildsFilters'
import type {AgentId, AgentPoolId, BuildId, BuildTypeId, ProblemId, ProjectId} from '../types'
import {getBuildTypeStatusRequest} from '../types'
import {BS} from '../types/BS_types'
import {createRefetchableSubscription} from '../utils/refetchable'
import {getTopics, subscribeOnOverallEvents} from '../utils/subscriber'
import * as SubscriptionEvents from '../utils/subscriptionEvents'

import {CollapsibleBlock} from './collapsibleBlockTypes'
import type {ActionCreator, AppThunk} from './types'

export const resetState = createAction<State>('resetState')

export const receiveAgentsInCloud = agentsInCloud.actions.receive

export const openDialog = dialog.actions.open
export const closeDialog = dialog.actions.close

export const updateResults =
  (update: () => unknown): ActionCreator =>
  () =>
  dispatch => {
    dispatch(buildsFilters.actions.updateResults())
    update()
  }
export const changeBuildTypeTab = buildTypeTab.actions.change
export const changeBuildTab = buildTab.actions.change

export const setIsSakuraUI = isSakuraUI.actions.set
export const changeSortingDimension = sorting.actions.changeDimension
export const changeSortingDirection = sorting.actions.changeDirection
export const changeSorting =
  (dimension: string, descending: boolean): AppThunk<any> =>
  dispatch => {
    dispatch(changeSortingDimension(dimension))
    dispatch(changeSortingDirection(descending))
  }
export const showAuthorizeAgentDialog =
  (agentId: AgentId, poolId: AgentPoolId, isCloud: boolean): AppThunk<any> =>
  () => {
    BS?.Agent?.showChangeStatusDialog(
      true,
      agentId,
      false,
      'changeAuthorizeStatus',
      {
        cloud: isCloud,
        poolId,
      },
      () => {
        BS?.AgentsReact?.refreshFetcherData()

        BS?.AgentsReact?.refreshTabsCounters()
      },
    )
  }

const addBlocks = blocks.actions.add

const removeBlocks = blocks.actions.remove

export const collapseBuildTypeBranchesSection = (id: BuildTypeId, branchesToShow: BranchesToShow) =>
  addBlocks(CollapsibleBlock.COLLAPSED_BRANCHES_SECTION, [
    buildTypeBranchesSectionCollapsedKey(id, branchesToShow),
  ])
export const expandBuildTypeBranchesSection = (id: BuildTypeId, branchesToShow: BranchesToShow) =>
  removeBlocks(CollapsibleBlock.COLLAPSED_BRANCHES_SECTION, [
    buildTypeBranchesSectionCollapsedKey(id, branchesToShow),
  ])
export const collapseBuildTypeBuildsSection = (id: BuildTypeId | ReadonlyArray<BuildTypeId>) =>
  addBlocks(CollapsibleBlock.COLLAPSED_PROJECT_BUILDTYPELINE, Array.isArray(id) ? id : [id])
export const expandBuildTypeBuildsSection = (id: BuildTypeId | ReadonlyArray<BuildTypeId>) =>
  removeBlocks(CollapsibleBlock.COLLAPSED_PROJECT_BUILDTYPELINE, Array.isArray(id) ? id : [id])
export const expandSubproject = (id: ProjectId | ReadonlyArray<ProjectId>) =>
  removeBlocks(CollapsibleBlock.COLLAPSED_SUBPROJECT, Array.isArray(id) ? id : [id])
export const collapseSubproject = (id: ProjectId | ReadonlyArray<ProjectId>) =>
  addBlocks(CollapsibleBlock.COLLAPSED_SUBPROJECT, Array.isArray(id) ? id : [id])
export const toggleSubproject = (id: ProjectId, expand: boolean) =>
  expand ? expandSubproject(id) : collapseSubproject(id)
export const resetBuildsLocatorState = builds.actions.reset
export const storeUrlExtensions = urlExtensions.actions.set
export const setRouteAvailabilityResponse = routeAvailabilityResponse.actions.set
export const changeInvestigation =
  (
    buildTypeId: BuildTypeId,
    buildTypeName: string,
    presetFix: boolean,
    submitHandler?: () => unknown,
  ): AppThunk<any> =>
  () =>
    BS?.ResponsibilityDialog?.showDialog(buildTypeId, buildTypeName, presetFix, true, submitHandler)
export const showQueuedBuildsForBranch = (buildTypeId: BuildTypeId, branch: Branch) =>
  showQueuedBuildsPerBranch.actions.show({
    buildTypeId,
    branch,
  })
export const hideQueuedBuildsForBranch = (buildTypeId: BuildTypeId, branch: Branch) =>
  showQueuedBuildsPerBranch.actions.hide({
    buildTypeId,
    branch,
  })
export const setShowQueuedBuildsCount = (
  count: number,
  buildTypeId: BuildTypeId,
  branch?: Branch,
) =>
  showQueuedBuildsCount.actions.set({
    buildTypeId,
    branch,
    count,
  })
export const showQueuedBuildsForProject = showQueuedBuildsInProject.actions.show
export const hideQueuedBuildsForProject =
  (buildTypeId: BuildTypeId, branch?: Branch | null): AppThunk =>
  (dispatch, getState) => {
    const state = getState()
    const statusKey = getStatusKey(getBuildTypeStatusRequest(buildTypeId, branch))

    if (getAutoExpandQueuedBuilds(state, statusKey)) {
      return dispatch(queuedTogglerAutoExpand.actions.collapse(statusKey))
    }

    return dispatch(showQueuedBuildsInProject.actions.hide(buildTypeId))
  }
export const toggleQueuedVisibility =
  (buildTypeId: BuildTypeId | null | undefined, branch: Branch | null | undefined): AppThunk<any> =>
  (dispatch, getState) => {
    const state = getState()

    if (buildTypeId != null && !getShowQueuedBuildsInBuildsList(state)) {
      const statusKey = getStatusKey(getBuildTypeStatusRequest(buildTypeId, branch))

      if (getAutoExpandQueuedBuilds(state, statusKey)) {
        return dispatch(queuedTogglerAutoExpand.actions.collapse(statusKey))
      }
    }

    return dispatch(showQueuedBuildsInBuildsList.actions.toggle())
  }

export const receiveServerInfo = serverInfo.actions.receive
export const dummyAction = dummyCalls.actions.increment

export const addPlugin = (placeId: PlaceId, name: string) =>
  cachedPlugins.actions.add({placeId, name})
export const removePlugin = (placeId: PlaceId, name: string) =>
  cachedPlugins.actions.remove({placeId, name})
export const assignProblemInvestigations =
  (
    problemId: ProblemId | null | undefined,
    buildId: BuildId | null | undefined,
    presetFix: boolean,
    submitHandler?: () => unknown,
  ): AppThunk<any> =>
  () =>
    problemId != null &&
    buildId != null &&
    BS?.BulkInvestigateMuteTestDialog?.showForBuildProblem(
      problemId,
      buildId,
      presetFix,
      true,
      () => submitHandler?.(),
    )
export const setSyncStorageValue = (key: string, value: string | null) =>
  syncStorageValues.actions.set({key, value})
const REMAINING_AGENTS_TIMEOUT = 1000
const REMAINING_AGENT_EVENT_TYPE_TOPICS = getTopics('', [
  SubscriptionEvents.AGENT_REGISTERED,
  SubscriptionEvents.AGENT_UNREGISTERED,
  SubscriptionEvents.AGENT_STATUS_CHANGED,
  SubscriptionEvents.AGENT_REMOVED,
])
export const subscribeOnRemainingAgents = (): AppThunk<() => void> => dispatch =>
  createRefetchableSubscription(
    () => dispatch(restApi.endpoints.getLicensingData.initiate({})),
    handler =>
      subscribeOnOverallEvents(
        REMAINING_AGENT_EVENT_TYPE_TOPICS,
        handler,
        REMAINING_AGENTS_TIMEOUT,
      ),
  )
export const setBuildTypesLimit = buildTypesLimit.actions.set
