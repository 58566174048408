import {useCallback, useEffect, useMemo, useRef} from 'react'
import type {ReactNode} from 'react'

import {LocatorContext} from '../../../contexts/locator'
import {UpdateProvider} from '../../../contexts/update'
import type {PeriodicalExecutor} from '../../../types/BS_types'
import {BS} from '../../../types/BS_types'
import type {Refetchable} from '../../../utils/refetchable'
import createSimpleStream from '../../../utils/simpleStream'
import type {SimpleStream} from '../../../utils/simpleStream'

export type FetcherProps = {
  locator: string | null | undefined
  page?: number
  update$?: SimpleStream<void>
  updatePeriod?: number | null | undefined
  fetchData: (
    locator: string,
    options: {inBackground?: boolean; forceRefetch?: boolean},
    page?: number,
  ) => Refetchable
  resetLocatorState?: (locator: string) => unknown
  children?: ReactNode
  pause?: boolean | undefined
  hasSubscription?: boolean | undefined
}
export const DEBOUNCE_PERIOD = 200
const DEFAULT_UPDATE_PERIOD = 60000
export default function Fetcher({
  locator,
  page,
  update$,
  updatePeriod = DEFAULT_UPDATE_PERIOD,
  fetchData,
  resetLocatorState,
  children,
  pause = false,
  hasSubscription,
}: FetcherProps) {
  const executor = useRef<PeriodicalExecutor>()
  const unmounted = useRef(false)
  const defaultUpdate$ = useMemo(() => createSimpleStream(DEBOUNCE_PERIOD), [])
  const hasScheduled = useRef(false)
  const job = useRef<Promise<unknown>>()
  const fetch = useCallback(
    async (options: {inBackground?: boolean; forceRefetch?: boolean}) => {
      if (locator == null || unmounted.current || hasScheduled.current) {
        return
      }

      if (job.current != null) {
        hasScheduled.current = true
        await job.current
        hasScheduled.current = false
      }

      job.current = fetchData(locator, options, page)
      await job.current
      job.current = undefined
    },
    [fetchData, locator, page],
  )
  useEffect(() => {
    unmounted.current = false
    return () => {
      unmounted.current = true
    }
  }, [])
  useEffect(() => {
    if (pause || hasSubscription) {
      return undefined
    }

    if (BS?.periodicalExecutor == null || updatePeriod == null) {
      fetch({
        inBackground: false,
        forceRefetch: true,
      })
      return undefined
    }

    let isFirstCall = true
    const currentExecutor = BS.periodicalExecutor(() => {
      const promise = fetch({
        inBackground: !isFirstCall,
        forceRefetch: true,
      })
      isFirstCall = false
      return promise
    }, updatePeriod)
    executor.current = currentExecutor
    currentExecutor?.start()
    return () => {
      currentExecutor?.stop()
    }
  }, [fetch, pause, updatePeriod, hasSubscription])

  const updateImmediate = useCallback(() => {
    if (!pause) {
      return executor.current
        ? executor.current.unscheduledExecution()
        : fetch({
            inBackground: true,
            forceRefetch: true,
          })
    }
    return Promise.resolve()
  }, [pause, fetch])

  const currentUpdate$ = update$ ?? defaultUpdate$
  useEffect(
    () =>
      pause
        ? undefined
        : currentUpdate$.subscribe(() => {
            executor.current
              ? executor.current.unscheduledExecution()
              : fetch({
                  inBackground: true,
                  forceRefetch: true,
                })
          }),
    [currentUpdate$, fetch, pause],
  )
  useEffect(
    () => () => {
      if (resetLocatorState && locator != null && !unmounted.current) {
        resetLocatorState(locator)
      }
    },
    [locator, resetLocatorState],
  )
  useEffect(() => {
    hasScheduled.current = false
    job.current = undefined
  }, [locator, page])

  return useMemo(
    () =>
      children != null ? (
        <UpdateProvider update={currentUpdate$.fire} updateImmediate={updateImmediate}>
          <LocatorContext.Provider value={locator ?? null}>{children}</LocatorContext.Provider>
        </UpdateProvider>
      ) : null,
    [children, currentUpdate$, locator, updateImmediate],
  )
}
