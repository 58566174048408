import {produce} from 'immer'

import mapKeys from 'lodash/mapKeys'
import {createSelector} from 'reselect'

import type {State} from '../../../../reducers/types'
import {emptyArray, getEmptyHash} from '../../../../utils/empty'
import type {KeyValue} from '../../../../utils/object'
import type {Job, SuggestionType} from '../types'
import {isStoreYamlInVcsEnabled} from '../utils/featureToggles'

import {getDependencyName, parsePipeline, stringifyPipeline} from './EditPipelinePage.utils'
import {generatedFields} from './SettingsSidebarContent/JobSettingsSidebar/JobSteps/JobStep/JobStepHotFields/JobStepHotFields.descriptors'
import {errorValidators} from './SettingsSidebarContent/JobSettingsSidebar/JobSteps/JobStep/SmartDetectors/CliErrorMessage/useCliErrorMessage.consts'
import type {ErrorValidatorsKeys} from './SettingsSidebarContent/JobSettingsSidebar/JobSteps/JobStep/SmartDetectors/CliErrorMessage/useCliErrorMessage.consts'
import {MIN_PARALLELISM_COUNT} from './slices/EditPipelinePage.slices.consts'
import type {FormType, PipelineDraft} from './slices/EditPipelinePage.slices.types'
import {deleteIfEmpty} from './slices/EditPipelinePage.slices.utils'

const getPipelineDraftDeleted = (id: string) => (state: State) =>
  state.pipelines.pipelineDraft[id]?.deleted

export const getPipelineOriginalIntegrations = (id: string) => (state: State) =>
  state.pipelines.pipelineDraft[id]?.original?.integrations

export const getOriginalPipelineTriggers = (id: string) => (state: State) =>
  state.pipelines.pipelineDraft[id]?.original?.triggers

export const getOriginalPipelineSettings = (id: string) => (state: State) =>
  state.pipelines.pipelineDraft[id]?.original?.settings

export const getOriginalPipelineAdditionalVcsRoots = (id: string) => (state: State) =>
  state.pipelines.pipelineDraft[id]?.original?.additionalVcsRoots

export const getOriginalVersionedSettings = (id: string) => (state: State) =>
  state.pipelines.pipelineDraft[id]?.original?.versionedSettings

export const getDraft = (state: State, id: string) => state.pipelines.pipelineDraft[id]?.draft

export const getDraftPipelineAdditionalVcsRoots = (id: string) => (state: State) =>
  getDraft(state, id)?.additionalVcsRoots

export const getDraftJobs = (state: State, id: string) => getDraft(state, id)?.settings.jobs

const makePipelineErrorsSelector = () =>
  createSelector(
    [
      (state: State, props: {pipelineId: string}) =>
        getDraftJobs(state, props.pipelineId) ?? getEmptyHash(),
      (state: State, props: {pipelineId: string}) =>
        getPipelineDraftDeleted(props.pipelineId)(state)?.jobs ?? (emptyArray as string[]),
      (state: State, props: {pipelineId: string}) =>
        getPipelineDraftDeleted(props.pipelineId)(state)?.steps ??
        (getEmptyHash() as Record<string, number[]>),
    ],
    (jobs, deletedJobs, deletedSteps) =>
      Object.fromEntries(
        Object.entries(jobs)
          .filter(([jobId]) => !deletedJobs.includes(jobId))
          .map(([jobId, job]) => {
            const jobErrors: Array<Record<string, Array<string>>> = []

            const deletedStepsInCurrentJob = deletedSteps[jobId]
            const steps =
              deletedStepsInCurrentJob != null && deletedStepsInCurrentJob.length > 0
                ? job?.steps?.filter((_, index) => !deletedStepsInCurrentJob.includes(index))
                : job?.steps

            if (steps != null) {
              for (const step of steps) {
                const defaultFields = generatedFields[step.type]
                const stepWithDefaultValues = {
                  ...(defaultFields &&
                    Object.fromEntries(defaultFields.map(field => [field.name, '']))),
                  ...step,
                }

                const stepErrors = Object.fromEntries(
                  Object.entries(stepWithDefaultValues).map(([name, value]) => {
                    const result = (errorValidators[name as ErrorValidatorsKeys] ?? [])
                      .filter(({validator}) => !validator({value}))
                      .map(({message}) => message)

                    return [name, result]
                  }),
                )

                jobErrors.push(stepErrors)
              }
            }
            return [jobId, jobErrors]
          }),
      ),
  )

export const makeTotalCountPipelineErrorsSelector = () =>
  createSelector([makePipelineErrorsSelector()], errors =>
    Object.values(errors).reduce(
      (totalPipeline, jobErrors) =>
        totalPipeline +
        jobErrors?.reduce((acc, stepErrors) => acc + Object.values(stepErrors).flat().length, 0),
      0,
    ),
  )

export const makePipelineFormErrorsSelector = () =>
  createSelector(
    [makePipelineErrorsSelector(), (state: State) => state.pipelines.pipelineDraftForm.submitted],
    (errors, submitted): KeyValue<string, Array<Record<string, Array<string>>>> =>
      submitted ? errors : getEmptyHash(),
  )

export const makeTotalCountPipelineFormErrorsSelector = () =>
  createSelector(
    [
      makeTotalCountPipelineErrorsSelector(),
      (state: State) => state.pipelines.pipelineDraftForm.submitted,
    ],
    (count, submitted) => (submitted ? count : 0),
  )

const makeDraftStepFormErrorsSelector = () =>
  createSelector(
    [makePipelineFormErrorsSelector(), (_: State, props: {jobId: string}) => props.jobId],
    (errors, jobId) => errors[jobId] ?? [],
  )
export const getJobName = (state: State, pipelineId: string, id: string) => {
  const currentName = getDraftJobs(state, pipelineId)?.[id]?.name
  const renamed = state.pipelines.pipelineDraft[pipelineId]?.renamed?.jobs?.[id]
  return renamed ?? currentName ?? id
}

export const makeHasJobFormErrorSelector = () =>
  createSelector([makeDraftStepFormErrorsSelector()], stepErrors =>
    stepErrors.some(errors => Object.values(errors).flat().length > 0),
  )

export const getJobAgent = (jobId: string, id: string) => (state: State) =>
  getDraft(state, id)?.settings?.jobs?.[jobId]?.['runs-on']

export const getJobCheckoutWorkingDirectoriesOnly = (jobId: string, id: string) => (state: State) =>
  getDraft(state, id)?.settings?.jobs?.[jobId]?.['checkout-working-directories-only']

export const getJobAllowReuse = (jobId: string, id: string) => (state: State) =>
  getDraft(state, id)?.settings?.jobs?.[jobId]?.['allow-reuse'] !== false

export const isPipelineFormOpened = (state: State, formId: string, formType: FormType) => {
  const {id, type, isOpened} = state.pipelines.pipelineForm

  return id === formId && type === formType && isOpened === true
}

export const getSkippedSuggestions = (state: State, stepId: string) =>
  state.pipelines.suggestions.skippedSuggestions[stepId]

export const isSuggestionSkipped = (state: State, stepId: string, type: SuggestionType) =>
  getSkippedSuggestions(state, stepId)?.includes(type)

export const isSuccessMessageVisible = (state: State, stepId: string, type: SuggestionType) =>
  state.pipelines.suggestions.successMessages[stepId] === type

export const isDraftChanged = createSelector(
  (state?: PipelineDraft) => state?.deleted,
  (state?: PipelineDraft) => state?.renamed,
  (state?: PipelineDraft) => state?.reordered,
  (deleted, renamed, reordered) => Boolean(deleted || renamed || reordered),
)

const getResolvedSettingsFromDraft = createSelector(
  (state?: PipelineDraft) => state?.draft,
  (state?: PipelineDraft) => state?.deleted,
  (state?: PipelineDraft) => state?.renamed,
  (state?: PipelineDraft) => state?.reordered,
  (draft, deleted, renamed, reordered) =>
    produce(draft, pipeline => {
      if (pipeline != null) {
        if (pipeline.integrations != null) {
          if (deleted?.integrations != null) {
            pipeline.integrations = pipeline.integrations.filter(
              item => !deleted.integrations?.includes(item.id!),
            )
          }
          if (renamed?.integrations != null) {
            pipeline.integrations.forEach(item => {
              const newId = renamed.integrations?.[item.id!]
              if (newId != null) {
                item.id = newId
              }
            })
          }
        }
        if (pipeline.additionalVcsRoots != null && deleted?.additionalVcsRoots?.length) {
          pipeline.additionalVcsRoots = pipeline.additionalVcsRoots.filter(
            ({url}) => !deleted?.additionalVcsRoots?.includes(url),
          )
        }
        const {settings} = pipeline
        if (settings.jobs != null) {
          deleted?.jobs?.forEach(id => {
            delete settings.jobs![id]
          })
          Object.entries(settings.jobs).forEach(([jobId, job]) => {
            if (renamed?.jobs != null) {
              job.name = renamed.jobs![jobId] ?? job.name ?? jobId
            }
            if (deleted?.steps?.[jobId] != null || reordered?.steps?.[jobId] != null) {
              job.steps = job.steps
                ?.map((_, i) => {
                  const originalIndex = reordered?.steps?.[jobId]?.[i] ?? i
                  return {
                    value: job.steps![originalIndex],
                    isDeleted: deleted?.steps?.[jobId].includes(originalIndex),
                  }
                })
                .filter(({isDeleted}) => !isDeleted)
                .map(({value}) => value)
            }
            if (renamed?.integrations != null) {
              job.integrations = job.integrations?.map(id => renamed.integrations![id] ?? id)
            }
            if (
              job.parallelism != null &&
              (!job.parallelism || job.parallelism < MIN_PARALLELISM_COUNT)
            ) {
              delete job.parallelism
            }
            if (job.repositories?.length && deleted?.additionalVcsRoots?.length) {
              job.repositories = job.repositories?.filter(repository => {
                const [url] = Object.keys(repository)

                return !deleted?.additionalVcsRoots?.includes(url)
              })

              if (!job.repositories?.length) {
                deleteIfEmpty(job, 'repositories')
              }
            }
          })
        }
        if (settings.parameters != null) {
          deleted?.parameters?.forEach(name => {
            delete settings.parameters![name]
          })
          if (renamed?.parameters != null) {
            settings.parameters = mapKeys(
              settings.parameters,
              (_, name) => renamed.parameters![name] ?? name,
            )
          }

          deleteIfEmpty(settings, 'parameters')
        }
        if (settings.secrets != null) {
          deleted?.secrets?.forEach(name => {
            delete settings.secrets![name]
          })
          if (renamed?.secrets != null) {
            settings.secrets = mapKeys(
              settings.secrets,
              (_, name) => renamed.secrets![name] ?? name,
            )
          }

          deleteIfEmpty(settings, 'secrets')
        }
      }
    }),
)

export const getJobEnableDependencyCache = (jobId: string, id: string) => (state: State) =>
  getDraft(state, id)?.settings?.jobs?.[jobId]?.['enable-dependency-cache']

export const getResolvedSettings = (state: State, pipelineId: string) =>
  getResolvedSettingsFromDraft(state.pipelines.pipelineDraft[pipelineId])

const yamlSelector = createSelector(
  [
    (state: State, props: {pipelineId: string}) =>
      state.pipelines.pipelineYamlValidity[props.pipelineId]?.yaml,
    (state: State, props: {pipelineId: string}) =>
      state.pipelines.pipelineYamlValidity[props.pipelineId]?.isParsed,
    (state: State, props: {pipelineId: string}) =>
      getResolvedSettings(state, props.pipelineId)?.settings,
  ],
  (invalidYaml, isParsed, settings) =>
    (isStoreYamlInVcsEnabled && !isParsed
      ? invalidYaml
      : settings &&
        stringifyPipeline(settings, {
          noRefs: true,
        })) ?? '',
)

export const getYaml = (state: State, pipelineId: string) => yamlSelector(state, {pipelineId})

export function getNewYaml(state: State, pipelineId: string) {
  const yaml = getYaml(state, pipelineId)
  return state.pipelines.pipelineYamlDraft[pipelineId]?.yaml ?? yaml
}

export const getNewSettings = (state: State, pipelineId: string) =>
  parsePipeline(getNewYaml(state, pipelineId))

export function getStringifiedDependencies(jobs?: Record<string, Job>) {
  const result: Record<string, string[]> = {}
  if (jobs != null) {
    Object.entries(jobs).forEach(([id, job]) => {
      result[id] = job.dependencies?.map(getDependencyName) ?? []
    })
  }
  return JSON.stringify(result)
}

export const getIsPipelineValid = createSelector(
  [
    (state: State, props: {pipelineId: string}) =>
      !state.pipelines.pipelineYamlDraft[props.pipelineId]?.diagnostics?.length,
    (state: State, props: {pipelineId: string}) =>
      state.pipelines.pipelineYamlValidity[props.pipelineId]?.isParsed ?? true,
  ],
  (isValid, isParsed) => isValid && isParsed,
)
