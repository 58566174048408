import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'

import type {QueueCountersByPool} from './QueueSidebar.types'

export const queueSidebar = createSlice({
  name: 'queueSidebar',
  initialState: {
    counters: {},
  },
  reducers: {
    receiveCounters: (state, action: PayloadAction<QueueCountersByPool>) => {
      state.counters = action.payload
    },
  },
})
