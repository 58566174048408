import type {ReactNode} from 'react'

export type BannerAction = {
  label: string
  onClick: () => void
}
export enum MessageType {
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
  SUCCESS = 'success',
}
export type Banner = {
  key: string
  title?: string
  message: string
  type: MessageType
  closeable?: boolean
  actions?: BannerAction[]
}

export type BannerStoreValue = Record<string, Banner[]>

export type BannersContextProps = {
  items: BannerStoreValue
  addBanner: (pipelineId: string, value: Banner) => void
  removeBanner: (pipelineId: string, key: string) => void
  removeAllBanners: () => void
}

export type BannersProviderProps = {
  children: ReactNode
}

export const RELOAD_MESSAGE = 'reload-message'
export const VALIDATION_ERRROR_MESSAGE = 'validation-error'
