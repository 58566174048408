import type {Diagnostic} from '@codemirror/lint'

import type {KeyValue} from '../../../../../utils/object'
import type {SuggestionType} from '../../types'

import type {PipelineDraftState, PipelineFormState} from './EditPipelinePage.slices.types'

export const initialState: PipelineDraftState = {}

export const collapsedBlocksInitialState: Record<string, boolean> = {}

export const pipelineFormInitialState: PipelineFormState = {}

export const pipelineYamlInitialState: KeyValue<
  string,
  {yaml?: string; diagnostics?: Diagnostic[]}
> = {}
export const pipelineYamlValidityState: KeyValue<string, {isParsed: boolean; yaml?: string}> = {}
export const MIN_PARALLELISM_COUNT = 2

export const suggestionsInitialState: {
  skippedSuggestions: Record<string, string[]>
  successMessages: Record<string, SuggestionType | null>
} = {
  skippedSuggestions: {},
  successMessages: {},
}
