const supportBaseUrl = 'https://teamcity-support.jetbrains.com/hc/en-us/requests/new'

type Props = {
  licenseIsCloud: boolean | undefined
  licenseIsEnterpise: boolean | undefined
  version: string | undefined
  type?: 'teamcity_pipelines' | 'teamcity_cloud'
}

export function getSupportUrl({
  licenseIsCloud,
  licenseIsEnterpise,
  version,
  type = 'teamcity_cloud',
}: Props): string {
  const params = new URLSearchParams()
  if (licenseIsCloud) {
    params.set('ticket_form_id', '360003300299')
    params.set(
      type === 'teamcity_cloud' ? 'teamcity_cloud_url' : 'teamcity_pipelines_url',
      window.location.hostname,
    )
    params.set('product_type', type)
  } else {
    params.set('ticket_form_id', '66621')
    if (licenseIsEnterpise) {
      params.set('license_type', 'enterprise')
    }
    if (version != null && version.length > 0) {
      const shortVersion = version.split(' ').find(i => i.length > 0)
      if (shortVersion != null) {
        params.set('version', shortVersion)
      }
    }
  }
  return `${supportBaseUrl}?${params.toString()}`
}
