import type {CaseReducer, PayloadAction} from '@reduxjs/toolkit'

import type {PipelineDraftState} from './EditPipelinePage.slices.types'
import {getJob} from './EditPipelinePage.slices.utils'

export const setDependencies: CaseReducer<
  PipelineDraftState,
  PayloadAction<{
    id: string
    jobId: string
    dependencies: string[]
  }>
> = (state, action) => {
  const {id, jobId, dependencies} = action.payload
  const job = getJob(state, id, jobId)

  if (job != null) {
    const {dependencies: jobDependencies = []} = job
    job.dependencies = dependencies.map(dependency => {
      const dependencyWithFiles = jobDependencies.find(
        jobDependency => typeof jobDependency === 'object' && dependency in jobDependency,
      )

      return dependencyWithFiles || dependency
    })
  }
}

export const setFilesDependencies: CaseReducer<
  PipelineDraftState,
  PayloadAction<{
    id: string
    jobId: string
    dependencyId: string
    files?: readonly string[]
  }>
> = (state, action) => {
  const {id, jobId, dependencyId, files} = action.payload
  const job = getJob(state, id, jobId)

  if (job != null) {
    job.dependencies ??= []
    const dependencyIndex = job.dependencies.findIndex(dependency => {
      const isDependencyObject = typeof dependency === 'object' && dependency[dependencyId]

      return isDependencyObject || dependency === dependencyId
    })

    if (files?.length) {
      job.dependencies[dependencyIndex] = {
        [dependencyId]: {files},
      }
    } else {
      job.dependencies[dependencyIndex] = dependencyId
    }
  }
}
