import LoaderInline from '@jetbrains/ring-ui/components/loader-inline/loader-inline'
import {Suspense} from 'react'
import {graphql, useLazyLoadQuery} from 'react-relay'

import type {BuildId} from '../../../../types'
import BuildApprovalControls from '../BuildApprovalInline/BuildApprovalControls/BuildApprovalControls'
import BuildApprovalReviewers from '../BuildApprovalReviewers'

import type {BuildApprovalClassicQuery} from './__generated__/BuildApprovalClassicQuery.graphql'

const query = graphql`
  query BuildApprovalClassicQuery($locator: String!) {
    approvalInfo(buildLocator: $locator) {
      ...BuildApprovalReviewers_approvalInfo
      ...BuildApprovalControls_approvalInfo
    }
  }
`

type Props = {
  buildId: BuildId
}

function BuildApprovalClassic({buildId}: Props) {
  const {approvalInfo = null} = useLazyLoadQuery<BuildApprovalClassicQuery>(query, {
    locator: `id:${buildId}`,
  })

  return (
    <>
      <div>
        <BuildApprovalReviewers approvalInfoKey={approvalInfo} />
      </div>
      <div>
        <BuildApprovalControls approvalInfoKey={approvalInfo} buildId={buildId} />
      </div>
    </>
  )
}

export default function BuildApprovalClassicContainer(props: Props) {
  return (
    <Suspense
      fallback={
        <span>
          {'Loading'} <LoaderInline />
        </span>
      }
    >
      <BuildApprovalClassic {...props} />
    </Suspense>
  )
}
