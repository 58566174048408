import classNames from 'classnames'

import TransformText from '../../../containers/TransformText'
import {useDistanceToNow} from '../../../hooks/useTimeSelector'
import {useUserDisplayName} from '../../../hooks/useUserDisplayName'
import type {User} from '../../../services/rest'
import type {BuildId, BuildTypeId} from '../../../types'
import CommentPopup from '../CommentPopup/CommentPopup'
import IconButton from '../IconButton/IconButton'

import styles from './BuildCommentByIcon.css'

export type BuildCommentByIconProps = {
  text: string | null | undefined
  timestamp?: string | null | undefined
  user?: User | undefined
  className?: string
  inline?: boolean
  buildTypeId?: BuildTypeId | null | undefined
  buildId?: BuildId
}

function BuildCommentByIcon({
  text,
  timestamp,
  user,
  className,
  inline,
  buildTypeId,
}: BuildCommentByIconProps) {
  const userDisplayName = useUserDisplayName(user, true)
  const wrapperClasses = classNames(styles.wrapper, className)
  const timestampFromNow = useDistanceToNow(timestamp)
  if (text == null) {
    return null
  }

  const anchor = (
    <IconButton icon="comment" title="" aria-label="Comment" className={styles.button} />
  )
  return (
    <div className={wrapperClasses}>
      {inline !== true && (
        <CommentPopup
          anchor={anchor}
          timestamp={timestamp}
          user={userDisplayName}
          text={text}
          buildTypeId={buildTypeId}
        />
      )}
      {inline === true && (
        <TransformText
          className={styles.text}
          text={text}
          buildTypeId={buildTypeId}
          lines={3}
          lineHeight={16}
        />
      )}
      {inline === true && userDisplayName != null && timestampFromNow != null && (
        <span className={styles.info}>
          <span className={styles.user}>{userDisplayName}</span>
          <span className={styles.inlineCommentText}>{`${timestampFromNow}`}</span>
        </span>
      )}
    </div>
  )
}

export default BuildCommentByIcon
