import ArtifactsIcon from '@jetbrains/icons/artifacts.svg'
import NoArtifactsIcon from '@jetbrains/icons/no-artifacts.svg'
import {H3} from '@jetbrains/ring-ui/components/heading/heading'
import {Directions} from '@jetbrains/ring-ui/components/popup/popup.consts'
import * as React from 'react'
import {useCallback} from 'react'

import BuildArtifactsTree from '../BuildArtifactsTree/BuildArtifactsTree'
import IconButton from '../IconButton/IconButton'
import Link from '../Link/Link'

import type {PopupContentProps, Props} from './BuildArtifacts.types'
import BuildArtifactsPopup from './BuildArtifactsPopup'

import styles from './BuildArtifacts.css'

const handleAnchorClick = (isPlainLeftClick: boolean, e: React.SyntheticEvent<any>) => {
  if (isPlainLeftClick) {
    e.preventDefault()
  } else {
    e.stopPropagation()
  }
}
function BuildArtifacts({
  className,
  hasArtifacts,
  buildId,
  href,
  popupOffset,
  popupDirection = [Directions.LEFT_BOTTOM, Directions.LEFT_TOP],
  isLoading,
  isVisible,
  currentZindex,
  wrapperProps,
}: Props) {
  const renderContent = useCallback(
    ({noArtifacts, labelId, onReady}: PopupContentProps) => (
      <>
        {
          <H3 id={labelId} className={styles.title}>
            {href != null ? <Link href={href}>{'Artifacts'}</Link> : 'Artifacts'}
          </H3>
        }
        <BuildArtifactsTree
          showToggleHidden={noArtifacts}
          buildId={buildId}
          labelledBy={labelId}
          onReady={onReady}
          showDownloadLink
          isPopup
        />
      </>
    ),
    [buildId, href],
  )
  const anchor = React.useMemo(() => {
    const clickProps =
      href != null
        ? {
            onConditionalClick: handleAnchorClick,
          }
        : Object.freeze({})
    return (
      <IconButton
        className={className}
        title="Artifacts"
        href={href}
        target="_blank"
        rel="noreferrer"
        primary={hasArtifacts === true}
        loader={isVisible === true && isLoading}
        icon={hasArtifacts === false ? NoArtifactsIcon : ArtifactsIcon}
        {...clickProps}
      />
    )
  }, [className, hasArtifacts, href, isLoading, isVisible])

  return (
    <BuildArtifactsPopup
      hasArtifacts={hasArtifacts}
      popupOffset={popupOffset}
      popupDirection={popupDirection}
      currentZindex={currentZindex}
      wrapperProps={wrapperProps}
      anchor={anchor}
    >
      {renderContent}
    </BuildArtifactsPopup>
  )
}
export default React.memo(BuildArtifacts)
