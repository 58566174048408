import type {Graph} from 'd3-dag'
import {graphStratify, sugiyama} from 'd3-dag'
import type {GraphNode as DagNode} from 'd3-dag/dist/graph'

export type GraphNode<T = unknown> = {
  id: string
  dependencies: Set<string>
  optimizedDependencies: Set<string> // if a direct dependency duplicates a transitive one, it's removed here
  dependants: Set<string>
  predecessors: Set<string>
  successors: Set<string>
  data: T
}

type CreateGraphArg<T> = {
  data: readonly T[]
  getId: (item: T) => string
  getDependencies: (item: T) => readonly string[]
}

export function createGraph<T>({data, getId, getDependencies}: CreateGraphArg<T>) {
  const result: Record<string, GraphNode<T>> = {}
  for (const item of data) {
    const id = getId(item)
    const deps = getDependencies(item).filter(dep =>
      data.some(otherItem => getId(otherItem) === dep),
    )
    result[id] = {
      id,
      dependencies: new Set(deps),
      optimizedDependencies: new Set(deps),
      dependants: new Set(),
      predecessors: new Set(),
      successors: new Set(),
      data: item,
    }
  }
  function markAsSuccessor(id: string, successorId: string, recursionDepth = 0) {
    if (result[id] != null && !result[id].successors.has(successorId)) {
      result[successorId].predecessors.add(id)
      result[id].successors.add(successorId)
      result[id].dependencies.forEach(depId =>
        markAsSuccessor(depId, successorId, recursionDepth + 1),
      )
    }
    if (recursionDepth > 0) {
      result[successorId]?.optimizedDependencies.delete(id)
    }
  }
  for (const item of data) {
    const id = getId(item)
    const deps = getDependencies(item)
    deps.forEach(dep => {
      result[dep]?.dependants.add(id)
      markAsSuccessor(dep, id)
    })
  }

  return result
}

const createDag = (showTransitiveEdges?: boolean) =>
  graphStratify().parentIds(({dependencies, optimizedDependencies}: GraphNode) => [
    ...(showTransitiveEdges ? dependencies : optimizedDependencies),
  ])
const getLayout = <T>(dimensions: [number, number] | ((node: GraphNode<T>) => [number, number])) =>
  sugiyama().nodeSize((n: DagNode<GraphNode<T>> | undefined) =>
    n === undefined ? [0, 0] : typeof dimensions === 'function' ? dimensions(n.data) : dimensions,
  )

export type Layout<T> = {
  width: number
  height: number
  dag: Graph<GraphNode<T>> | null
}

const emptyLayout: Layout<never> = {
  width: 0,
  height: 0,
  dag: null,
}

export function createLayout<T = unknown>(
  graph: Record<string, GraphNode<T>>,
  dimensions: [number, number] | ((node: GraphNode<T>) => [number, number]),
  showTransitiveEdges?: boolean,
): Layout<T> {
  const data = Object.values(graph)
  if (data.length === 0) {
    return emptyLayout
  }
  const dag = createDag(showTransitiveEdges)(data)
  const {width, height} = getLayout(dimensions)(dag)
  return {width, height, dag}
}
