import type * as React from 'react'

import type {AppDispatch} from '../../../actions/types'
import type {UpdateResultProps} from '../../../contexts/update'
import type {LocationProps} from '../../../routes'

export enum PagerGroup {
  BUILD = 'build',
  TEST = 'test',
  CHANGE = 'change',
  CHANGE_LOG = 'changeLog',
  TEST_PROBLEM = 'testProblem',
}
type OwnProps = {
  warningClassName?: string
  group: PagerGroup
  warningComponent?: React.ComponentType<any> | string
  isLoading?: boolean | null | undefined
  hasError?: boolean | null | undefined
}
export type PagerHelperProps = OwnProps &
  LocationProps &
  UpdateResultProps & {dispatch: AppDispatch}
export type ReduxOwnProps = OwnProps & {
  [K in PropertyKey]?: number
}
export type StateProps = {
  currentPage: number
  openTotal: boolean
  pageSize: number
  show?: boolean
  total: number
}
type ActionProps = {
  changeCurrentPage: (page: number) => unknown
}
export type Props = OwnProps & StateProps & ActionProps
export type Pager = {
  hasMore?: boolean
  isClientFilter?: boolean
  loadedLessThanRequested: boolean | null | undefined
  lookupDelta?: number
  lookupLimit?: number
  lookupLimitWarning?: boolean | null | undefined
  openTotal: boolean
  pageSize: number
  precountedPages?: number
  total: number
  isLoading?: boolean
}
