import * as React from 'react'

const params = new URLSearchParams(location.search)

if (params.has('why-update') && process.env.NODE_ENV !== 'production') {
  import(
    /* webpackChunkName: "WhyDidYouRender", webpackPrefetch: true */
    '@hypnosphi/why-did-you-render'
  ).then(whyDidYouRender =>
    whyDidYouRender.default(React, {
      include: [/.*/],
      exclude: [/^Location$/],
      logOnDifferentValues: params.has('log-all'),
      logOwnerReasons: true,
    }),
  )
}
