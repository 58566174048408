import alertService from '@jetbrains/ring-ui/components/alert-service/alert-service'
import deepEqual from 'fast-deep-equal'
import {createElement, useCallback, useEffect, useMemo, useState} from 'react'
import {shallowEqual} from 'react-redux'
import {graphql, useFragment} from 'react-relay'
import {useLocation, useSearchParams} from 'react-router-dom'

import {useAppDispatch, useAppSelector, useAppStore} from '../../../../hooks/react-redux'
import {useRefetchableLazyLoadQuery} from '../../../../hooks/relay/useRefetchableLazyLoadQuery'
import {mergeIfDifferent, mergeIfDifferentAndPrune} from '../../../../reducers/utils'
import {getBranchLocator} from '../../../../rest/locators'
import {restApi} from '../../../../services/rest'
import {defaultBranch} from '../../../../utils/branchNames'
import {copyClipboard} from '../../../../utils/clipboard'
import {emptyArray as frozenEmptyArray} from '../../../../utils/empty'
import {subscribeOnProjectEvents} from '../../../../utils/subscriber'
import {
  BUILD_FINISHED,
  BUILD_INTERRUPTED,
  BUILD_STARTED,
  BUILD_TYPE_ADDED_TO_QUEUE,
  BUILD_TYPE_REMOVED_FROM_QUEUE,
} from '../../../../utils/subscriptionEvents'
import {useBanners} from '../contexts/Banners/BannersContext'
import {VALIDATION_ERRROR_MESSAGE} from '../contexts/Banners/BannersContext.types'
import type {pipelineHeadFragment$key} from '../hooks/__generated__/pipelineHeadFragment.graphql'
import {useJobIdState, usePipelineHeadId, usePipelineId} from '../hooks/pipeline'
import {useVersionedSettingsStatus} from '../hooks/useVersionedSettings'
import {pipelinesApi} from '../services/pipelinesApi'
import type {VersionedSettingsDto} from '../services/pipelinesApi.types'
import type {Job} from '../types'
import {isStoreYamlInVcsEnabled} from '../utils/featureToggles'
import type {GraphNode} from '../utils/graph'
import {createGraph} from '../utils/graph'

import type {EditPipelinePageDependencyFragment$key} from './__generated__/EditPipelinePageDependencyFragment.graphql'
import type {EditPipelinePageLastRunQuery} from './__generated__/EditPipelinePageLastRunQuery.graphql'
import {
  getDraft,
  getDraftJobs,
  getJobName,
  getNewSettings,
  getNewYaml,
  getResolvedSettings,
  getStringifiedDependencies,
  getYaml,
  getIsPipelineValid,
  makePipelineFormErrorsSelector,
  makeTotalCountPipelineErrorsSelector,
  isDraftChanged,
} from './EditPipelinePage.selectors'
import {getExtractErrorMessage} from './EditPipelinePage.utils'
import PipelineAlertServiceError from './PipelineAlertServiceError/PipelineWrapperAlertMessage'
import {
  pipelineDraft,
  pipelineDraftForm,
  pipelineYaml,
  pipelineYamlValidity,
} from './slices/EditPipelinePage.slices'

export const useJobId = (): string | null => {
  const [searchParams] = useSearchParams()
  return searchParams.get('job')
}

export const usePipelineNameOrDraft = () => {
  const id = usePipelineId()
  return useAppSelector(state => getDraft(state, id)?.settings.name) ?? ''
}

export const usePipelineIntegrationsOrDraft = () => {
  const id = usePipelineId()
  return useAppSelector(state => getDraft(state, id)?.integrations)
}

export const usePipelineAdditionalVcsRootsOrDraft = () => {
  const id = usePipelineId()
  return useAppSelector(state => getDraft(state, id)?.additionalVcsRoots) ?? frozenEmptyArray
}

const useExistingJobs = () => {
  const id = usePipelineId()
  const {existing} = pipelinesApi.endpoints.getPipelineById.useQuery(id, {
    selectFromResult: ({data}) => ({existing: data?.settings.jobs}),
  })
  return existing
}

const emptyArray: never[] = []
export const usePipelineJobIdsOrDraft = (): ReadonlyArray<string> => {
  const id = usePipelineId()
  return useAppSelector(state => Object.keys(getDraftJobs(state, id) ?? {}), shallowEqual)
}
export const useNonDeletedPipelineJobIdsOrDraft = (): ReadonlyArray<string> => {
  const jobIds = usePipelineJobIdsOrDraft()
  const id = usePipelineId()
  const deleted = useAppSelector(state => state.pipelines.pipelineDraft[id]?.deleted?.jobs)

  return useMemo(() => jobIds.filter(jobId => !deleted?.includes(jobId)), [jobIds, deleted])
}

export const usePipelineExistingJobIdsSet = (): Set<string> => {
  const existing = useExistingJobs()
  return useMemo(() => new Set(existing != null ? Object.keys(existing) : emptyArray), [existing])
}

export const usePipelineExistingJob = (jobId: string): Job | undefined => {
  const existingJobs = useExistingJobs()

  return existingJobs?.[jobId]
}

export const useDoesJobExist = (jobId: string) => {
  const existing = usePipelineExistingJob(jobId)
  return existing != null
}

export const usePipelineGraph = (stringifiedDependencies: string): Record<string, GraphNode> =>
  useMemo(() => {
    const dependencies = JSON.parse(stringifiedDependencies)
    return createGraph({
      data: Object.keys(dependencies),
      getId: id => id,
      getDependencies: id => dependencies[id],
    })
  }, [stringifiedDependencies])

export const usePipelineDraftGraph = (): Record<string, GraphNode> => {
  const id = usePipelineId()
  const stringifiedDependencies = useAppSelector(state =>
    getStringifiedDependencies(getDraftJobs(state, id)),
  )
  return usePipelineGraph(stringifiedDependencies)
}

const GENERATOR_MAX_ITERATIONS = 1000
export const useGenerateFreeJobName = () => {
  const pipelineId = usePipelineId()
  const usedNames = useAppSelector(
    state =>
      Object.entries(getDraftJobs(state, pipelineId) ?? {}).flatMap(([jobId, job]) => [
        job.name ?? jobId,
        getJobName(state, pipelineId, jobId),
      ]),
    shallowEqual,
  )

  return useCallback((): string => {
    let id
    let index = 0

    while (index <= GENERATOR_MAX_ITERATIONS) {
      index++
      id = `Job ${index}`
      if (!usedNames.includes(id)) {
        return id
      }
    }

    throw new Error(`Error while generating new job name`)
  }, [usedNames])
}

export const useGenerateFreeJobId = () => {
  const pipelineId = usePipelineId()
  const usedIds = useAppSelector(
    state => Object.keys(getDraftJobs(state, pipelineId) ?? {}),
    shallowEqual,
  )

  const [generateId] = restApi.endpoints.generateId.useMutation()

  return useCallback(
    async (name: string): Promise<string> => {
      const generatedId = await generateId({
        object: 'buildType',
        name,
      }).unwrap()

      let id
      let index = 0
      while (index <= GENERATOR_MAX_ITERATIONS) {
        index++
        id = index === 1 ? generatedId : `${generatedId}_${index}`
        if (!usedIds.includes(id)) {
          return id
        }
      }

      throw new Error(`Error while generating new job id`)
    },
    [generateId, usedIds],
  )
}

export const useIsJobDeleted = (jobId: string) => {
  const id = usePipelineId()
  return useAppSelector(
    state => state.pipelines.pipelineDraft[id]?.deleted?.jobs?.includes(jobId) ?? false,
  )
}

function useLastRunLocator(pipelineHeadKey: pipelineHeadFragment$key | null) {
  const headId = usePipelineHeadId(pipelineHeadKey)
  const baseLocator = [
    `count:1,buildType:(id:${headId}),state:finished`,
    getBranchLocator(defaultBranch, true),
  ]
    .filter(Boolean)
    .join(',')
  return `item(defaultFilter:false,${baseLocator}),item(${baseLocator})`
}

const dependencyFragment = graphql`
  fragment EditPipelinePageDependencyFragment on Build {
    snapshotDependencies {
      build {
        ...EditPipelineJobLastRunFragment
        ...JobStepLastRunFragment
        ...JobDirectoryPopupLastRunFragment
        buildType {
          name
        }
        canceledInfo {
          timestamp
        }
      }
    }
  }
`
const useDependency = (buildKey: EditPipelinePageDependencyFragment$key | null, dep: string) => {
  const build = useFragment(dependencyFragment, buildKey)
  return build?.snapshotDependencies?.build?.find(item => item.buildType?.name === dep) ?? null
}

const lastRunQuery = graphql`
  query EditPipelinePageLastRunQuery($locator: String!) {
    builds(locator: $locator) {
      build {
        ...EditPipelinePageDependencyFragment
      }
    }
  }
`
export function useLastRun(id: string, pipelineHeadKey: pipelineHeadFragment$key | null) {
  const locator = useLastRunLocator(pipelineHeadKey)
  const [{builds}, refetch] = useRefetchableLazyLoadQuery<EditPipelinePageLastRunQuery>(
    lastRunQuery,
    {locator},
  )
  const pipelineId = usePipelineId()
  const name = useAppSelector(state => getJobName(state, pipelineId, id))
  const lastRun = useDependency(builds?.build?.[0] ?? null, name)
  const lastFinishedRun = useDependency(builds?.build?.[1] ?? null, name)

  useEffect(
    () =>
      pipelineId != null
        ? subscribeOnProjectEvents(
            pipelineId,
            [
              BUILD_TYPE_ADDED_TO_QUEUE,
              BUILD_TYPE_REMOVED_FROM_QUEUE,
              BUILD_STARTED,
              BUILD_FINISHED,
              BUILD_INTERRUPTED,
            ],
            () => refetch,
          )
        : undefined,
    [pipelineId, refetch],
  )

  if (lastRun == null || lastRun.canceledInfo != null) {
    return lastFinishedRun
  }
  return lastRun
}

export const useEditPipeline = () => {
  const dispatch = useAppDispatch()
  const pipelineId = usePipelineId()
  const [jobId, setJobId] = useJobIdState()
  const hasChangesDraft = useAppSelector(state =>
    isDraftChanged(state.pipelines.pipelineDraft[pipelineId]),
  )
  const hasChangesOriginal = useAppSelector(state => {
    const updated = getResolvedSettings(state, pipelineId)
    const original = state.pipelines.pipelineDraft[pipelineId]?.original
    return updated != null && !deepEqual(updated, original)
  })
  const hasChanges = hasChangesDraft || hasChangesOriginal
  const [updatePipeline, {isLoading, error}] = pipelinesApi.endpoints.updatePipeline.useMutation({
    fixedCacheKey: 'shared-save-pipeline',
  })

  useEffect(() => {
    if (error != null) {
      const errorMessage = getExtractErrorMessage(error)

      alertService.error(createElement(PipelineAlertServiceError, null, errorMessage))
    }
  }, [error])

  const selector = useMemo(() => makeTotalCountPipelineErrorsSelector(), [])
  const {getState} = useAppStore()
  const handlerSaveButtonClick = async () => {
    dispatch(pipelineDraftForm.actions.submit())
    const state = getState()
    const totalCountErrors = selector(state, {
      pipelineId,
    })

    if (totalCountErrors > 0) {
      return false
    }

    const updated = getResolvedSettings(state, pipelineId)

    if (hasChangesDraft && !hasChangesOriginal) {
      dispatch(pipelineDraft.actions.reset(pipelineId))

      const pipelineOriginal = state.pipelines.pipelineDraft[pipelineId]?.original

      if (jobId != null && pipelineOriginal?.settings.jobs?.[jobId] == null) {
        setJobId(null)
      }
    } else if (updated != null) {
      const result = await updatePipeline({id: pipelineId, body: updated})
      if ('data' in result && result.data != null) {
        if (jobId != null && result.data.settings.jobs?.[jobId] == null) {
          setJobId(null)
        }
        dispatch(pipelineDraft.actions.set(result.data))
      }
    }

    return true
  }

  return {
    hasChanges,
    isLoading,
    handlerSaveButtonClick,
  }
}
const ALERT_TIMEOUT = 2000

export function useApplyYAMLChangesHandler() {
  const pipelineId = usePipelineId()
  const dispatch = useAppDispatch()
  const {getState} = useAppStore()
  return () => {
    const state = getState()
    const newSettings = getNewSettings(state, pipelineId)
    const pipelineDraftState = getDraft(state, pipelineId)

    if (pipelineDraftState !== undefined) {
      dispatch(
        pipelineDraft.actions.setDraft({
          id: pipelineId,
          draft: {
            ...pipelineDraftState,
            settings: mergeIfDifferentAndPrune(pipelineDraftState.settings, newSettings),
          },
        }),
      )
    }
  }
}

export function useIsEditPipelineYAMLValid() {
  const pipelineId = usePipelineId()
  return useAppSelector(state => getIsPipelineValid(state, {pipelineId}))
}

export function useSaveYAMLChangesHandler(): [
  () => Promise<boolean>,
  ReturnType<typeof pipelinesApi.endpoints.updatePipeline.useMutation>[1],
] {
  const dispatch = useAppDispatch()
  const [updatePipeline, updatePipelineResult] = pipelinesApi.endpoints.updatePipeline.useMutation({
    fixedCacheKey: 'shared-save-yaml',
  })
  const pipelineId = usePipelineId()
  const {getState} = useAppStore()
  return [
    async () => {
      const state = getState()
      const newSettings = getNewSettings(state, pipelineId)
      const pipelineDraftState = getDraft(state, pipelineId)

      if (pipelineDraftState !== undefined) {
        const result = await updatePipeline({
          id: pipelineId,
          body: {
            ...pipelineDraftState,
            settings: mergeIfDifferent(pipelineDraftState.settings, newSettings),
          },
        })
        if ('data' in result && result.data != null) {
          dispatch(pipelineDraft.actions.set(result.data))
        }

        return true
      }
      return false
    },
    updatePipelineResult,
  ]
}

export function useYAMLHasChanges() {
  const pipelineId = usePipelineId()
  return useAppSelector(
    state => getYaml(state, pipelineId)?.trim() !== getNewYaml(state, pipelineId)?.trim(),
  )
}

export function useEditPipelineYAML() {
  const dispatch = useAppDispatch()
  const pipelineId = usePipelineId()
  const yaml = useAppSelector(state => getYaml(state, pipelineId))
  const isValid = useIsEditPipelineYAMLValid()
  const hasChanges = useYAMLHasChanges()

  const applyChangesHandler = useApplyYAMLChangesHandler()
  const [saveChangesHandler, {isLoading, error}] = useSaveYAMLChangesHandler()
  const {getState} = useAppStore()
  const {removeBanner} = useBanners()

  const copyToClipboardHandler = () =>
    copyClipboard(getNewYaml(getState(), pipelineId)).then(() =>
      alertService.message('Copied to clipboard', ALERT_TIMEOUT),
    )

  const yamlEditorChangeHandler = (value: string) =>
    dispatch(pipelineYaml.actions.setYaml({id: pipelineId, yaml: value}))

  const yamlEditorValidateHandler = (isPipelineValid: boolean) => {
    if (isPipelineValid && hasChanges) {
      applyChangesHandler()
    }
  }
  useEffect(() => {
    if (isStoreYamlInVcsEnabled && hasChanges) {
      dispatch(pipelineYamlValidity.actions.setParsed({id: pipelineId, value: true}))
      removeBanner(pipelineId, `${pipelineId}-${VALIDATION_ERRROR_MESSAGE}`)
    }
  }, [dispatch, hasChanges, pipelineId, removeBanner])

  useEffect(() => {
    if (error != null) {
      const errorMessage = getExtractErrorMessage(error)

      alertService.error(createElement(PipelineAlertServiceError, null, errorMessage))
    }
  }, [error])

  return {
    yaml,
    isValid,
    hasChanges,
    isLoading,
    yamlEditorChangeHandler,
    yamlEditorValidateHandler,
    applyChangesHandler,
    saveChangesHandler,
    copyToClipboardHandler,
  }
}

export const useDraftStepError = (props: {stepIndex: number; name: string}) => {
  const {stepIndex, name} = props
  const jobId = useJobId()!
  const pipelineId = usePipelineId()
  const selector = useMemo(() => makePipelineFormErrorsSelector(), [])

  return useAppSelector(state => selector(state, {pipelineId})?.[jobId]?.[stepIndex]?.[name]) ?? []
}

export const useHasNonDeletedDependants = (jobId: string) => {
  const pipelineId = usePipelineId()

  const graph = usePipelineDraftGraph()
  const deleted = useAppSelector(state => state.pipelines.pipelineDraft[pipelineId]?.deleted?.jobs)
  return (
    graph[jobId] != null &&
    Array.from(graph[jobId].successors).some(successorId => !deleted?.includes(successorId))
  )
}

export const useConfigurationStorage = () => {
  const id = usePipelineId()

  const location = useLocation()
  const isCreated = location?.state?.isCreated ?? false

  const pipelineDraftState = useAppSelector(state => getDraft(state, id))
  const vcsRoot = pipelineDraftState?.vcsRoot

  const [dialogVisible, setDialogVisible] = useState(false)

  const [checkVersionedSettings, {hasFileInRepo}] =
    pipelinesApi.endpoints.checkVersionedSettings.useLazyQuery({
      selectFromResult: ({data}) => ({hasFileInRepo: data ?? false}),
    })

  useVersionedSettingsStatus()
  const [updatePipeline, {isLoading, error}] = pipelinesApi.endpoints.updatePipeline.useMutation()

  const onConfigurationStorageChange = useCallback(
    async (settings: VersionedSettingsDto) => {
      if (pipelineDraftState) {
        const data = await updatePipeline({
          id,
          body: {
            ...pipelineDraftState,
            versionedSettings: settings,
          },
        })
        setDialogVisible(false)
        history.replaceState({}, '')
        if (!data.error) {
          window.location.reload()
        }
      }
    },
    [id, pipelineDraftState, updatePipeline],
  )

  useEffect(() => {
    if (error != null) {
      const errorMessage = getExtractErrorMessage(error)
      alertService.error(createElement(PipelineAlertServiceError, null, errorMessage))
    }
  }, [error])

  useEffect(() => {
    if (isCreated && vcsRoot?.url) {
      checkVersionedSettings({vcsRoot})
    }
  }, [checkVersionedSettings, isCreated, vcsRoot])

  useEffect(() => {
    if (isCreated) {
      setDialogVisible(hasFileInRepo)
    }
  }, [hasFileInRepo, isCreated])

  return {
    dialogVisible,
    isLoading,
    onConfigurationStorageChange,
  }
}
