import Button from '@jetbrains/ring-ui/components/button/button'
import classNames from 'classnames'
import {graphql, useFragment} from 'react-relay'

import type {BuildId} from '../../../../../types'
import Popup from '../../../Popup/Popup.lazy'
import {useBuildApprove} from '../../BuildApproval.hooks'
import BuildApprovalReviewers from '../../BuildApprovalReviewers'
import styles from '../BuildApprovalDetailsPopup.css'

import type {BuildApprovalDetailsPopupContent_approvalInfo$key} from './__generated__/BuildApprovalDetailsPopupContent_approvalInfo.graphql'

const fragment = graphql`
  fragment BuildApprovalDetailsPopupContent_approvalInfo on ApprovalInfo {
    canBeApprovedByCurrentUser
    ...BuildApprovalReviewers_approvalInfo
  }
`

export type OwnProps = {
  buildId: BuildId
  approvalInfoKey: BuildApprovalDetailsPopupContent_approvalInfo$key | null
}

export default function BuildApprovalDetailsPopupContent({
  buildId,
  approvalInfoKey,
  ...props
}: OwnProps) {
  const approvalInfo = useFragment(fragment, approvalInfoKey)
  const {approve} = useBuildApprove(buildId)

  return (
    <Popup
      className={classNames(
        styles.popup,
        approvalInfo?.canBeApprovedByCurrentUser && styles.withControls,
      )}
      {...props}
    >
      <div className={styles.popupContent}>
        <div>
          <BuildApprovalReviewers approvalInfoKey={approvalInfo} />
        </div>
        {approvalInfo?.canBeApprovedByCurrentUser ? (
          <Button className={styles.approve} onClick={approve}>
            {'Approve'}
          </Button>
        ) : null}
      </div>
    </Popup>
  )
}
