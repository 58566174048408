import {useMemo} from 'react'

import {useAppSelector} from '../hooks/react-redux'
import {getBuild} from '../selectors'
import type {BuildId} from '../types'

const MIN_OVERTIME_PERCENT = 0.01
export const getBuildOvertimeInfo = (
  progressInfo:
    | {
        elapsedSeconds?: number | null
        estimatedTotalSeconds?: number | null
      }
    | undefined
    | null,
) => {
  if (progressInfo == null) {
    return {
      seconds: 0,
      isOvertime: false,
    }
  }

  const elapsedSeconds = progressInfo.elapsedSeconds ?? 0
  const estimatedTotalSeconds = progressInfo.estimatedTotalSeconds ?? 0
  const overtimedSeconds = estimatedTotalSeconds > 0 ? elapsedSeconds - estimatedTotalSeconds : 0

  return {
    seconds: overtimedSeconds,
    isOvertime: overtimedSeconds > elapsedSeconds * MIN_OVERTIME_PERCENT,
  }
}
export const useBuildOvertimeInfo = (buildId: BuildId | undefined | null) => {
  const build = useAppSelector(state => getBuild(state, buildId))
  return useMemo(() => getBuildOvertimeInfo(build?.['running-info']), [build])
}
