import Button from '@jetbrains/ring-ui/components/button/button'
import {graphql, useFragment} from 'react-relay'

import type {BuildId} from '../../../../../types'
import {useBuildApprove, useTimeoutInfo} from '../../BuildApproval.hooks'

import type {BuildApprovalControls_approvalInfo$key} from './__generated__/BuildApprovalControls_approvalInfo.graphql'
import BuildApprovalStatus from './BuildApprovalStatus/BuildApprovalStatus'

import styles from './BuildApprovalControls.css'

const fragment = graphql`
  fragment BuildApprovalControls_approvalInfo on ApprovalInfo {
    canBeApprovedByCurrentUser
    status
    timeoutTimestamp
  }
`

type OwnProps = {
  approvalInfoKey: BuildApprovalControls_approvalInfo$key | null
  buildId: BuildId
}

export default function BuildApprovalControls({approvalInfoKey, buildId}: OwnProps) {
  const approvalInfo = useFragment(fragment, approvalInfoKey)
  const secondsLeft = useTimeoutInfo(approvalInfo?.timeoutTimestamp || null)
  const {approve} = useBuildApprove(buildId)

  const shouldRenderApproveButton = approvalInfo?.canBeApprovedByCurrentUser && !!secondsLeft

  return (
    <>
      {shouldRenderApproveButton ? (
        <Button className={styles.approveButton} onClick={approve}>
          {'Approve'}
        </Button>
      ) : null}
      {approvalInfo?.timeoutTimestamp ? (
        <BuildApprovalStatus timeout={approvalInfo.timeoutTimestamp} status={approvalInfo.status} />
      ) : null}
    </>
  )
}
