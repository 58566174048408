const isProjectsAndBuildTypesEnabled =
  window.internalProps['teamcity.pipelines.projects.enabled'] !== false
export const isPipelinesEnabled = window.internalProps['teamcity.pipelines.enabled']
export const isPipelinesEnabledInExclusiveMode =
  isPipelinesEnabled && !isProjectsAndBuildTypesEnabled
export const areFeaturesWithoutBackendEnabled =
  window.internalProps['teamcity.pipelines.featuresWaitingForBackend']
export const isDockerAutocompleteEnabled =
  window.internalProps['teamcity.docker.dockerhub.autocomplete']
export const isPipelinesDockerfileEnabled =
  window.internalProps['teamcity.pipelines.dockerfile.support']
export const isUnityRunnedEnabled = window.internalProps['teamcity.pipelines.unity.enabled']
export const parallelTestsEnabled = window.internalProps['teamcity.pipelines.parallelTests.enabled']
export const customizeJobReuseEnabled =
  window.internalProps['teamcity.pipelines.feature.jobReuseCustomization.enabled']
export const isNewTimeBadgeEnabled = window.internalProps['teamcity.pipelines.newTimeBadge.enabled']
export const isFilePickersEnabled =
  window.internalProps['teamcity.pipelines.filePickers.enabled'] === true
export const isNewArtifactField =
  window.internalProps['teamcity.pipelines.newArtifactField.enabled'] === true
export const isLineEditorEnabled =
  window.internalProps['teamcity.pipelines.lineEditor.enabled'] === true
export const isSpaceConnectionEnabled =
  window.internalProps['teamcity.pipelines.spaceConnection.enabled']
export const dependencyCacheEnabled =
  window.internalProps['teamcity.pipelines.dependencyCache.enabled']
export const isStoreYamlInVcsEnabled =
  window.internalProps['teamcity.pipelines.feature.storeYAMLinVCS']
