import {skipToken} from '@reduxjs/toolkit/query'

import {restApi} from '../../../../../services/rest'
import {resolveRelative} from '../../../../../utils/url'
import IconButton from '../../../IconButton/IconButton'
import styles from '../FileTreeNode.css'

type Props = {
  endpoint: string
  buildId?: number
  path: string
}

function FileTreeNodeExtension({endpoint, buildId, path}: Props) {
  const {icon, title, href} = restApi.endpoints.getArtifactExtension.useQuery(
    buildId != null ? {buildId, endpoint} : skipToken,
    {
      selectFromResult: ({data}) => {
        const {icon, title, hrefs} = data ?? {}
        return {icon: icon?.name, title, href: hrefs?.[path]}
      },
    },
  )

  return icon != null && href != null ? (
    <IconButton
      onClick={stop}
      className={styles.extensionIcon}
      icon={icon}
      title={title}
      href={resolveRelative(href)}
      target="_blank"
      rel="noreferrer"
    />
  ) : null
}

export default FileTreeNodeExtension
