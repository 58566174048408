import {graphql} from 'react-relay'

export const snapshotDependenciesCountersQuery = graphql`
  query SnapshotDependenciesCountersQuery($locator: String!) {
    builds(locator: $locator) {
      count
      ...SnapshotDependenciesCountersFragment
      ...BuildProblemsPreviewDependencyIdsFragment
    }
  }
`

export const getSnapshotDependenciesCountersVariables = (buildLocator: string) => ({
  locator: `defaultFilter:false,snapshotDependency(to:(${buildLocator})),count:-1`,
})
