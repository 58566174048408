/**
 * @generated SignedSource<<4b81ce3d5c45ca3e5fe2da0c3a4d83e1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type JobAgentRequirementsCloudImagesQuery$variables = Record<PropertyKey, never>;
export type JobAgentRequirementsCloudImagesQuery$data = {
  readonly cloudImages: {
    readonly cloudImage: ReadonlyArray<{
      readonly name: string | null | undefined;
    }> | null | undefined;
  } | null | undefined;
};
export type JobAgentRequirementsCloudImagesQuery = {
  response: JobAgentRequirementsCloudImagesQuery$data;
  variables: JobAgentRequirementsCloudImagesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "JobAgentRequirementsCloudImagesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CloudImages",
        "kind": "LinkedField",
        "name": "cloudImages",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CloudImageRest",
            "kind": "LinkedField",
            "name": "cloudImage",
            "plural": true,
            "selections": [
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "JobAgentRequirementsCloudImagesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CloudImages",
        "kind": "LinkedField",
        "name": "cloudImages",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CloudImageRest",
            "kind": "LinkedField",
            "name": "cloudImage",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "814aa270fd4e9be8ee4cc72beb0c646b",
    "id": null,
    "metadata": {},
    "name": "JobAgentRequirementsCloudImagesQuery",
    "operationKind": "query",
    "text": "query JobAgentRequirementsCloudImagesQuery {\n  cloudImages {\n    cloudImage {\n      name\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7f7cc6cd643e1163b76eb8015a43b06e";

export default node;
