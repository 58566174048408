import type {Branch} from '../services/rest'
import type {
  BuildId,
  BuildTypeId,
  LocatorOptions,
  ProjectOrBuildTypeNode,
  SelectedBranch,
  BuildState,
} from '../types'
import {stateLocators, stringifyId} from '../types'
import escapeLocatorDimension from '../utils/escapeLocatorDimension'

export const getBranchLocatorPart = (
  branch?: SelectedBranch | null | undefined,
  inPath?: boolean,
): string | null | undefined => {
  if (branch == null || branch.wildcard === true) {
    return 'default:any'
  }

  if (branch.default === true) {
    return 'default:true'
  }

  if (branch.groupFlag === true && branch.internalName != null) {
    return `group:(${branch.internalName})`
  }

  if (branch.name && branch.name.trim().length > 0) {
    return `name:(matchType:equals,value:${escapeLocatorDimension(branch.name, inPath)})`
  }

  return null
}
export const getBranchLocator = (
  branch?: SelectedBranch | null | undefined,
  inPath?: boolean,
): string => {
  const branchLocatorPart = getBranchLocatorPart(branch, inPath)

  if (branchLocatorPart == null) {
    return ''
  }

  if (
    branch == null ||
    branch.wildcard === true ||
    branch.default === true ||
    branch.groupFlag === true
  ) {
    return `branch:(${branchLocatorPart})`
  }

  return `branch:(policy:ALL_BRANCHES,${branchLocatorPart})`
}
export const getPagerLocator = (page: number, pageSize: number): string =>
  `count:${pageSize},start:${(page - 1) * pageSize}`
export const getBuildTypeAndBranchLocator = (
  buildTypeId: BuildTypeId | null | undefined,
  branch: Branch | null | undefined,
): string =>
  [`buildType:(id:${stringifyId(buildTypeId)})`, getBranchLocator(branch)].filter(Boolean).join(',')
export const getBuildLocator = (id?: BuildId | null): string => `id:${stringifyId(id)}`
export const getBuildStatsLocator = (
  buildTypeId: BuildTypeId | null | undefined,
  branch: Branch | null | undefined,
  count: number,
): string => {
  const buildTypeAndBranchLocator = getBuildTypeAndBranchLocator(buildTypeId, branch)
  return `item:(${buildTypeAndBranchLocator},history:false,state:(running:true,finished:true),count:${count})`
}
export const getBuildStatsAfterAndBeforeBuildLocator = (
  buildTypeId: BuildTypeId | null | undefined,
  buildId: BuildId,
  branch: Branch | null | undefined,
  count: number,
): string => {
  const buildTypeAndBranchLocator = getBuildTypeAndBranchLocator(buildTypeId, branch)
  const stringifyBuildId = stringifyId(buildId)
  return [
    `item:(${buildTypeAndBranchLocator},untilBuild(id:${stringifyBuildId}),defaultFilter:false,state:(running:true,finished:true),history:false,count:${count})`,
    `item:(${stringifyBuildId})`,
    `item:(${buildTypeAndBranchLocator},sinceBuild:(id:${stringifyBuildId}),defaultFilter:false,state:(running:true,finished:true),history:false,count:${count})`,
  ].join(',')
}

const getEntitySelector: (
  node?: ProjectOrBuildTypeNode | null | undefined,
  onlyFavorites?: boolean,
) => string = (node, onlyFavorites) => {
  if (!node?.id) {
    return ''
  }

  return [
    node.nodeType === 'project' ? `project:${stringifyId(node.id)}` : `id:${stringifyId(node.id)}`,
    onlyFavorites === true ? 'selectedByUser:(user:current)' : null,
  ]
    .filter(Boolean)
    .join(',')
}
const getFinishedItemLocator = ({
  selector,
  branchLocatorWithSeparator,
  count,
}: {
  selector: string
  branchLocatorWithSeparator: string
  count: number | undefined
}) =>
  `item:(${
    (count != null && count > 0 && `count:${count},`) || ''
  }strob:(buildType:(${selector})${branchLocatorWithSeparator}))`
const getQueuedItemLocator = ({
  selector,
  branchLocatorWithSeparator,
}: {
  selector: string
  branchLocatorWithSeparator: string
}) => `item:(state:queued,buildType:(${selector})${branchLocatorWithSeparator})`
const getRunningItemLocator = ({
  selector,
  branchLocatorWithSeparator,
}: {
  selector: string
  branchLocatorWithSeparator: string
}) => `item:(state:running,buildType:(${selector})${branchLocatorWithSeparator})`
export const getProjectOverviewLocator = (options: LocatorOptions) => {
  const {branch, projectBuildtype, runningCount, queuedCount, finishedCount} = options
  const totalCount = (runningCount || 0) + (queuedCount || 0) + (finishedCount || 0)
  const branchLocator = branch ? getBranchLocator(branch) : ''
  const selector = getEntitySelector(projectBuildtype)
  const branchLocatorWithSeparator = branchLocator
    ? `,${branchLocator}`
    : ',branch:(policy:ACTIVE_HISTORY_AND_ACTIVE_VCS_BRANCHES)'
  const finishedItem = getFinishedItemLocator({
    branchLocatorWithSeparator,
    selector,
    count: finishedCount,
  })
  const queuedItem = getQueuedItemLocator({
    branchLocatorWithSeparator,
    selector,
  })
  const runningItem = getRunningItemLocator({
    branchLocatorWithSeparator,
    selector,
  })
  const locator = [queuedItem, runningItem, finishedItem].join(',')
  return `count:${totalCount},${locator}`
}
type HistoryLocatorOptions = {
  buildTypeId: BuildTypeId
  branch?: Branch | null | undefined
  buildState?: BuildState | null | undefined
}
export const getHistoryLocator = ({buildTypeId, branch, buildState}: HistoryLocatorOptions) =>
  [
    'defaultFilter:false',
    `buildType:(id:${stringifyId(buildTypeId)})`,
    branch && getBranchLocator(branch),
    buildState != null && `state:${buildState}`,
  ]
    .filter(Boolean)
    .join(',')
export const getLocator = ({
  baseLocator,
  projectBuildtype,
  agent,
  agentPattern,
  tag,
  buildState = 'all',
  withRunningAndQueued,
  runningAndFinished,
  branch,
  useRawLocator,
  rawLocator,
}: LocatorOptions) => {
  if (useRawLocator && rawLocator != null) {
    return rawLocator
  }

  const branchLocator = branch ? getBranchLocator(branch) : ''
  return [
    'defaultFilter:false',
    branchLocator,
    baseLocator,
    agent?.typeId != null && `agentTypeId:${stringifyId(agent.typeId)}`,
    agent?.id != null && `agent:(id:${stringifyId(agent.id)})`,
    agentPattern != null &&
      `agentName:(value:${escapeLocatorDimension(
        agentPattern,
      )},matchType:contains,ignoreCase:true)`,
    runningAndFinished !== true && buildState && stateLocators[buildState],
    withRunningAndQueued !== true &&
      runningAndFinished !== true &&
      (buildState == null || !['running', 'queued'].includes(buildState)) &&
      'state:finished',
    runningAndFinished === true && stateLocators.runningAndFinished,
    projectBuildtype &&
      projectBuildtype.nodeType === 'project' &&
      `affectedProject:(id:${stringifyId(projectBuildtype.id)})`,
    projectBuildtype &&
      projectBuildtype.nodeType === 'bt' &&
      `buildType:(id:${stringifyId(projectBuildtype.id)})`,
    tag != null && `tag:(name:${escapeLocatorDimension(tag)})`,
  ]
    .filter(Boolean)
    .join(',')
}
