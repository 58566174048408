import {useCallback, useEffect, useState} from 'react'

const initialState = document.documentElement.clientHeight

export default function useDocumentClientHeight(): number {
  const [state, setState] = useState<number>(initialState)

  const onWindowResize = useCallback(() => {
    setState(document.documentElement.clientHeight)
  }, [])

  useEffect(() => {
    window.addEventListener('resize', onWindowResize)
    return () => window.removeEventListener('resize', onWindowResize)
  }, [onWindowResize])

  return state
}
