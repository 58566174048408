import type {CollapsibleBlock} from '../actions/collapsibleBlockTypes'
import type {BuildTypeId, Id, ProjectId, ProjectReceiveMeta} from '../types'
import type {KeyValue} from '../utils/object'
import {generateUID} from '../utils/uid'

import type {MainReducer} from '.'

export type CollapsibleBlockItems = KeyValue<CollapsibleBlock, KeyValue<string | Id, boolean>>
export type TogglingOverview = {
  project: KeyValue<ProjectId, boolean | null | undefined>
  bt: KeyValue<BuildTypeId, boolean | null | undefined>
}

export type ProjectsState = {
  data: readonly ProjectId[]
  loaded: boolean
  receiveMeta?: ProjectReceiveMeta
}

export type State = ReturnType<MainReducer>

type ClientId = string
export const clientId: ClientId = generateUID('uid')
