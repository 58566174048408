import {EntityRedirect} from '../../../../containers/EntityLink'
import {extractError} from '../../../../utils/extractError'
import Loader from '../../../common/Loader/Loader'
import {useBuildPageData} from '../BuildPage.hooks'

export default function BuildUnknownBuildTypePage() {
  const {
    buildTypeIdChanged,
    buildError,
    listError,
    actualBuildTypeId,
    buildTypeId,
    buildIdChanged,
    actualBuildId,
  } = useBuildPageData()

  if (buildTypeIdChanged || buildIdChanged) {
    return (
      <EntityRedirect
        buildId={actualBuildId}
        buildTypeId={actualBuildTypeId}
        redirectedFromId={buildTypeIdChanged ? buildTypeId : undefined}
      />
    )
  }

  const error = extractError(buildError) || listError

  if (!window.GLOBAL_ERROR_PAGE_DISABLED && error != null) {
    throw error
  }

  return <Loader />
}
