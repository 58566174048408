import type {Middleware} from '@reduxjs/toolkit'
import {isAction} from '@reduxjs/toolkit'
import memoize from 'memoize-one'

import type {State} from '../reducers/types'

const loadStatisticsChunk = memoize(
  () =>
    import(
      /* webpackChunkName: "Statistics", webpackPrefetch: true */
      '.'
    ),
)

// TODO: think on how to avoid this flag
let fusPluginInitialized = false
export const middleware: Middleware<{}, State> = store => next => action => {
  if (isAction(action) && !fusPluginInitialized) {
    const state = store.getState()
    loadStatisticsChunk().then(({initializeStatisticsPlugin, scheduleEventsSending}) => {
      const config = initializeStatisticsPlugin(state)
      if (config.initialized) {
        scheduleEventsSending()
        fusPluginInitialized = true
      }
    })
  }

  return next(action)
}

export const trackEvent = (fusAction: any) => {
  loadStatisticsChunk().then(({trackEvent: track}) => track(fusAction))
}
