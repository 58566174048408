import type {File} from '../services/rest'

const archiveRE = /\.(zip|nupkg|sit|jar|war|ear|apk|tar\.gz|tgz|tar\.gzip|tar|7z)$/
export function getType({name, size}: File): string {
  if (size == null) {
    return 'folder'
  }

  if (name != null && archiveRE.test(name)) {
    return 'archive'
  }

  return 'file'
}
export const isOnlyFolder = (files: ReadonlyArray<File>): boolean =>
  files.length === 1 && getType(files[0]) === 'folder'
