import exceptionIcon from '@jetbrains/icons/exception.svg'
import {lazy, Suspense} from 'react'
import {useRouteError} from 'react-router-dom'

import {extractErrorFromRelay} from '../../../relay/extractErrorFromRelay'
import {getPipelinesHref} from '../../../routes'

import FullScreenErrorWrapper from './components/FullScreenErrorWrapper/FullScreenErrorWrapper'
import Link from './components/Link/Link'
import {serializeError} from './utils/error'

const ErrorMessage = lazy(
  () =>
    import(
      /* webpackChunkName: "ErrorMessage", webpackPrefetch: true */ '@jetbrains/ring-ui/components/error-message/error-message'
    ),
)

export default function PipelinesPagesErrorBoundary() {
  const routeError = useRouteError() as Error | null | undefined
  const error = routeError && extractErrorFromRelay(routeError)
  return (
    <FullScreenErrorWrapper>
      <Suspense>
        <ErrorMessage
          icon={exceptionIcon}
          message="Error while loading data"
          description={serializeError(error)}
        >
          <Link to={getPipelinesHref()}>{'Go to the home page'}</Link>
        </ErrorMessage>
      </Suspense>
    </FullScreenErrorWrapper>
  )
}
