/**
 * @generated SignedSource<<e1dab06013e63bbebec53095cba37d05>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TestScopesViewQuery$variables = {
  locator: string;
  scopeName: string;
};
export type TestScopesViewQuery$data = {
  readonly testScopes: {
    readonly " $fragmentSpreads": FragmentRefs<"TestScopesView">;
  } | null | undefined;
};
export type TestScopesViewQuery = {
  response: TestScopesViewQuery$data;
  variables: TestScopesViewQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "locator"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "scopeName"
},
v2 = [
  {
    "kind": "Variable",
    "name": "locator",
    "variableName": "locator"
  },
  {
    "kind": "Variable",
    "name": "scopeName",
    "variableName": "scopeName"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TestScopesViewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "TestScopes",
        "kind": "LinkedField",
        "name": "testScopes",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TestScopesView"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "TestScopesViewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "TestScopes",
        "kind": "LinkedField",
        "name": "testScopes",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nextHref",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TestScope",
            "kind": "LinkedField",
            "name": "testScope",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "suite",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "package",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "class",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TestOccurrences",
                "kind": "LinkedField",
                "name": "testOccurrences",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TestCounters",
                    "kind": "LinkedField",
                    "name": "testCounters",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "failed",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "success",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "muted",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "all",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "ignored",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "duration",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "732a45a905939ae4b02622ba84b9498d",
    "id": null,
    "metadata": {},
    "name": "TestScopesViewQuery",
    "operationKind": "query",
    "text": "query TestScopesViewQuery(\n  $scopeName: String!\n  $locator: String!\n) {\n  testScopes(scopeName: $scopeName, locator: $locator) {\n    ...TestScopesView\n  }\n}\n\nfragment TestScope on TestScope {\n  ...TestScopeName\n  testOccurrences {\n    testCounters {\n      failed\n      success\n      muted\n      all\n      ignored\n      duration\n    }\n  }\n}\n\nfragment TestScopeName on TestScope {\n  suite\n  package\n  class\n}\n\nfragment TestScopesView on TestScopes {\n  nextHref\n  testScope {\n    suite\n    package\n    class\n    ...TestScope\n  }\n}\n"
  }
};
})();

(node as any).hash = "98460c897c7922e19ef3512f711b1df8";

export default node;
