import * as React from 'react'

import AppRoutes from '../../../routes'

import {EditPipelinePageEntryPoint} from './EditPipelinePage/EditPipelinePage.entryPoint'
import {PipelinePageEntryPoint} from './PipelinePage/PipelinePage.entryPoint'
import {PipelineRunPageEntryPoint} from './PipelineRunPage/PipelineRunPage.entryPoint'
import PipelinesPagesErrorBoundary from './PipelinesPages.errorBoundary'
import {isPipelinesEnabled} from './utils/featureToggles'

const PipelinesPages = React.lazy(
  () =>
    import(
      /* webpackChunkName: "PipelinesPages", webpackPrefetch: true */
      './PipelinesPages'
    ),
)

const IndexPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "PipelinesIndexPage", webpackPrefetch: true */
      './IndexPage/IndexPage'
    ),
)

const CreatePipelinePage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "CreatePipelinePage", webpackPrefetch: true */
      './CreatePipelinePage/CreatePipelinePage'
    ),
)

const WelcomePage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "WelcomePage", webpackPrefetch: true */
      './WelcomePage/WelcomePage'
    ),
)

const prefixLength = AppRoutes.PIPELINES.length - 1

export const pipelinesPagesRoute = isPipelinesEnabled
  ? {
      path: AppRoutes.PIPELINES,
      Component: PipelinesPages,
      ErrorBoundary: PipelinesPagesErrorBoundary,
      children: [
        {index: true, element: <IndexPage />},
        {path: AppRoutes.WELCOME_PIPELINES.slice(prefixLength), element: <WelcomePage />},
        {path: AppRoutes.CREATE_PIPELINE.slice(prefixLength), element: <CreatePipelinePage />},
        {path: AppRoutes.EDIT_PIPELINE.slice(prefixLength), entryPoint: EditPipelinePageEntryPoint},
        {path: AppRoutes.PIPELINE_RUN.slice(prefixLength), entryPoint: PipelineRunPageEntryPoint},
        {path: AppRoutes.PIPELINE.slice(prefixLength), entryPoint: PipelinePageEntryPoint},
      ],
    }
  : null
