import type {EntryPoint} from 'react-relay'
import {getRequest} from 'relay-runtime'

import {makeResource} from '../../../../utils/makeResource'
import {commiterSelectQuery} from '../../../common/UserSelect/CommiterSelect.queries'

import type {BuildChangesTabComponent} from './BuildChangesTab'

export const BuildChangesTabEntryPoint: EntryPoint<BuildChangesTabComponent, string> = {
  root: makeResource(
    'BuildChangesTab',
    () =>
      import(/* webpackChunkName: "BuildChangesTab", webpackPrefetch: true */ './BuildChangesTab'),
  ),
  getPreloadProps: buildLocator => {
    const baseLocator = `build:(${buildLocator})`
    return {
      queries: {
        commiterSelect: {
          parameters: getRequest(commiterSelectQuery),
          variables: {changeLocator: baseLocator},
        },
      },
      extraProps: {baseLocator},
    }
  },
}
