import * as Redux from 'redux'

import buildPager from './BuildPager/BuildPager.slice'
import changeLogPagerReducer from './ChangeLogPager/ChangeLogPager.reducer'
import changePagerReducer from './ChangePager/ChangePager.reducer'
import {PagerGroup} from './Pager.types'
import testPagerReducer from './TestPager/TestPager.reducer'
import testProblemPagerReducer from './TestProblemPager/TestProblemPager.reducer'

const pagerReducer = Redux.combineReducers({
  [PagerGroup.BUILD]: buildPager.reducer,
  [PagerGroup.TEST]: testPagerReducer,
  [PagerGroup.CHANGE]: changePagerReducer,
  [PagerGroup.CHANGE_LOG]: changeLogPagerReducer,
  [PagerGroup.TEST_PROBLEM]: testProblemPagerReducer,
})

export default pagerReducer
