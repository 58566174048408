import styles from './PipelineWrapperAlertMessage.css'

type PipelineWrapperAlertMessageProps = {
  children: React.ReactNode
}

function PipelineWrapperAlertMessage({children}: PipelineWrapperAlertMessageProps) {
  return <div className={styles.container}>{children}</div>
}

export default PipelineWrapperAlertMessage
