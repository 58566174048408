import type {PopupMenuAttrs} from '@jetbrains/ring-ui/components/popup-menu/popup-menu'

import PopupMenu from '../../Popup/PopupMenu.lazy'

import {UNIT} from './HelpDropdownPopup.consts'
import {useHelpDropdownContent} from './HelpDropdownPopup.hooks'

function HelpDropdownPopup(props: PopupMenuAttrs) {
  return <PopupMenu {...props} data={useHelpDropdownContent()} left={-UNIT} />
}

export default HelpDropdownPopup
