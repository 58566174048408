import type {EntryPoint} from 'react-relay'
import type {LoaderFunctionArgs} from 'react-router'
import {getRequest} from 'relay-runtime'

import type {ThinNestedEntryPointParamsObject} from '../../../../types/entryPoints'
import {makeResource} from '../../../../utils/makeResource'
import {queryToObject} from '../../../../utils/queryParams'
import {pipelineHeadQuery} from '../PipelinesPages.queries'

import type {EditPipelinePageComponent, NestedEntryPoints} from './EditPipelinePage'
import {JobSettingsSidebarEntryPoint} from './SettingsSidebarContent/JobSettingsSidebar/JobSettingsSidebar.entryPoint'

export const EditPipelinePageEntryPoint: EntryPoint<EditPipelinePageComponent, LoaderFunctionArgs> =
  {
    root: makeResource(
      'EditPipelinePage',
      () =>
        import(
          /* webpackChunkName: "EditPipelinePage", webpackPrefetch: true */ './EditPipelinePage'
        ),
    ),
    getPreloadProps: ({params, request}) => {
      const entryPoints: ThinNestedEntryPointParamsObject<NestedEntryPoints> = {}
      const url = new URL(request.url)
      const {job} = queryToObject(url.search)
      if (job != null) {
        entryPoints.jobSettingsSidebar = {
          entryPoint: JobSettingsSidebarEntryPoint,
          entryPointParams: {},
        }
      }

      return {
        queries: {
          pipelineHead: {
            parameters: getRequest(pipelineHeadQuery),
            variables: {locator: `internalId:${params.pipelineId}`},
            options: {networkCacheConfig: {metadata: {essential: true}}},
          },
        },
        entryPoints,
      }
    },
  }
