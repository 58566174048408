import {useCallback, useState} from 'react'

import type {Banner, BannerStoreValue} from './BannersContext.types'

function useBanners() {
  const [items, setItems] = useState<BannerStoreValue>({})

  const addBanner = useCallback((pipelineId: string, value: Banner) => {
    setItems(prevItems => {
      const existingItems = prevItems[pipelineId] ?? []
      if (!existingItems.find(item => item.key === value.key)) {
        return {
          ...prevItems,
          [pipelineId]: [...existingItems, value],
        }
      }
      return prevItems
    })
  }, [])

  const removeBanner = useCallback((pipelineId: string, key: string) => {
    setItems(prevItems => {
      if (!prevItems[pipelineId]) {
        return prevItems
      }

      const updatedBanners = prevItems[pipelineId].filter(banner => banner.key !== key)
      return {...prevItems, [pipelineId]: updatedBanners}
    })
  }, [])

  const removeAllBanners = useCallback(() => {
    setItems({})
  }, [])

  return {
    items,
    addBanner,
    removeBanner,
    removeAllBanners,
  }
}
export default useBanners
