import Dropdown from '@jetbrains/ring-ui/components/dropdown/dropdown'
import composeRefs from '@jetbrains/ring-ui/components/global/composeRefs'
import {Directions} from '@jetbrains/ring-ui/components/popup/popup.consts'
import * as React from 'react'
import {useCallback} from 'react'
import type {ContentRect} from 'react-measure'

import Measure from '../../../containers/Measure'
import useUID from '../../../hooks/useUID'
import Popup from '../Popup/Popup.lazy'

import type {PopupProps} from './BuildArtifacts.types'

import styles from './BuildArtifacts.css'

const Z_INDEX_INCREMENT = 5
const DEFAULT_POPUP_OFFSET = 12

export const MIN_POPUP_WIDTH = 320
const emptyDirections: Directions[] = []

function BuildArtifactsPopup({
  hasArtifacts,
  popupOffset,
  popupDirection = [Directions.LEFT_BOTTOM, Directions.LEFT_TOP],
  currentZindex,
  wrapperProps,
  maxHeight,
  anchor,
  children,
}: PopupProps) {
  const labelId = useUID('artifacts-tree-label-')
  // Popup can grow but cannot shrink
  const [treeMinWidth, setTreeMinWidth] = React.useReducer(
    (state: number, newWidth: number) => Math.max(state, newWidth),
    MIN_POPUP_WIDTH,
  )
  const ref = React.useRef<HTMLDivElement>(null)

  const setRefs = composeRefs(ref, wrapperProps?.ref)

  const onResize = (rect: ContentRect) => setTreeMinWidth(rect.offset?.width ?? 0)

  const [isFetching, setIsFetching] = React.useState(true)
  const onReady = useCallback(() => setIsFetching(false), [])
  const noArtifacts = hasArtifacts === false

  const content = React.useMemo(
    () => children({noArtifacts, labelId, onReady}),
    [labelId, noArtifacts, onReady, children],
  )

  const popupContent = React.useMemo(
    () => (
      // redraw popup whenever loading state changes
      <div data-loading={isFetching} className={styles.popupContent}>
        <Measure offset onResize={onResize}>
          {({measureRef}) => (
            <div
              style={{
                minWidth: `${treeMinWidth}px`,
              }}
              ref={measureRef}
            >
              {content}
            </div>
          )}
        </Measure>
      </div>
    ),
    [content, isFetching, treeMinWidth],
  )

  const popup = React.useMemo(
    () => (
      <Popup
        className={styles.popup}
        directions={emptyDirections.concat(popupDirection ?? [])}
        autoCorrectTopOverflow={false}
        offset={popupOffset ?? DEFAULT_POPUP_OFFSET}
        maxHeight={maxHeight}
        style={
          currentZindex != null
            ? {
                zIndex: currentZindex + Z_INDEX_INCREMENT,
              }
            : undefined
        }
      >
        {popupContent}
      </Popup>
    ),
    [popupDirection, popupOffset, maxHeight, currentZindex, popupContent],
  )
  const dropdown = React.useMemo(
    () => (
      <Dropdown anchor={anchor} hoverShowTimeOut={300} hoverHideTimeOut={300} hoverMode clickMode>
        {popup}
      </Dropdown>
    ),
    [anchor, popup],
  )
  return (
    <div className={styles.wrapper} {...wrapperProps} ref={setRefs}>
      {dropdown}
    </div>
  )
}
export default React.memo(BuildArtifactsPopup)
