import type {RefCallback} from 'react'
import {useCallback, useState} from 'react'

import {useSandbox} from '../../../../utils/fastdom'

const isTest = process.env.NODE_ENV === 'test'

export default function useTopOffset(): {
  topOffset: number | null | undefined
  topOffsetRef: RefCallback<HTMLElement>
} {
  const [topOffset, setTopOffset] = useState<number | null | undefined>(isTest ? 0 : null)
  const sandbox = useSandbox()
  const topOffsetRef = useCallback(
    async (node: HTMLElement) => {
      if (node != null) {
        const top = await sandbox.measure(() => node.getBoundingClientRect().top)

        if (top != null) {
          sandbox.mutate(() => setTopOffset(top))
        }
      }
    },
    [sandbox],
  )
  return {
    topOffset,
    topOffsetRef,
  }
}
