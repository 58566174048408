import type * as React from 'react'

import useDocumentClientHeight from '../../hooks/useDocumentClientHeight'
import useTopOffset from '../../hooks/useTopOffset'

type Props = {
  children: React.ReactNode | React.ReactNode[]
}

function RestScreenHeight({children}: Props) {
  const {topOffset, topOffsetRef} = useTopOffset()
  const documentClientHeight = useDocumentClientHeight()

  const style = {
    display: 'flex',
    minHeight: topOffset != null ? `${documentClientHeight - topOffset}px` : undefined,
  }

  return (
    <div ref={topOffsetRef} style={style}>
      {topOffset != null ? children : null}
    </div>
  )
}

export default RestScreenHeight
