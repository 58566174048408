import type {GraphQLTaggedNode} from 'react-relay'
import {graphql} from 'react-relay'

export const agentPoolQuery = graphql`
  query AgentPoolPagePoolQuery($agentPoolId: ID!, $isDefault: Boolean = false) {
    agentPool(id: $agentPoolId) {
      id
      rawId
      name
      maxAgentsNumber
      projects {
        count
      }
      permissions {
        manage
        manageAgents
        manageProjects
      }
      __typename
    }
    defaultPool: agentPool(id: "0") @skip(if: $isDefault) {
      permissions {
        manageAgents
        manageProjects
      }
    }
  }
`
export const projectsTreeQuery: GraphQLTaggedNode = graphql`
  query AgentPoolPageProjectsQuery($withPermissions: Boolean = false) {
    projects(filter: {archived: false}) {
      edges {
        node {
          id
          rawId
          name
          archived
          permissions @include(if: $withPermissions) {
            manageAgentPoolsForProject
          }
          agentPools {
            edges {
              node {
                id
                rawId
                name
              }
            }
          }
          ancestorProjects {
            edges {
              node {
                id
                rawId
                name
                archived
              }
            }
          }
        }
      }
    }
  }
`

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const projectsConnectionFragment: GraphQLTaggedNode = graphql`
  fragment AgentPoolPageProjectsConnectionFragment on AgentPoolProjectsConnection {
    edges {
      node {
        id
        rawId
        name
        archived
        permissions {
          manageAgentPoolsForProject
        }
        agentPools {
          edges {
            node {
              id
              rawId
              name
            }
          }
        }
        ancestorProjects {
          edges {
            node {
              id
              rawId
              name
              archived
            }
          }
        }
      }
    }
  }
`

// TODO: would be nice to use the fragment here (it will require the restructuring of the query consuming components)
export const agentPoolProjectsTreeQuery: GraphQLTaggedNode = graphql`
  query AgentPoolPageAgentPoolProjectsQuery($agentPoolId: ID!) {
    agentPool(id: $agentPoolId) {
      __typename
      name
      projects(filter: {archived: null}) {
        edges {
          node {
            id
            rawId
            name
            archived
            permissions {
              manageAgentPoolsForProject
            }
            agentPools {
              edges {
                node {
                  id
                  rawId
                  name
                  __typename
                }
              }
            }
            ancestorProjects {
              edges {
                node {
                  id
                  rawId
                  name
                  archived
                }
              }
            }
          }
        }
        excludedCount
      }
    }
  }
`

export const unassignProjectFromAgentPool: GraphQLTaggedNode = graphql`
  mutation AgentPoolPageUnassignProjectFromAgentPoolMutation(
    $projectRawId: ID!
    $agentPoolRawId: ID!
    $recursively: Boolean = false
  ) {
    unassignProjectFromAgentPool(
      input: {projectRawId: $projectRawId, agentPoolRawId: $agentPoolRawId, recursive: $recursively}
    ) {
      agentPool {
        projects(filter: {}) {
          ...AgentPoolPageProjectsConnectionFragment
        }
      }
    }
  }
`

export const bulkAssignProjectWithAgentPool: GraphQLTaggedNode = graphql`
  mutation AgentPoolPageBulkAssignProjectWithAgentPoolMutation(
    $projectRawIds: [ID!]!
    $agentPoolRawId: ID!
    $exclusively: Boolean = false
  ) {
    bulkAssignProjectWithAgentPool(
      input: {
        projectRawIds: $projectRawIds
        agentPoolRawId: $agentPoolRawId
        exclusively: $exclusively
      }
    ) {
      agentPool {
        id
      }
    }
  }
`

export const createAgentPool: GraphQLTaggedNode = graphql`
  mutation AgentPoolPageCreateAgentPoolMutation($name: String!, $maxAgentsNumber: Int) {
    createAgentPool(input: {name: $name, maxAgentsNumber: $maxAgentsNumber}) {
      agentPool {
        id
        rawId
        name
        maxAgentsNumber
        projects {
          count
        }
        permissions {
          manageProjects
        }
        __typename
      }
    }
  }
`

export const updateAgentPool: GraphQLTaggedNode = graphql`
  mutation AgentPoolPageUpdateAgentPoolMutation($rawId: ID!, $name: String, $maxAgentsNumber: Int) {
    updateAgentPool(input: {rawId: $rawId, name: $name, maxAgentsNumber: $maxAgentsNumber}) {
      agentPool {
        id
        name
        maxAgentsNumber
      }
    }
  }
`

export const removeAgentPool: GraphQLTaggedNode = graphql`
  mutation AgentPoolPageRemoveAgentPoolMutation($agentPoolRawId: ID!) {
    removeAgentPool(input: {agentPoolRawId: $agentPoolRawId}) {
      agentPool {
        rawId
        name
      }
    }
  }
`

export const moveAgentToAgentPool: GraphQLTaggedNode = graphql`
  mutation AgentPoolPageMoveAgentToAgentPoolMutation($agentRawId: ID!, $agentPoolRawId: ID!) {
    moveAgentToAgentPool(input: {agentRawId: $agentRawId, targetAgentPoolRawId: $agentPoolRawId}) {
      targetAgentPool {
        id
      }
    }
  }
`

export const moveCloudImageToAgentPool: GraphQLTaggedNode = graphql`
  mutation AgentPoolPageMoveCloudImageToAgentPoolMutation(
    $agentTypeRawId: ID!
    $agentPoolRawId: ID!
  ) {
    moveCloudImageToAgentPool(
      input: {agentTypeRawId: $agentTypeRawId, targetAgentPoolRawId: $agentPoolRawId}
    ) {
      targetAgentPool {
        id
      }
    }
  }
`

export const query = graphql`
  query AgentPoolPageQuery {
    ...AgentsPagesCanViewAgentDetailsFragment
  }
`
