import throttle from 'lodash/throttle'
import {useEffect, useMemo} from 'react'

export const useThrottle = <T extends (...args: any) => any>(callback: T, delay: number) => {
  const throttleHandler = useMemo(
    () => throttle(callback, delay, {leading: true, trailing: true}),
    [callback, delay],
  )

  useEffect(() => () => throttleHandler.cancel(), [throttleHandler])

  return throttleHandler
}
