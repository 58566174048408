import type {BuildNumberProps as APIProps} from '@jetbrains/teamcity-api'
import {skipToken} from '@reduxjs/toolkit/query'
import type {ConnectedProps} from 'react-redux'
import {connect} from 'react-redux'

import {useIsMine} from '../../../hooks/useIsMine'
import type {State} from '../../../reducers/types'
import {getSingleBuildArg} from '../../../rest/builds'
import {getBuildLocator} from '../../../rest/locators'
import {getOverviewHref} from '../../../routes'
import {getBuild, getIsSakuraUI} from '../../../selectors'
import {restApi} from '../../../services/rest'
import type {BuildId} from '../../../types'
import {toBuildId} from '../../../types'
import {resolveWebEntityLink, WebEntityType} from '../../../utils/entityLinks'

import BuildNumberComponent from './BuildNumber'

type OwnProps = {
  buildId: BuildId | null | undefined
  number?: string
  withLink?: boolean
  hideStar?: boolean
  className?: string
  showEmptyStar?: boolean
  withWarnings?: boolean
  withComment?: boolean
  withStatusChangeComment?: boolean
}

const mapStateToProps = (state: State, props: OwnProps) => {
  const build = getBuild(state, props.buildId)
  const isSakuraUI = getIsSakuraUI(state)
  const {number, personal, canceledInfo, queuePosition} = build || {}
  const href = isSakuraUI
    ? getOverviewHref({
        buildId: props.buildId,
        buildTypeId: build?.buildType,
      })
    : resolveWebEntityLink(WebEntityType.BUILD, props.buildId)
  return {
    href,
    queuePosition,
    loaded: number != null || queuePosition != null || props.number != null,
    number: number ?? props.number,
    isPersonal: personal === true,
    isQueued: build?.state === 'queued',
    isCanceled: canceledInfo != null,
  }
}

const connector = connect(mapStateToProps)

type Props = OwnProps & ConnectedProps<typeof connector>

const BuildNumberContainer = connector(function BuildNumberContainer({
  buildId,
  loaded,
  ...restProps
}: Props) {
  restApi.endpoints.getBuildNormalized.useQuerySubscription(
    buildId != null && !loaded ? getSingleBuildArg(getBuildLocator(buildId)) : skipToken,
  )
  const isMine = useIsMine(buildId)
  return loaded && buildId != null ? (
    <BuildNumberComponent {...restProps} buildId={buildId} isMine={isMine} />
  ) : null
})
export default BuildNumberContainer
export function BuildNumberAPI({buildId, number, withLink, hideStar}: APIProps) {
  return (
    <BuildNumberContainer
      buildId={buildId != null ? toBuildId(buildId) : null}
      number={number}
      withLink={withLink}
      hideStar={hideStar}
    />
  )
}
