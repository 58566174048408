import type {RepositoryGroup} from './pipelinesApi.types'
import {RepositoryGroupType} from './pipelinesApi.types'

// TODO Temporary solution, remove
export const prossessSpaceRepositories = (data: string): RepositoryGroup[] | null => {
  if (
    data.includes('To show available repositories TeamCity requires access to your Space account')
  ) {
    return null
  }

  const regex =
    /{\s*id:\s*'([^']*)',\s*isPrivate:\s*(true|false),\s*name:\s*'([^']*)',\s*url:\s*'([^']*)',\s*}/g

  const result = []
  let match
  while ((match = regex.exec(data))) {
    result.push({
      private: match[2] === 'true',
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      name: match[3],
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      htmlUrl: match[4],
      defaultBranch: 'main',
    })
  }

  return [
    {
      myOwner: {avatarUrl: '', login: ''},
      myGroupType: RepositoryGroupType.CURRENT_USER,
      myRepositories: result,
    },
  ]
}
