import type {ReactNode} from 'react'

import RestScreenHeight from '../RestScreenHeight/RestScreenHeight'

import styles from './FullScreenErrorWrapper.css'

type Props = {
  children: ReactNode
}

function FullScreenErrorWrapper({children}: Props) {
  return (
    <RestScreenHeight>
      <div className={styles.content}>{children}</div>
    </RestScreenHeight>
  )
}

export default FullScreenErrorWrapper
