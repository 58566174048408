import {graphql} from 'react-relay'

export const cloudImagesQuery = graphql`
  query JobAgentRequirementsCloudImagesQuery {
    cloudImages {
      cloudImage {
        name
      }
    }
  }
`
