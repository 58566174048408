import compose from 'lodash/flowRight'
import type * as React from 'react'
import {connect} from 'react-redux'

import {BuildLineLayoutProvider} from '../contexts/buildLineLayout'
import {useQueryParam, withBuildTypeId} from '../hooks/routes'
import type {State} from '../reducers/types'
import {getBuildType} from '../selectors'
import type {BuildLineLayout as BuildLineLayoutType, BuildTypeId} from '../types'

type OwnProps = BuildLineLayoutType & {
  children: React.ReactNode
  buildTypeId?: BuildTypeId
  isDependencies?: boolean
}
type StateProps = {
  isComposite: boolean
}
type Props = OwnProps & StateProps

const mapStateToProps = (state: State, props: Props): StateProps => {
  const {buildTypeId} = props
  return {
    isComposite:
      props.isDependencies !== true && getBuildType(state, buildTypeId)?.type === 'composite',
  }
}

function BuildLineLayout({
  showAgent = true,
  showExactDuration = true,
  showBranch = true,
  showPath = true,
  showTags = true,
  showComment = true,
  children,
  isComposite,
  artifactDependenciesMode,
}: Props) {
  const buildTypeId = useQueryParam('buildTypeId')

  const layout = {
    showAgent: showAgent === true && !isComposite,
    showExactDuration,
    showBranch,
    showPath: showPath === true && !buildTypeId,
    showTags,
    showComment,
    artifactDependenciesMode,
  }
  return <BuildLineLayoutProvider {...layout}>{children}</BuildLineLayoutProvider>
}

export default compose(withBuildTypeId, connect(mapStateToProps))(BuildLineLayout)
